import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import TeamsTable from "./teamsTable";
import SearchBox from "../../Common/searchbox";
import { getTeams, saveTeam } from "../../../services/fakeTeamService";
import _ from "lodash";

class AdminTeamsPage extends Component {
    state = {
        teams: [],
        sortColumn: { path: "name", order: "asc" },
        searchQuery: "",
        sendLogin: {
            isPending: false,
            team: "",
        },
    };

    componentDidMount() {
        this.setState({ teams: getTeams() })
    }

    handleSort = (sortColumn) => {
        this.setState({ sortColumn });
    };

    toggleSendLogin = (team) => {
        const sendLogin = this.state.sendLogin.isPending ? { isPending: false, team: "" } : { isPending: true, team: team }
        this.setState({ sendLogin });
    }

    SendLogin = (team) => {
        //Write code to send login-details to contact person phonenumber
        console.log(team.contactPerson);
        console.log(team.phoneNumber);


        if (!team.loginIsSent) {
            const copyTeam = team;
            copyTeam.loginIsSent = true;

            const copyTeams = this.state.teams;
            copyTeams[team] = copyTeam

            this.setState({ teams: copyTeams });
            saveTeam(copyTeam);
        }
        this.setState({ sendLogin: { isPending: false, team: "" } })
    }

    handleSearch = query => {
        this.setState({ searchQuery: query });
    };

    getVisualData = () => {
        const { teams, sortColumn, searchQuery } = this.state;

        let filtered = teams;
        if (searchQuery) {
            filtered = teams.filter(m =>
                m.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        const sortedTeams = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        return { data: sortedTeams };
    };

    render() {

        const { data: teams } = this.getVisualData();
        const { sortColumn, searchQuery } = this.state;

        return (
            <div className="teams_container">
                <button><Link to="/admin-page" >Tillbaka</Link></button>
                <div>
                    <div className="search_and_addteam">
                        <div className="searchbox_container">
                            <SearchBox value={searchQuery} onChange={this.handleSearch} label="Sök lagnamn" />
                        </div>
                        <div><button className="sendFormButton"><Link to="/admin-page/teams/new">Lägg till nytt lag</Link></button></div>
                    </div>
                    {this.state.sendLogin.isPending ? <div>
                        <p>Skicka inloggningsuppgifter till: {this.state.sendLogin.team.name}</p>
                        <button onClick={() => this.SendLogin(this.state.sendLogin.team)}>Skicka</button>
                        <button onClick={this.toggleSendLogin}>Avbryt</button>
                    </div> : ""}
                    <h3 className="margin_top">Aktiva lag</h3>
                    <TeamsTable
                        onSort={this.handleSort}
                        sortColumn={sortColumn}
                        orders={teams.filter(t => t.active === true)}
                        onSend={this.toggleSendLogin} />

                    <h3 className="margin_top">Inaktiva lag</h3>
                    <TeamsTable
                        onSort={this.handleSort}
                        sortColumn={sortColumn}
                        orders={teams.filter(t => t.active === false)}
                        onSend={this.toggleSendLogin} />
                </div>
            </div>
        );
    }
}

export default AdminTeamsPage;
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useAppStateContext } from "../../hooks/useAppState";
import { useParams } from "react-router-dom";
import DeliveryCodeInput from "../DeliveryCodeInput";
import Footer from "../Footer";
import Products from "../Products";
import {
  Button,
} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },

  title: {
    fontSize: 28,
    marginTop: 8,
    marginRight: 16,
  },

  button: {
    borderRadius: 8,
    width: 300,
    height: 51,
    backgroundColor: "#883210",
    color: "white",
    fontSize: 18,
    border: "none",
    outline: "none",
    alignSelf: "center",
  },
}));

const Home = () => {
  const classes = useStyle();
  const { deliveryCode } = useParams();
  const { onCheckoutClick, validDevliveryCodes } = useAppStateContext();

  return (
    <div className={classes.container}>
      {Object.keys(validDevliveryCodes).length > 0 && <DeliveryCodeInput deliveryCode={deliveryCode} />}

      <Products />

      <Button
        className="submitButton"
        variant="contained"
        color="primary"
        onClick={onCheckoutClick}>
        Till varukorgen
      </Button>

      <Footer />
    </div>
  );
};

export default Home;

import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import UsersTable from "./usersTable";
import SearchBox from "../../Common/searchbox";
import { getUsers } from "../../../services/fakeUserService";
import _ from "lodash";

class AdminUsersPage extends Component {
    state = {
        users: [],
        sortColumn: { path: "name", user: "asc" },
        searchQuery: "",
    };

    componentDidMount() {
        this.setState({ users: getUsers() })
    }

    handleSort = (sortColumn) => {
        this.setState({ sortColumn });
    };

    handleSearch = query => {
        this.setState({ searchQuery: query });
    };

    getVisualData = () => {
        const { users, sortColumn, searchQuery } = this.state;

        let filtered = users;
        if (searchQuery) {
            filtered = users.filter(m =>
                m.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        const sortedUsers = _.sortBy(filtered, [sortColumn.path], [sortColumn.user]);

        return { data: sortedUsers };
    };

    render() {

        const { data: users } = this.getVisualData();
        const { sortColumn, searchQuery } = this.state;

        return (
            <div className="teams_container">
                <button><Link to="/admin-page" >Tillbaka</Link></button>
                <div>
                    <div className="search_and_addteam">
                        <div className="searchbox_container">
                            <SearchBox value={searchQuery} onChange={this.handleSearch} label="Sök ungdomens namn" />
                        </div>
                    </div>
                    <h3 className="margin_top">Alla ungdomar</h3>
                    <UsersTable
                        onSort={this.handleSort}
                        sortColumn={sortColumn}
                        users={users} />
                </div>
            </div>
        );
    }
}

export default AdminUsersPage;
import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import OrdersTable from "./ordersTable";
import SearchBox from "../../Common/searchbox";
import { getOrders } from "../../../services/fakeOrderService";
import SectionHeader from "../../SectionHeader";

import _ from "lodash";

class AdminOrdersPage extends Component {
    state = {
        orders: [],
        sortColumn: { path: "name", order: "asc" },
        searchQuery: "",
    };

    componentDidMount() {
        this.setState({ orders: getOrders() })
    }

    handleSort = (sortColumn) => {
        this.setState({ sortColumn });
    };

    handleSearch = query => {
        this.setState({ searchQuery: query });
    };

    getVisualData = () => {
        const { orders, sortColumn, searchQuery } = this.state;

        let filtered = orders;
        if (searchQuery) {
            filtered = orders.filter(m =>
                m.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        const sortedOrders = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        return { data: sortedOrders };
    };

    render() {

        const { data: orders } = this.getVisualData();
        const { sortColumn, searchQuery } = this.state;

        return (
            <div className="teams_container">
                <button><Link to="/admin-page" >Tillbaka</Link></button>
                <div>
                    <div className="search_and_addteam">
                        <div className="searchbox_container">
                            <SearchBox value={searchQuery} onChange={this.handleSearch} label="Sök kundens namn" />
                        </div>
                        <div><button className="sendFormButton"><Link to="/admin-page/orders/new">Lägg till ny order</Link></button></div>
                    </div>
                    <SectionHeader title="Leverans vecka 23" />
                    <div className="weekly_orders">
                        <p>{`<-- Vecka 22`}</p><p>{`Vecka 24 -->`}</p>
                    </div>
                    <h3 className="margin_top">Orders</h3>
                    <OrdersTable
                        onSort={this.handleSort}
                        sortColumn={sortColumn}
                        orders={orders} />
                </div>
            </div>
        );
    }
}

export default AdminOrdersPage;
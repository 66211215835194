import React from "react";
import { Link } from "react-router-dom";

export default function AdminPage() {
    return (
        <div>
            <button><Link to="/admin-page/users">Lista med ungdomar</Link></button>
            <br></br>
            <br></br>
            <button><Link to="/admin-page/teams">Hantera lag</Link></button>
            <br></br>
            <br></br>
            <button><Link to="/admin-page/orders">Hantera orders</Link></button>
        </div>
    );
}
import React from "react";
import Input from "../../Common/input";
import { saveOrder } from "../../../services/fakeOrderService";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOrder } from "../../../services/fakeOrderService";
import { Link } from "react-router-dom";
import "./styles.css"


function OrderForm() {
    const history = useHistory();
    const [state, setState] = useState({
        data: { name: "", deliverycode: "", amount: "", address: "", phoneNumber: "", city: "", deliveryDate: "", },
    })
    const { id: paramsId } = useParams();
    const isNewOrder = paramsId === "new" ? true : false;

    useEffect(() => {
        if (isNewOrder) return;

        const order = getOrder(paramsId);
        if (!order) return history.replace("/not-found");

        setState({ data: order });
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        const data = state.data;
        saveOrder(data);
        history.push("/admin-page/orders")
    }

    function handleChange({ currentTarget: input }) {
        const data = { ...state.data }
        data[input.name] = input.value
        setState({ data })
    }

    const { name, deliverycode, amount, address, phoneNumber, deliveryDate, city } = state.data;

    return (
        <div className="order_form_container">
            <div className="addTeam_container">
                <button><Link to="/admin-page/orders">Tillbaka</Link></button>
                <h2>{isNewOrder ? "Lägg till ny order" : "Gör ändring i befintlig order"}</h2>
                <form onSubmit={e => handleSubmit(e)}>
                    <Input label="Kundens namn" name="name" onChange={handleChange} value={name} required />
                    <Input label="Leveranskod" name="deliverycode" onChange={handleChange} value={deliverycode} required />
                    <Input label="Belopp" name="amount" onChange={handleChange} value={amount} required />
                    <Input label="Adress" name="address" onChange={handleChange} value={address} required />
                    <Input label="Ort" name="city" onChange={handleChange} value={city} required />
                    <Input label="Telefonnummer" name="phoneNumber" onChange={handleChange} value={phoneNumber} required />
                    <Input label="Leveransdag" name="deliveryDate" onChange={handleChange} value={deliveryDate} required />
                    <button className="sendFormButton">{isNewOrder ? "Skapa order" : "Spara"}</button>
                </form>
            </div >
            <div className="addTeam_container">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <h2>Produkter</h2>
                <p>- 0 + Källarfranska</p>
                <p>- 1 + Rågbulle</p>
                <p>- 0 + Levain</p>
                <p>- 4 + Kokoskladdkaka</p>
            </div>
        </div>
    );
};

export default OrderForm;
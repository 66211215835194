/**
 *
 * Checkout
 *
 */

import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SwishLogo from "../../assets/logo/swish-logo.svg";
import { useSubscribe } from "../../hooks";
import { useAppStateContext } from "../../hooks/useAppState";
import DB from "../../services/DB";
import { calculateTotalPrice } from "../../utils";
import CheckoutBasket from "../CheckoutBasket";
import CheckoutForm from "../CheckoutForm";
import ErrorOrder from "../ErrorOrder";
import PendingOrder from "../PendingOrder";
import SectionHeader from "../SectionHeader";
import SuccesOrder from "../SuccesOrder";

const useStyle = makeStyles((theme) => ({
  sectionHeader: {
    marginTop: 30,
    marginBottom: 30,
  },

  section: {
    backgroundColor: "#F4F4F4",
    height: 90,
  },

  swishLogo: {
    height: 60,
    width: 120,
    marginBottom: 5,
  },
}));

export default function PaymentModal() {
  const { productCount, currentDeliveryCode, resetProducts, resetAppState } =
    useAppStateContext();

  const [totalCost, setTotalCost] = useState(() => ({
    sum: calculateTotalPrice(productCount),
  }));

  const [pendingOrderId, setPendingOrderId] = useState(null);

  const resetOrder = () => setPendingOrderId(null);

  useEffect(() => {
    setTotalCost({ sum: calculateTotalPrice(productCount) });
  }, [productCount]);

  const { data: order } = useSubscribe(`orders/${pendingOrderId}`);

  const onSubmit = async (data, deliveryMethod) => {
    const order = generateOrderData(
      data,
      totalCost,
      productCount,
      deliveryMethod,
      currentDeliveryCode
    );

    setPendingOrderId(order.id);

    try {
      const response = await fetch(
        "https://europe-west1-frulla-production.cloudfunctions.net/euorders/create",
        {
          method: "POST",
          json: true,
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ order }),
        }
      );

      if (response.ok) {
        return;
      }
    } catch (error) {
      console.info("onSubmit Error:", error);
      resetOrder();
    }
  };

  if (order && ["ERROR", "DECLINED", "CANCELLED"].includes(order.status)) {
    return <ErrorOrder resetOrder={resetOrder} resetAppState={resetAppState} />;
  }

  if (order && order.status === "PAID") {
    return <SuccesOrder order={order} resetProducts={resetProducts} />;
  }

  if (order && order.status === "PENDING") {
    return <PendingOrder order={order} resetAppState={resetAppState} />;
  }

  return (
    <div>
      <SectionHeader title="Din varukorg" />

      <CheckoutBasket totalCost={totalCost} />

      <SectionHeader title="Slutför köp" />

      <PaymentMethod />

      <CheckoutForm
        onSubmit={onSubmit}
        isPending={pendingOrderId}
        deliveryCode={currentDeliveryCode.replaceAll(" ", "")}
      />
    </div>
  );
}

const PaymentMethod = () => {
  const classes = useStyle();

  return (
    <Grid container alignItems="center" justifyContent="space-around">
      <Grid item>
        <Typography>Betalsätt</Typography>
      </Grid>

      <Grid item>
        <img className={classes.swishLogo} src={SwishLogo} alt="Swish logo" />
      </Grid>
    </Grid>
  );
};

function generateOrderData(
  data,
  totalCost,
  productCount,
  deliveryMethod,
  deliveryCode
) {
  const id = DB.getID();

  const databaseDiff = Object.keys(data).reduce((diff, id) => {
    const value = data[id];

    if (value && typeof value === "string") {
      if (id === "phoneNumber") {
        diff[id] = value
          .replace("+", "")
          .replaceAll(" ", "")
          .replaceAll("-", "")
          .replace("(", "")
          .replace(")", "");
      } else {
        diff[id] = value.toLowerCase();
      }
    }

    return diff;
  }, {});

  const products = Object.keys(productCount).reduce((diff, id) => {
    const value = productCount[id];

    if (value) {
      diff[id] = value;
    }

    return diff;
  }, {});

  return {
    ...databaseDiff,
    id,
    deliveryCode,
    amount: totalCost.sum,
    products,
    deliveryMethod,
  };
}

import React, { Component } from "react";
import Table from "../Common/table";
import { Link } from "react-router-dom";

class SellerOrdersTable extends Component {
    columns = [
        { path: "delivered", label: "Leverera", content: order => <p>{order.delivered ? "Levererad" : <button>Leverera</button>}</p> },
        { path: "name", label: "Namn" },
        { path: "address", label: "Adress" },
    ]

    render() {
        const { orders, onSort, sortColumn } = this.props;

        return (
            <div>
                <Table
                    columns={this.columns}
                    data={orders}
                    sortColumn={sortColumn}
                    onSort={onSort}
                />
            </div>
        );
    }
};

export default SellerOrdersTable;
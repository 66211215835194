/**
 *
 * AboutUs
 *
 */

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import BackArrowImage from "../../assets/img/back-arrow.png";

export default function AboutUs(props) {
  return (
    <div>
      <div className="MarginLeft">
        <Link className="BackArrow" to="/">
          <img className="BackArrowImage" src={BackArrowImage} alt="back" />
        </Link>
      </div>

      <p className="TermsTitle">
        <strong>Om oss</strong>
      </p>
      <p className="TermsText">
        I början av 2020 satt vi, tre före detta idrottsutövare och ledare, i Göteborg och skissade på ett nytt koncept med fokus på idrottsungdomar och lagförsäljning. Vi har under större delen av våra liv varit aktiva inom föreningslivet och genom åren har vi sålt många olika produkter för att samla in nödvändiga pengar till vårt lag och förening.
      </p>
      <p className="TermsText">
        Vi såg ett allt större intresse hos privatpersoner att få hemleverans av varor och matprodukter samtidigt som idrottslag var i behov av kontinuerliga intäkter. Vi var enade om att vi ville kombinera nytta för både vår omgivning och idrottslag och till slut var valet självklart - nybakt bröd levererat direkt till dörren!
      </p>
      <p className="TermsText">
        Med tiden insåg vi samtidigt att det var svårt för ungdomar runt om i Göteborg att få helg- och sommarjobb. Detta gjorde att konceptet snabbt förädlades där vi såg att det gick att erbjudas både som extrajobb som en tonårings första merit på CV:t parallellt med både klass- och lagförsäljning.
      </p>
      <p className="TermsText">
        I grund och botten handlar vårt koncept om enkelhet. Lagledare och föräldrar skall inte behöva känna att det tillkommer extra arbete vid en försäljning via Frulla. Vi skall alltid sköta allt från administration till förarbetet av det som ungdomarna delar ut till sina kunder - allt för att göra första jobbet eller lagaktiviteten till en rolig och enkel erfarenhet.
      </p>
      <p className="TermsText">
        Vårt mål är att alla ungdomar skall få samma chans till oförglömliga cuper, roliga klassaktiviteter och ett kul första extrajobb som vi hade. Samtidigt vill vi att du som stöttar våra ungdomar, idrottslag och klasser skall få njuta av en härlig start på helgen.
      </p>
      <p className="TermsText">
        /Grundarna
      </p>
    </div>
  );
}

AboutUs.propTypes = {
  props: PropTypes.object,
};

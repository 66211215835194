/**
 *
 * Header
 *
 */

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import CartImage from "../../assets/img/cart.svg";
import FrullaLogo from "../../assets/logo/frulla-logo.svg";
import { useAppStateContext } from "../../hooks/useAppState";
import "./styles.css";

export default function Header() {
  const { nbProducts, onCheckoutClick } = useAppStateContext();

  return (
    <div className="HeaderContainer">
      <Link to="/">
        <img className="Logo" alt="Frulla" src={FrullaLogo} />
      </Link>

      <button onClick={onCheckoutClick} className="ShoppingCart">
        {nbProducts > 0 && <div className="ProductCountIcon">{nbProducts}</div>}

        <img className="Logo" alt="Frulla" src={CartImage} />
      </button>
    </div>
  );
}

Header.propTypes = {
  props: PropTypes.object,
};

import { useEffect, useState } from "react";
import DB from "../services/DB";

export function useSubscribe(path, defaultValue = null) {
  const [data, setData] = useState(defaultValue);

  useEffect(() => {
    const ref = DB.createRef(path);

    ref.on("value", (snap) => {
      if (snap.exists()) {
        setData(snap.val());
      } else {
        setData(null);
      }
    });
  }, [path]);

  return { data };
}

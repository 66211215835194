/**
 *
 * Products
 *
 */

import PropTypes from "prop-types";
import React from "react";
import { CATEGORIES, PRODUCTS } from "../../constants/products";
import { useAppStateContext } from "../../hooks/useAppState";
import ProductCard from "../ProductCard";
import "./styles.css";

const products = {};

for (let category of CATEGORIES) {
  products[category.id] = [];

  for (let id in category.products) {
    const ok = category.products[id];

    if (ok) {
      products[category.id].push(PRODUCTS[id]);
    }
  }
}

export default function Products() {
  const { removeProduct, addProduct, productCount } = useAppStateContext();
  return (
    <div className="SliderConteriner">
      {Object.keys(products).map((id, index) => {
        const list = products[id];

        return (
          <div key={id} className="CategoryContainer">
            <h1 className="CategoryTitle">{id}</h1>

            <div className="CategoryProducts">
              {list.map((item) => (
                <ProductCard
                  key={index + item.id + id}
                  count={productCount[item.id]}
                  removeProduct={removeProduct}
                  addProduct={addProduct}
                  item={item}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

Products.propTypes = {
  slides: PropTypes.array,
};

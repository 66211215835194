

export default async function SendEmail(event, data) {
    event.preventDefault();

    try {
        const response = await fetch(
            "https://europe-west1-frulla-production.cloudfunctions.net/formEmail/send",
            {
                method: "POST",
                json: true,
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    form: {
                        fullName: data.name,
                        team: data.team,
                        userEmail: data.email,
                        phonenumber: data.phonenumber,
                        other: data.other,
                        emailSubject: data.emailSubject,
                        address: data.address,
                        city: data.city,
                        schoolClass: data.schoolClass,
                        recommendedBy: data.recommendedBy,
                    }
                }),
            }
        );

        if (response.ok) {
            window.location.href = "/form-submitted";
            console.log("skickad");
            return;
        }
    } catch (error) {
        console.info("onSubmit Error:", error);
    }
}
import produce from "immer";
import React, { createContext, useContext, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import createPersistedReducer from "use-persisted-reducer";
import {
  ADD_DELIVERY_CODE,
  ADD_DELIVERY_CODE_ERROR,
  ADD_PRODUCT,
  ADD_VALIDATED_CODES,
  REMOVE_PRODUCT,
  RESET_DELIVERY_CODE,
  RESET_PRODUCTS,
  VALIDATE_DELIVERY_CODE
} from "../constants/actionTypes";
import {
  INITIAL_DELIVERY_CODE_DATA_STATE,
  INITIAL_PRODUCT_COUNT_STATE
} from "../constants/products";
import DB from "../services/DB";

const MyAppStateContext = createContext();

export const useAppStateContext = () => {
  return useContext(MyAppStateContext);
};

export const AppStateProvider = ({ children }) => {
  const appState = useAppState();

  return (
    <MyAppStateContext.Provider value={appState}>
      {children}
    </MyAppStateContext.Provider>
  );
};

const usePersistedProductReducer = createPersistedReducer("productCountv23");
const usePersistedDeliveryReducer = createPersistedReducer("deliveryInfov2");

const useAppState = () => {
  const deliveryCodeInputRef = useRef(null);
  const history = useHistory();
  let { pathname } = useLocation();

  const [
    { currentDeliveryCode, validCode, devliveryCodeError, validDevliveryCodes },
    deliveryCodeDataDispatch,
  ] = usePersistedDeliveryReducer(
    deliveryCodeDataReducer,
    INITIAL_DELIVERY_CODE_DATA_STATE
  );

  const [productCount, productCountDispatch] = usePersistedProductReducer(
    productCountReducer,
    INITIAL_PRODUCT_COUNT_STATE
  );

  const nbProducts = Object.values(productCount).reduce(
    (sum, value) => sum + value,
    0
  );

  useEffect(() => {
    const init = async () => {
      const data = await DB.get("deliveryCodes");

      const action = {
        type: ADD_VALIDATED_CODES,
        payload: { value: data },
      };

      deliveryCodeDataDispatch(action);
    };

    init();
  }, [deliveryCodeDataDispatch]);

  const onSellerCodeChange = (code) =>
    deliveryCodeDataDispatch({
      type: ADD_DELIVERY_CODE,
      payload: { value: code },
    });

  const validateCode = (code) =>
    deliveryCodeDataDispatch({
      type: VALIDATE_DELIVERY_CODE,
    });

  const addProduct = (productId) =>
    productCountDispatch({
      type: ADD_PRODUCT,
      payload: { productId },
    });

  const removeProduct = (productId) =>
    productCountDispatch({
      type: REMOVE_PRODUCT,
      payload: { productId },
    });

  const resetProducts = (productId) =>
    productCountDispatch({
      type: RESET_PRODUCTS,
    });

  const resetAppState = (productId) => {
    productCountDispatch({ type: RESET_PRODUCTS });

    deliveryCodeDataDispatch({ type: RESET_DELIVERY_CODE });
  };

  const setNoneValidCode = () => {
    deliveryCodeDataDispatch({
      type: ADD_DELIVERY_CODE_ERROR,
      payload: {
        value:
          "Vi behöver veta vilken föreningsungdom som ska leverera. Fyll i leveranskod för att gå vidare.",
      },
    });

    if (deliveryCodeInputRef?.current) {
      deliveryCodeInputRef.current.focus();
    }
  };

  const onCheckoutClick = () => {
    // if we are on the payment page already we navigate back to main page.
    if (pathname.includes("payment")) {
      history.push("/");
      return;
    }
    // if the code is not valid we ask them to supply one with the callback.
    if (!validCode) {
      validateCode();
      deliveryCodeInputRef.current.focus();
    }
    // if they have supplied a valid code and have added aleast one product we navigate to checkout.
    if (validCode && nbProducts > 0) {
      history.push("/payment");
    }
  };

  const onHomePageClick = () => {
    history.push("/");
  }

  const onNoCodePageClick = () => {
    history.push("/how-to-get-a-code");
  }

  return {
    nbProducts,
    onCheckoutClick,
    productCount,
    setNoneValidCode,
    resetAppState,
    resetProducts,
    removeProduct,
    addProduct,
    validateCode,
    onSellerCodeChange,
    currentDeliveryCode,
    validCode,
    devliveryCodeError,
    deliveryCodeInputRef,
    onHomePageClick,
    onNoCodePageClick,
    validDevliveryCodes
  };
};

const deliveryCodeDataReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_DELIVERY_CODE: {
      draft.currentDeliveryCode = action.payload.value.replaceAll(" ", "").toLowerCase();

      if (draft.devliveryCodeError != null) {
        draft.devliveryCodeError = null;
      }

      draft.validCode = false;

      return draft;
    }

    case ADD_VALIDATED_CODES: {
      const validDevliveryCodes = action.payload.value;

      draft.validDevliveryCodes = validDevliveryCodes;

      return draft;
    }

    case ADD_DELIVERY_CODE_ERROR: {
      const devliveryCodeError = action.payload.value;

      draft.devliveryCodeError = devliveryCodeError;

      return draft;
    }

    case VALIDATE_DELIVERY_CODE: {
      const lowerCaseCode = draft.currentDeliveryCode.toLowerCase();

      if (lowerCaseCode in draft.validDevliveryCodes) {
        draft.devliveryCodeError = null;
        draft.validCode = true;
      } else {
        draft.devliveryCodeError = "Du har angivit fel kod.";
        draft.validCode = false;
      }

      return draft;
    }

    case RESET_DELIVERY_CODE:
      return {
        ...INITIAL_DELIVERY_CODE_DATA_STATE,
        validDevliveryCodes: draft.validDevliveryCodes,
      };

    default:
      return draft;
  }
});

const productCountReducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_PRODUCT: {
      const productId = action.payload.productId;

      draft[productId] = draft[productId] + 1;

      return draft;
    }

    case REMOVE_PRODUCT: {
      const productId = action.payload.productId;

      if (draft[productId] === 0) {
        return draft;
      }

      draft[productId] = draft[productId] - 1;

      return draft;
    }

    case RESET_PRODUCTS: {
      return INITIAL_PRODUCT_COUNT_STATE;
    }

    default:
      return draft;
  }
});

export default useAppState;

// Action Types

export const ADD_PRODUCT = 'ADD_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const RESET_PRODUCTS = 'RESET_PRODUCTS'

export const ADD_VALIDATED_CODES = 'ADD_VALIDATED_CODES'
export const ADD_DELIVERY_CODE = 'ADD_DELIVERY_CODE'
export const ADD_DELIVERY_CODE_ERROR = 'ADD_DELIVERY_CODE_ERROR'
export const VALIDATE_DELIVERY_CODE = 'VALIDATE_DELIVERY_CODE'
export const RESET_DELIVERY_CODE = 'RESET_DELIVERY_CODE'

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../Common/table";

class PlayersTable extends Component {
    columns = [
        { path: "deliverycode", label: "Leveranskod", content: player => <Link to={`/my-team/member/${player.id}`}>{player.deliverycode}</Link> },
        { path: "firstname", label: "Namn", content: player => <Link to={`/my-team/member/${player.id}`}>{`${player.firstname} ${player.lastname}`}</Link> },
        { path: "phonenumber", label: "Telefonnummer" },
        { path: "loginIsSent", label: "Starta igång", content: player => <button disabled={player.loginIsSent} onClick={() => this.props.onSend(player)}> Skicka login</button> },
    ]
    render() {
        const { players, onSort, sortColumn } = this.props;

        return (
            <div>
                <Table
                    columns={this.columns}
                    data={players}
                    sortColumn={sortColumn}
                    onSort={onSort} />
            </div>
        );
    }
};

export default PlayersTable;
/**
 *
 * Terms
 *
 */

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import BackArrowImage from "../../assets/img/back-arrow.png";
import "./styles.css";

export default function Terms(props) {
  return (
    <div className="TermsContainer">
      <div className="MarginLeft">
        <Link className="BackArrow" to="/">
          <img className="BackArrowImage" src={BackArrowImage} alt="back" />
        </Link>
      </div>

      <p className="TermsTitle">
        <strong>Allmänna villkor</strong>
      </p>
      <p className="TermsText">
        De allmänna villkor (”villkoren”) som anges i det här dokumentet gäller
        mellan dig som kund (”du”) och Frulla Sverige AB, org nr 559262-1105
        (”Frulla”, ”vi”) vid köp av bröd och andra produkter (”varor”) som finns
        tillgängliga på https://frulla.se (”hemsidan”). Genom att genomföra
        betalning i nätbutiken godkänner du dessa villkor, intygar att du är
        myndig med rätten att ingå avtal samt intygar riktigheten i de uppgifter
        som du lämnar. Avtalet mellan dig och Frulla blir bindande när Frulla
        har bekräftat din order.
      </p>
      <p className="TermsText">
        Frulla har rätt att ändra villkoren och det är upp till dig som kund att
        vara uppdaterad på de villkor som gäller vid köptillfället.
      </p>
      <p className="TermsText">
        <strong>1. Aktörer som samarbetar med Frulla</strong>
      </p>
      <p className="TermsText">1.1 Bagerier, leverantörer</p>
      <p className="TermsText">
        Varor som visas på hemsidan i form av frallor och bröd bakas på
        beställning hos ett av Frulla’s utvalda bagerier. Varorna som saluförs
        på hemsidan är alla kopplade till bagerier som är godkända av
        livsmedelsverket.
      </p>
      <p className="TermsText">1.2 Säljare, ungdomar</p>
      <p className="TermsText">
        Det är ungdomar (”ungdom”, ”säljare”) som utför leveransen av de
        beställningar som görs på hemsidan. Genom att ingå avtal med Frulla
        bekräftar du att vald säljare tidigare sålt till dig, tidigare försökt
        sälja till dig, lämnat en folder till dig, eller på annat sätt bekräftat
        att leverans till din angivna adress är möjlig. Säljaren äger rätten att
        lämna över leveransansvaret till annan säljare om så önskas.
      </p>
      <p className="TermsText">
        <strong>2. Allmän info om vara och tjänst</strong>
      </p>
      <p className="TermsText">2.1 Utbud och varor</p>
      <p className="TermsText">
        För att säkerställa leveranstid och varans kvalitet är Frulla’s bagerier
        begränsade med affärsställe i närheten av din leveransadress. Du
        hänvisas till hemsidan för uppdaterad information om utbud, varornas
        innehåll och för att se vilket bageri din valda säljare är kopplad till.
        Utbud, bageri och annan information kan komma att uppdateras. Vi
        reserverar oss för att bilder av varorna på hemsidan kan komma att
        skilja mot den verkliga varan, dock ej mer än tillåtet enligt
        konsumenträttslagstiftningen.
      </p>
      <p className="TermsText">2.2 Beställning och leverans</p>
      <p className="TermsText">
        Samtliga beställningar genomförs på hemsidan genom att du först väljer
        säljare baserat på punkt 1.2. Anpassat utbud kommer visas från det
        bageri som är kopplat till vald säljare och du väljer sedan de varor som
        du önskar köpa. Beställning kan endast göras via hemsidan och behöver
        bekräftas av Frulla senast 23:59 torsdag för leverans lördag morgon
        samma vecka. Observera att beställningar via email, telefon eller
        hemsidans live-chat ej accepteras. Varje beställning bekräftas via
        e-post och inkluderar information om vilken dag som bokats för leverans.
      </p>
      <p className="TermsText">2.3 Risk och ägande av varan</p>
      <p className="TermsText">
        Frulla ansvarar för risken att varan förloras eller skadas under
        transporten från bageri till angiven leveransadress. När varan
        överlämnats till dig på överenskommen plats övergår risken till dig.
        Varor som tar skada på grund av att du inte tagit emot varan efter att
        ha placerats på överenskommen plats kommer inte kompenseras av Frulla.
      </p>
      <p className="TermsText">2.4 Byte av bageri och utbud</p>
      <p className="TermsText">
        Vid byte av bageri förändras hemsidans utbud, varornas innehåll,
        näringsvärde samt annan viktig information om brödet. Det är ditt ansvar
        att du har koll på aktuell produktinformation vid
        beställningstillfället. Byte av bageri kan ske av två anledningar.
      </p>
      <p className="TermsText">
        1. Säljare väljer att leverera varor från annat bageri.
      </p>
      <p className="TermsText">2. Du som kund byter säljare.</p>
      <p className="TermsText">2.5 Nybakt bröd</p>
      <p className="TermsText">
        Vid köp av nybakt bröd är varan inte tillverkad vid köptillfället.
        Brödet bakas på beställning hos utvalt bageri vilket sker mindre än 12
        timmar innan brödet hämtas upp av säljaren. Information om innehåll och
        näringsvärde som visas på hemsidan är den information som
        tillhandahållits från bageriet. Paketering av bröd görs vanligen av en
        ungdom från säljarens idrottslag. Alla personer som hanterar paketering
        har genomfört en hygienkurs för att få hantera livsmedel. Efter
        paketering transporteras brödet hem till dig på lämpligt sätt utan att
        säljaren varit i direkt kontakt med råvarorna eller brödet. Bröd är
        tänkt att förtäras omedelbart efter leverans. För övriga produkter finns
        bäst-före-märkning på produkten som levereras.
      </p>
      <p className="TermsText">
        <strong>3. Pris och betalning</strong>
      </p>
      <p className="TermsText">3.1 Priser</p>
      <p className="TermsText">
        Priser som visas på hemsidan är inklusive moms. Frulla äger rätten att
        ändra priser och/eller varornas innehåll.
      </p>
      <p className="TermsText">3.2 Betalning</p>
      <p className="TermsText">
        Betalning av vara ska ske vid beställningstillfället med något av de
        betalmedel som erbjuds via hemsidan.
      </p>
      <p className="TermsText">
        <strong>4. Ångerrätt och reklamation</strong>
      </p>
      <p className="TermsText">4.1 Ångerrätt</p>
      <p className="TermsText">
        När du som konsument köper en vara på distans t ex via internet har du
        rätt att ångra köpet enligt lag inom 14 dagar. Undantaget i lagen är
        bland annat livsmedel med kort hållbarhet, så som t ex bröd. För köp av
        bröd på Frulla’s hemsida gäller därav inte ångerrätt. Om du som kund
        vill åberopa ångerrätt på köp av andra produkter kontaktar du Frulla med
        hjälp av de kontaktuppgifter som tillhandahålls på hemsidan. I det fall
        att ångerätten godtas betalar du som kund kostnaden för transport och
        står som ansvarig fram till dess att varan är mottagen av Frulla.
      </p>
      <p className="TermsText">4.2 Reklamation</p>
      <p className="TermsText">
        Vid reklamationsärende är det viktigt att du kontaktar Frulla så snart
        som möjligt. Ta hjälp av kontaktuppgifterna som du hittar på vår
        hemsida. Frulla har rätt att avgöra vilken lösning som är bäst anpassad
        i varje enskilt reklamationsärende.
      </p>
      <p className="TermsText">
        <strong>5. Force Majeure</strong>
      </p>
      <p className="TermsText">
        Vid situation som klassas som Force majeure är Frulla, bageri och/eller
        säljare ej skyldiga att fullfölja leverans enligt beställning. Till
        Force majeure klassas bland annat väder, naturkatastrofer, brand, krig,
        pandemi, strejk, myndighetsbeslut, olyckshändelse eller annan
        omständighet som försvårar leverans. Frulla har som intention att
        meddela dig snarast möjligt i det fall att leverans ej är möjlig.
      </p>
      <p className="TermsText">
        <strong>6. Personuppgifter</strong>
      </p>
      <p className="TermsText">
        Vi behandlar dina personuppgifter enligt Frulla's sekretesspolicy som
        finns tillgänglig på hemsidan.
      </p>
      <p className="TermsText">
        <strong>7. Tvist</strong>
      </p>
      <p className="TermsText">
        Tvist ska i första hand försöka lösas mellan Frulla och konsument. Om
        oenighet råder rekommenderar vi att du vänder dig till allmänna
        reklamationsnämnden.
      </p>
      <p className="TermsText">
        Dessa villkor ska tolkas i enlighet med svensk lag.
      </p>
    </div>
  );
}

Terms.propTypes = {
  props: PropTypes.object,
};

/**
 *
 * Contact
 *
 */

import React from 'react'
import { Typography, makeStyles } from "@material-ui/core"
import { Link } from "react-router-dom"
import BackArrowImage from "../../assets/img/back-arrow.png"
import Support_img from '../../assets/img/support.png'
import Victor_img from '../../assets/img/victor.jpg'
import Niclas_img from '../../assets/img/niclas.jpg'
import Christian_img from '../../assets/img/christian.jpg'
import "./styles.css";

const useStyle = makeStyles(() => ({
  gridcontainer: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    columnGap: 25,
    rowGap: 35,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    ['@media (min-width:780px)']: {
      gridTemplateColumns: '300px auto',
      rowGap: 50,
      textAlign: 'left',
    },
  },
  image: {
    width: '300px',
    height: '300px',
    border: 'none',
    borderRadius: 10,
    marginBottom: -35,
    ['@media (min-width:780px)']: {
      marginBottom: 0,
    },
  },
  description: {
    marginTop: 18,
  },
  top_container: {
    paddingTop: 4,
    position: 'relative',
    alignItems: "center",
    margin: '40px 75px',
  },
  flex_start: {
    alignSelf: 'flex-start'
  },
  name: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  underline: {
    textDecoration: 'underline',
  },
  backarrow: {
    width: '5px',
  },
  h1: {
    fontSize: '3rem',
    textAlign: 'center',
  }
}))

const Contact = () => {
  const classes = useStyle()
  return (
    <div>
      <div className={classes.top_container} >
        <div className={classes.flex_start}>

          <div className={classes.backarrow}>
            <Link to="/">
              <img className="BackArrowImage" src={BackArrowImage} alt="back" />
            </Link>
          </div>

        </div>
        <Typography className={classes.h1} variant="h1">Kontakt</Typography>
        <div className={classes.gridcontainer}>
          <div>
            <img src={Support_img} alt="Support" className={classes.image} />
          </div>
          <div>
            <Typography className={classes.name}>
              Support
            </Typography>
            <Typography>Support</Typography>
            <Typography className={classes.underline}>
              <a href="Mailto:support@frulla.se">support@frulla.se</a>
            </Typography>
            <Typography>
              <a href="Tel:+46760222188">0760-222188</a>
            </Typography>
            <Typography className={classes.description}>
              Vill du hylla vårt koncept? Kanske såga det? Eller vill du bara
              snacka lite gott? Oavsett vad det gäller kan du alltid slå en
              pling eller skicka ett digitalt brev till vår support. En klassisk
              tombola där slumpen avgör vem av grabbarna listade nedan som du
              får prata med.
            </Typography>
          </div>
          {/* <div>
            <img src={Victor_img} alt="Victor" className={classes.image} />
          </div>
          <div>
            <Typography className={classes.name}>
              Victor Eriksson
            </Typography>
            <Typography>VD</Typography>
            <Typography className={classes.underline}>
              <a href="Mailto:victor@frulla.se">victor@frulla.se</a>
            </Typography>
            <Typography className={classes.description}>
              Med sina dryga två meter står denna pjäs lika stadigt som det lugn
              han utstrålar. Har koll på det mesta i allmänhet och Frulla i
              synnerhet. Har inte haft en dålig dag sedan tidigt 90-tal.
              Innebandynörd vars karriär snöpligt tog slut när baksidan small 9
              sekunder in på säsongen 17/18. Har sedan dess verkat både som
              ledare och kommentator samt lagt mer tid på quiz och oändliga
              Excel-listor.
            </Typography>
          </div>
          <div>
            <img src={Niclas_img} alt="Niclas" className={classes.image} />
          </div>
          <div>
            <Typography className={classes.name}>
              Niclas Boquist
            </Typography>
            <Typography>Försäljning</Typography>
            <Typography className={classes.underline}>
              <a href="Mailto:niclas@frulla.se">niclas@frulla.se</a>
            </Typography>
            <Typography className={classes.description}>
              Om du behöver hjälp med din golfsving eller söker en overkligt
              trevlig stund över en kopp java har du kommit rätt. En sann
              livsnjutare tillika träningsprodukt som mår som bäst i högt tempo
              där aktiviteterna haglar tätt. Gav upp innebandyn i tidig ålder
              för att satsa på sin älskade golf. Nu har han fullt upp med att
              effektivisera sina golfrundor för att maximera tiden åt att hitta
              nya lösningar för föreningslivet.
            </Typography>
          </div>
          <div>
            <img
              src={Christian_img}
              alt="Christian"
              className={classes.image}
            />
          </div>
          <div>
            <Typography className={classes.name}>
              Christian Åhman
            </Typography>
            <Typography>Marknad</Typography>
            <Typography className={classes.underline}>
              <a href="Mailto:christian@frulla.se">christian@frulla.se</a>
            </Typography>
            <Typography className={classes.description}>
              En sann glädjespridare som inte hatar att få folk i sin omgivning
              att garva högt. Företagets bolltrollare som faktiskt var uppe på
              högsta nivån innan han gav sig pga skada. Talangen som inte helt
              blommade ut har sedan innebandyklubban lades på hyllan konkurrerat
              med konsulter och mellanchefer på padelplanen. Följer varenda
              sport som involverar en boll och tackar sällan nej till kaka till
              kaffet.
            </Typography>
          </div> */}
          <div>
            <Typography>Besöksadress:</Typography>
            <Typography>
              Frulla Sverige AB <br /> Eklövsvägen 178 <br /> 443 50 Lerum
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact

/**
 *
 * SuccesOrder
 *
 */

import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import FrullaLogo from "../../assets/logo/frulla-logo.svg";
import { useAppStateContext } from "../../hooks/useAppState";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 24,
  },

  logo: {
    marginTop: 30,
    height: 50,
    lenght: 75,
  },
  title: {
    marginTop: 50,
    textAlign: "center",
    fontSize: 36,
    fontWeight: "bold",
  },
  text: {
    marginTop: 34,
    textAlign: "center",
    fontSize: 16,
  },
  orderNumberText: {
    marginTop: 40,
    textAlign: "center",
    fontSize: 16,
  },
  orderNumber: {
    marginTop: 4,
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
  },
  submitButton: {
    marginTop: 50,
    width: 330,
    height: 48,
    borderRadius: 30,
    color: "white",
  },
}));

export default function SuccesOrder({ resetProducts, order }) {
  const { onHomePageClick } = useAppStateContext();
  const classes = useStyles();
  // we reset the checkout if the order is successful.
  useEffect(() => {
    resetProducts();
  }, [resetProducts]);

  return (
    <div className={classes.container}>
      <img className={classes.logo} src={FrullaLogo} alt="frulla logo" />

      <Typography className={classes.title}>
        Tack för din beställning!
      </Typography>

      <Typography className={classes.text}>
        Vi har tagit emot din beställning och längtar redan efter att få ge dig
        en härlig start på helgen. En orderbekräftelse har skickats till din
        mail.
      </Typography>

      <Typography className={classes.orderNumberText}>Ordernummer</Typography>

      <Typography className={classes.orderNumber}>
        {order?.id || "123lhljh123"}
      </Typography>

      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
        onClick={onHomePageClick}
      >
        Tillbaka till startsidan
      </Button>
    </div>
  );
}

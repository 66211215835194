import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  AboutUs,
  Checkout,
  Contact,
  GDPR,
  Header,
  Home,
  NoCodeInfo,
  ProductInfoPage,
  Terms,
  TeamPage,
  ClassPage,
  SellWithFrulla,
  SuccessSubsription,
  WorkWithFrulla,
  AdminPage,
  AdminOrderPage,
  AdminTeamsPage,
  TeamForm,
  OrderForm,
  AdminUsersPage,
  UserForm,
  TeamAdminPage,
  PlayerForm,
  SellerAdminPage,
} from "./components";
import { AppStateProvider } from "./hooks/useAppState";
import scrollToTop from "./hooks/scrollToTop";

function App() {
  scrollToTop();
  return (
    <AppStateProvider>
      <Header />

      <Switch>
        <Route path="/payment">
          <Checkout />
        </Route>

        <Route path="/terms">
          <Terms />
        </Route>

        <Route path="/contact">
          <Contact />
        </Route>

        <Route path="/about-us">
          <AboutUs />
        </Route>

        <Route path="/GDPR">
          <GDPR />
        </Route>

        <Route path="/how-to-get-a-code">
          <NoCodeInfo />
        </Route>

        <Route path="/product/:id">
          <ProductInfoPage />
        </Route>

        <Route path="/lagforsaljning">
          <TeamPage />
        </Route>

        <Route path="/form-submitted">
          <SuccessSubsription />
        </Route>

        <Route path="/jobba-hos-frulla">
          <WorkWithFrulla />
        </Route>

        <Route path="/helgjobb">
          <SellWithFrulla />
        </Route>

        <Route path="/klassforsaljning">
          <ClassPage />
        </Route>

        <Route path="/admin-page/users/:id">
          <UserForm />
        </Route>

        <Route path="/admin-page/users">
          <AdminUsersPage />
        </Route>

        <Route path="/admin-page/orders/:id">
          <OrderForm />
        </Route>

        <Route path="/admin-page/orders">
          <AdminOrderPage />
        </Route>

        <Route path="/admin-page/teams/:id">
          <TeamForm />
        </Route>

        <Route path="/admin-page/teams">
          <AdminTeamsPage />
        </Route>

        <Route path="/admin-page">
          <AdminPage />
        </Route>

        <Route path="/my-page">
          <SellerAdminPage />
        </Route>

        <Route path="/my-team/member/:id">
          <PlayerForm />
        </Route>

        <Route path="/my-team">
          <TeamAdminPage />
        </Route>

        <Route path="/:deliveryCode?">
          <Home />
        </Route>
      </Switch>
    </AppStateProvider>
  );
}

export default App;

/**
 *
 * CheckoutBasket
 *
 */

import {
  Container,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PropTypes from "prop-types";
import React from "react";
import { SERVICE_FEE } from "../../constants/cost";
import { PRICE_BY_PRODUCT, PRODUCTS } from "../../constants/products";
import { useAppStateContext } from "../../hooks/useAppState";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  image: {
    height: 88,
    width: 88,
    borderRadius: 5,
  },

  productTitle: {
    fontSize: 13,
  },

  main: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    minWidth: 350,
  },

  item: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    margin: 16,
    maxWidth: 350,
    minWidth: 300,
  },

  text: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },

  title: {
    padding: "0px 16px",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },

  buttons: {
    padding: 12,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },

  controls: {
    display: "flex",
    flex: 2,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },

  button: {
    height: 28,
    width: 28,
    borderRadius: 14,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  price: {
    display: "flex",
    flex: 2,
    width: 50,
    justifyContent: "flex-end",
  },

  summary: {
    padding: "0px 16px",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },

  service: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },

  totalCost: {
    marginTop: 8,
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },

  font14: {
    fontSize: 14,
    fontWeight: "bold",
  },
  font16: {
    fontSize: 16,
    fontWeight: "bold",
  },
  font18: {
    fontSize: 18,
    fontWeight: "bold",
  },
  marginBottom: {
    marginBottom: 30,
  },
}));

export default function CheckoutBasket({ totalCost }) {
  const { productCount, removeProduct, addProduct, nbProducts } = useAppStateContext();
  const classes = useStyle();
  const history = useHistory();


  const onRemoveProduct = (id) => {
    if (nbProducts === 1) {
      removeProduct(id)
      history.push("/");
    }
    else
      removeProduct(id)
  };

  return (
    <Container className={classes.marginBottom}>
      <div className={classes.main}>
        {Object.keys(productCount).map((id) => {
          const product = PRODUCTS[id];
          const count = productCount[id];
          const price = PRICE_BY_PRODUCT[id];

          if (count === 0 || !product) {
            return null;
          }

          return (
            <div key={id} className={classes.item}>
              <img className={classes.image} src={product.image} alt={id} />

              <div className={classes.text}>
                <div className={classes.title}>
                  <Typography className={classes.font14}>
                    {product.title}
                  </Typography>

                  <Typography className={classes.font14}>
                    {price} kr/st
                  </Typography>
                </div>

                <div className={classes.buttons}>
                  <div className={classes.controls}>
                    <div
                      className={classes.button}
                      style={{ backgroundColor: "gray" }}
                    >
                      <IconButton
                        onClick={() => onRemoveProduct(id)}
                        aria-label="remove"
                        component="button"
                      >
                        <RemoveIcon htmlColor="#ffffff" />
                      </IconButton>
                    </div>

                    <Typography className={classes.font16}>{count}</Typography>

                    <div className={classes.button}>
                      <IconButton
                        onClick={() => addProduct(id)}
                        aria-label="add"
                        component="button"
                      >
                        <AddIcon htmlColor="#ffffff" />
                      </IconButton>
                    </div>
                  </div>

                  <div className={classes.price}>
                    <Typography className={classes.font14}>
                      {(count * price).toFixed(2)} kr
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={classes.summary}>
        <div className={classes.service}>
          <Typography className={classes.font14}>Serviceavgift</Typography>

          <Typography className={classes.font14}>
            {SERVICE_FEE.toFixed(2)} kr
          </Typography>
        </div>

        <div className={classes.totalCost}>
          <Typography className={classes.font18}>Totalt (ink.moms)</Typography>

          <Typography className={classes.font18}>
            {totalCost.sum.toFixed(2)} kr
          </Typography>
        </div>
      </div>
    </Container>
  );
}

CheckoutBasket.propTypes = {
  props: PropTypes.object,
};

import React, { useState } from 'react';
import Frullabilen from '../../assets/logo/Frulla_logo-bil.png'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import "./styles.css";

export default function Collapsible({ heading, body, defaultOpen = false }) {

    const [open, setOpen] = useState(defaultOpen);

    function toggleCollapsible() {
        setOpen(!open)
    }

    const classname = open ? "collapsible_container collapsible_open" : "collapsible_container collapsible_closed";

    return (
        <div className={classname} >
            <div className="collapsible_header" onClick={toggleCollapsible}>
                <div className="collapsible_header_left">
                    <img className="collapsible_car" src={Frullabilen} alt="frulla-bilen" />
                    <h2 className="collapsible_h2">{heading}</h2>
                </div>
                <div className="arrow_container">
                    <KeyboardArrowDownIcon />
                </div>
            </div>
            <div className="collapsible_body">
                {body}
            </div>
        </div>
    );
}
import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import createPersistedState from "use-persisted-state";
import { useAppStateContext } from "../../hooks/useAppState";
import OtherIcon from "../../assets/logo/annat.svg";
import MailBoxIcon from "../../assets/logo/brevlåda.svg";
import OutsideDoorIcon from "../../assets/logo/dörr.svg";
import KnockIcon from "../../assets/logo/knacka.svg";
import BagIcon from "../../assets/logo/tygpase.svg";
import {
  getDeliveryDate,
  validateEmail,
  validatePhoneNumber,
} from "../../utils";

const KNOCK = "Knacka på";
const MAIL_BOX = "I brevlådan";
const OUTSIDE_THE_DOOR = "Utanför dörren";
const TYGPASE = "I påse på handtaget"
const OTHER = "Annat";

const options = [
  {
    text: KNOCK,
    image: KnockIcon,
  },
  {
    text: MAIL_BOX,
    image: MailBoxIcon,
  },
  {
    text: OUTSIDE_THE_DOOR,
    image: OutsideDoorIcon,
  },
  {
    text: TYGPASE,
    image: BagIcon,
  },
  {
    text: OTHER,
    image: OtherIcon,
  },
];

const useFormValuesState = createPersistedState("defaultValuesV1");

const useStyle = makeStyles((theme) => ({
  item: {
    margin: 8,
  },

  sectionHeader: {
    marginTop: 30,
    marginBottom: 30,
  },

  submitButton: {
    width: 330,
    height: 48,
    borderRadius: 30,
    color: "white",
  },

  deliveryMethodContainer: {
    marginBottom: 16,
  },

  deliveryImageContainer: {
    height: 80,
    width: 80,
    borderRadius: 4,
    border: "1px solid lightgray",
  },
  deliveryImage: {
    height: 40,
    width: 40,
  },
  deliveryMethod: {
    marginLeft: 16,
  },
  deliveryContainer: {
    marginBottom: 16,
  },

  fieldError: {
    color: "red",
    fontSize: 14,
    marginTop: 16,
  },

  padding: {
    padding: "0px 20px",
  },

  warrningTitle: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 16,
  },
  warrningText: {
    marginTop: 8,
    textAlign: "center",
  },
  summer: {
    fontWeight: 'bold',
    color: 'red',
  }
}));

export default function CheckoutForm({ onSubmit, deliveryCode, isPending }) {
  const classes = useStyle();

  const { nbProducts } = useAppStateContext();

  const [defaultFormValues, setDefaultFormValue] = useFormValuesState({});

  const [deliveryMethod, setDeliveryMethod] = useState(OTHER);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: { ...defaultFormValues, deliveryCode: deliveryCode },
  });

  const _onSubmit = (data) => {
    setDefaultFormValue(data);
    onSubmit(data, deliveryMethod);
  };

  return (
    <form className={classes.padding} onSubmit={handleSubmit(_onSubmit)}>
      <div className={classes.sectionHeader}>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="h6" component="p">
              Dina uppgifter
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Grid container justifyContent="center">
        <Grid item xs={12} md={4} className={classes.item}>
          <Input
            label="Telefonnummer (+46)"
            name="phoneNumber"
            error={errors.phoneNumber}
            inputRef={register({
              required: true,
              validate: validatePhoneNumber,
            })}
            required
          />
        </Grid>

        <Grid item xs={12} md={4} className={classes.item}>
          <Input
            label="Förnamn"
            name="firstname"
            error={errors.firstname}
            inputRef={register({ required: true, minLength: 2, maxLength: 100 })}
            required
          />
        </Grid>

        <Grid item xs={12} md={4} className={classes.item}>
          <Input
            label="Efternamn"
            name="lastname"
            error={errors.lastname}
            inputRef={register({ required: true, minLength: 2, maxLength: 100 })}
            required
          />
        </Grid>

        <Grid item xs={12} md={4} className={classes.item}>
          <Input
            label="E-post"
            name="email"
            error={errors.email}
            inputRef={register({ required: true, validate: validateEmail, maxLength: 100 })}
            required
          />
        </Grid>
      </Grid>

      <div className={classes.sectionHeader}>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="h6" component="p">
              Leveransuppgifter
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item>
            {/* <Typography>Leverans lördag {getDeliveryDate()}</Typography> */}
            <Typography className={classes.summer}>Frulla är pausat, det går därför inte att beställa för tillfället</Typography>
          </Grid>
        </Grid>
      </div>

      <Grid container justifyContent="center">
        <Grid item xs={12} md={4} className={classes.item}>
          <Input
            label="Adress"
            name="address"
            error={errors.address}
            inputRef={register({ required: true, minLength: 2, maxLength: 100 })}
            required
          />
        </Grid>

        <Grid item xs={12} md={4} className={classes.item}>
          <Input
            label="Postnr"
            name="postcode"
            error={errors.postcode}
            inputRef={register({ required: true, minLength: 2, maxLength: 100 })}
            required
          />
        </Grid>
        <Grid item xs={12} md={4} className={classes.item}>
          <Input
            label="Ort"
            name="ort"
            error={errors.ort}
            inputRef={register({ required: true, minLength: 2, maxLength: 100 })}
            required
          />
        </Grid>

        <Grid item xs={12} md={4} className={classes.item}>
          <Input
            label="Våning + portkod"
            name="floor"
            error={errors.floor}
            inputRef={register({ maxLength: 100 })}
          />
        </Grid>

        <Grid item xs={12} md={4} className={classes.item}>
          <Input
            label="Lägenhetsnummer"
            name="appartmentNumber"
            error={errors.appartmentNumber}
            inputRef={register({ maxLength: 100 })}
          />
        </Grid>

        <Grid item xs={12} md={4} className={classes.item}>
          <Input
            label="Leveranskod"
            name="deliveryCode"
            error={null}
            inputRef={register({ required: true })}
            disabled
            required
          />
        </Grid>
      </Grid>

      <div className={classes.sectionHeader}>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="h6" component="p">
              Hur vill du ta emot beställningen?
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Container>
        <Grid container alignItems="center" justifyContent="center">
          <DeliveryMethods
            deliveryMethod={deliveryMethod}
            setDeliveryMethod={setDeliveryMethod}
          />

          {OUTSIDE_THE_DOOR === deliveryMethod && (
            <Grid item xs={12}>
              <Typography className={classes.warrningTitle}>
                Bor du i villa eller radhus?
              </Typography>
              <Typography className={classes.warrningText}>
                För att du inte ska bli bestulen på din frukost av fåglar så
                rekommenderar vi att du väljer något av våra andra leveranssätt.
              </Typography>
            </Grid>
          )}

          {TYGPASE === deliveryMethod && (
            <Grid item xs={12}>
              <Typography className={classes.warrningTitle}>
                Beställ en påse av oss eller häng ut en på ditt dörrhandtag så lägger vi din beställning i den vid leverans.
              </Typography>
            </Grid>
          )}

          {OTHER === deliveryMethod && (
            <Grid item xs={12} md={8}>
              <Input
                label="Hur? Till exempel i lådan eller i förrådet"
                name="deliveryMethodDescription"
                error={errors.deliveryMethodDescription}
                inputRef={register({ required: true, maxLength: 400 })}
                required
              />
            </Grid>
          )}
        </Grid>
      </Container>

      <div className={classes.sectionHeader}>
        <Grid container justifyContent="center">
          <Grid item>
            <Button
              className={classes.submitButton}
              type="submit"
              variant="contained"
              color="primary"
              disabled
            // disabled={nbProducts === 0 || isPending}
            >
              Just nu går det inte att beställa
              {/* {isPending ? "Laddar" : "Beställ"} */}
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
}

const DeliveryMethods = ({ deliveryMethod, setDeliveryMethod }) => {
  const classes = useStyle();
  const theme = useTheme();

  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Grid
      container
      item
      alignItems="center"
      justifyContent="center"
      className={classes.deliveryMethodContainer}
    >
      {options.map((value) => (
        <Grid
          xs={12}
          md={2}
          key={value.text}
          item
          container
          direction={largeScreen ? "column" : "row"}
          alignItems={largeScreen ? "center" : "center"}
          justifyContent={largeScreen ? "center" : null}
          className={classes.deliveryContainer}
        >
          <Grid
            item
            container
            className={classes.deliveryImageContainer}
            onClick={() => setDeliveryMethod(value.text)}
            style={{
              backgroundColor:
                value.text === deliveryMethod
                  ? theme.palette.primary.main
                  : null,
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <img
                className={classes.deliveryImage}
                src={value.image}
                alt={value.text}
              />
            </Grid>
          </Grid>

          <Grid item className={largeScreen ? null : classes.deliveryMethod}>
            <Typography>{value.text}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const Input = ({ label, name, inputRef, error, required, disabled }) => {
  const classes = useStyle();

  return (
    <div>
      <TextField
        label={label}
        name={name}
        error={!!error}
        variant="outlined"
        inputRef={inputRef}
        fullWidth
        required={required}
        disabled={disabled}
      />
      {error && (
        <span className={classes.fieldError}>{getErrorMessage(error)}</span>
      )}
    </div>
  );

  function getErrorMessage(error) {
    switch (error.type) {
      case "validate": {
        if (name === "email") {
          return "Ogiltlig e-post";
        }

        return "Ogiltligt nummer, börja med +46";
      }
      case "minLength":
        return "Fältet måste minst vara 2 karaktärer.";

      case "maxLength":
        return "Du har angett för många tecken";

      default:
        return "Det här fältet krävs.";
    }
  }
};

import firebase from "firebase/app";
import "firebase/database";
import config from "../../config";
import { flatten } from "../../utils";

firebase.initializeApp(config.firebaseConfigProd);

const database = {
  get(path) {
    return firebase
      .database()
      .ref(path)
      .once("value")
      .then((snap) => snap.val());
  },

  update(diffObj) {
    return firebase.database().ref().update(flatten(diffObj));
  },

  getID() {
    // We use this because the swish api only accepts ids represented by a-z & 0-9
    return Math.random().toString(36).substr(2, 20);
  },

  createRef(path) {
    return firebase.database().ref(path);
  },
};

export default database;

/**
 *
 * Footer
 *
 */

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import FrullaLogo from "../../assets/logo/frulla-logo.svg";
import "./styles.css";

export default function Footer(props) {
  return (
    <>
      <div className="FooterSeperator"></div>


      <div className="FooterSection">
        <div className="FooterContainer FirstFooterContainer">
          <img className="Logo FooterLogo" alt="Frulla" src={FrullaLogo} />
          <Link className="FooterLink" to="/lagforsaljning">
            Tjäna pengar till laget
          </Link>
          <Link className="FooterLink" to="/klassforsaljning">
            Tjäna pengar till klassen
          </Link>
          <Link className="FooterLink" to="/helgjobb">
            Tjäna pengar som ungdom
          </Link>
          <Link className="FooterLink" to="/jobba-hos-frulla">
            Lediga jobb hos Frulla
          </Link>
        </div>
        <div className="FooterContainer">
          <Link className="FooterLink" to="/how-to-get-a-code">
            Har du ingen kod?
          </Link>
          <Link className="FooterLink" to="/contact">
            Kontakt
          </Link>
        </div>
        <div className="FooterContainer LastFooterContainer">
          <Link className="FooterLink" to="/GDPR">
            GDPR
          </Link>
          <Link className="FooterLink" to="/terms">
            Allmänna villkor
          </Link>
          <Link className="FooterLink" to="/about-us">
            Om oss
          </Link>
        </div>
      </div>
    </>
  );
}

Footer.propTypes = {
  props: PropTypes.object,
};

import { createTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import yellow from "@material-ui/core/colors/yellow";
import "formdata-polyfill";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "whatwg-fetch";
import "./App.css";
import RootContainer from "./RootContainer";

const theme = createTheme({
  palette: {
    primary: {
      ...yellow,
      main: "#F6A237",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <RootContainer />
      </Router>

      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;

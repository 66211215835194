import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import PlayersTable from "./playersTable";
import { getPlayers, savePlayer } from "../../services/fakePlayerService";
import _ from "lodash";

class TeamAdminPage extends Component {
    state = {
        teamId: "5b21ca3eeb7f6fbccd471812",
        players: [],
        sortColumn: { path: "name", order: "asc" },
        searchQuery: "",
        sendLogin: {
            isPending: false,
            player: "",
        },
    };

    componentDidMount() {
        const players = getPlayers();
        const thisTeamPlayers = players.filter(p => (p.team.id === this.state.teamId))
        this.setState({ players: thisTeamPlayers })
    }

    handleSort = (sortColumn) => {
        this.setState({ sortColumn });
    };

    toggleSendLogin = (player) => {
        const sendLogin = this.state.sendLogin.isPending ? { isPending: false, player: "" } : { isPending: true, player: player }
        this.setState({ sendLogin });
    }

    SendLogin = (player) => {
        //Write code to send login-details to contact person phonenumber
        console.log(player.contactPerson);
        console.log(player.phoneNumber);


        if (!player.loginIsSent) {
            const copyPlayer = player;
            copyPlayer.loginIsSent = true;

            const copyPlayers = this.state.players;
            copyPlayers[player] = copyPlayer

            this.setState({ players: copyPlayers });
            savePlayer(copyPlayer);
        }
        this.setState({ sendLogin: { isPending: false, player: "" } })
    }

    handleSearch = query => {
        this.setState({ searchQuery: query });
    };

    getVisualData = () => {
        const { players, sortColumn, searchQuery } = this.state;

        let filtered = players;
        if (searchQuery) {
            filtered = players.filter(m =>
                m.firstname.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        const sortedPlayers = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        return { data: sortedPlayers };
    };

    render() {

        const { data: players } = this.getVisualData();
        const { sortColumn, searchQuery, sendLogin } = this.state;

        return (
            <div className="teams_container">
                <h2>FBC Lerum F08</h2>

                <div className="search_and_addteam margin_top">
                    <div><button className="sendFormButton"><Link to="/my-team/member/new">Lägg till ny ungdom</Link></button></div>
                </div>
                {this.state.sendLogin.isPending ? <div>
                    <p>Skicka inloggningsuppgifter till: {`${sendLogin.player.firstname} ${sendLogin.player.lastname}`}</p>
                    <button onClick={() => this.SendLogin(sendLogin.player)}>Skicka</button>
                    <button onClick={this.toggleSendLogin}>Avbryt</button>
                </div> : ""}
                <h3 className="underline">Ungdomar</h3>
                <PlayersTable
                    onSort={this.handleSort}
                    sortColumn={sortColumn}
                    players={players}
                    onSend={this.toggleSendLogin} />
            </div>
        );
    }
}

export default TeamAdminPage;
/**
 *
 * ProductCard
 *
 */
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import RemoveIcon from "@material-ui/icons/Remove";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  ProductCardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 10,
  },
  container: {
    width: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    height: 28,
    width: 28,
    borderRadius: 14,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  font16: {
    fontSize: 16,
    marginLeft: 16,
    marginRight: 16,
  },

  infoContainer: {
    marginTop: 16,
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },

  productInfo: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },

  productName: {
    fontSize: 16,
    fontWeight: "bold",
  },
  productPrice: {
    fontSize: 14,
  },

  infoIconContainer: {
    position: "relative",
    top: 50,
    right: -120,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 36,
    height: 36,
    borderRadius: 18,
  },

  productImage: {
    width: 300,
    height: 300,
    borderRadius: 8,
  },
}));

export default function ProductCard({
  item,
  count = 0,
  addProduct,
  removeProduct,
}) {
  const classes = useStyle();

  const add = () => addProduct(item.id);

  const remove = () => removeProduct(item.id);

  return (
    <div className={classes.ProductCardContainer}>
      <Link to={`/product/${item.id}`}>
        <div className={classes.infoIconContainer}>
          <InfoOutlinedIcon htmlColor="#ffffff" />
        </div>
      </Link>

      <img className={classes.productImage} src={item.image} alt={item.id} />

      <div className={classes.infoContainer}>
        <div className={classes.productInfo}>
          <p className={classes.productName}>{item.title}</p>

          <p className={classes.productPrice}>{item.price} kr</p>
        </div>

        <div className={classes.container}>
          <div className={classes.button} style={{ backgroundColor: "gray" }}>
            <IconButton onClick={remove} aria-label="remove" component="button">
              <RemoveIcon htmlColor="#ffffff" />
            </IconButton>
          </div>

          <Typography className={classes.font16}>{count}</Typography>

          <div className={classes.button}>
            <IconButton onClick={add} aria-label="add" component="button">
              <AddIcon htmlColor="#ffffff" />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  number: PropTypes.number,
};

import React from "react";
import { TextField, makeStyles } from "@material-ui/core";

const useStyle = makeStyles(() => ({
    inputContainer: {
        marginTop: 16,
    },
    error: {
        color: "red",
    }
}));


const Input = ({ label, name, error, onChange, value, type = "text", ...rest }) => {
    const classes = useStyle();

    return (
        <div className={classes.inputContainer}>
            <TextField
                label={label}
                name={name}
                id={name}
                error={error}
                onChange={onChange}
                value={value}
                type={type}
                inputProps={{ maxLength: 250 }}
                variant="outlined"
                fullWidth
                {...rest}
            />
            {error && (
                <span className={classes.error}>{error}</span>
            )}
        </div>
    );
};

export default Input;

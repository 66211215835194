import React from "react";
import Input from "../Common/input";
import { savePlayer, getPlayer } from "../../services/fakePlayerService";
import { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import "./styles.css"


function PlayerForm() {
    const history = useHistory();
    const [state, setState] = useState({
        data: { firstname: "", lastname: "", phonenumber: "", team: { id: "5b21ca3eeb7f6fbccd471812" } },
    })
    const { id: paramsId } = useParams();
    const isNewPlayer = paramsId === "new" ? true : false;

    useEffect(() => {
        if (isNewPlayer) return;

        const player = getPlayer(paramsId);
        if (!player) return history.replace("/not-found");

        setState({ data: player });
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        const data = state.data;
        savePlayer(data);
        history.push("/my-team")
    }

    function handleChange({ currentTarget: input }) {
        const data = { ...state.data }
        data[input.name] = input.value
        setState({ data })
    }

    const { firstname, lastname, phonenumber } = state.data;

    return (
        <div className="addTeam_container">
            <button><Link to="/my-team">Tillbaka</Link></button>
            <h2>{isNewPlayer ? "Lägg till ny ungdom" : ""}</h2>
            <form onSubmit={e => handleSubmit(e)}>
                <Input label="Förnamn" name="firstname" onChange={handleChange} value={firstname} required />
                <Input label="Efternamn" name="lastname" onChange={handleChange} value={lastname} required />
                <Input label="Telefonnummer" name="phonenumber" onChange={handleChange} value={phonenumber} required />
                <button className="sendFormButton">{isNewPlayer ? "Skapa ungdom" : "Spara"}</button>
            </form>
        </div >
    );
};

export default PlayerForm;
import "./styles.css";
import {
    Button,
} from "@material-ui/core";
import Footer from "../Footer";
import SectionHeader from "../SectionHeader";
import { useAppStateContext } from "../../hooks/useAppState";

export default function WorkWithFrulla() {
    const { onHomePageClick } = useAppStateContext();
    return (
        <div>
            <SectionHeader title="Leveransansvarig till idrottslag" />
            <div className="workwithfrulla_container">
                <ul className="workwithfrulla_list_car">
                    <p className="workwithfrulla_p">Vill du jobba extra vid sidan av ditt vanliga jobb eller studier?</p>
                    <p className="workwithfrulla_p">Då har vi möjlighet att erbjuda dig roliga lördagsmorgnar där vi tillsammans sätter guldkant på både idrottsungdomars- och deras kunders helger!</p>
                    <li className="workwithfrulla_item">Kort om rollen</li>
                    <p className="workwithfrulla_p">Som leveransansvarig på Frulla jobbar du med att:</p>
                    <ul>
                        <li className="workwithfrulla_p">Packa frukostbeställningar.</li>
                        <li className="workwithfrulla_p">Köra ut beställningar till idrottsungdomar i Göteborg.</li>
                    </ul>
                    <p className="workwithfrulla_p">Du blir en del av ett härligt gäng på 15 glada kollegor i samma roll. Dina lördagsmorgnar består till 50% av att packa beställningar och 50% av leveranser till idrotssungdomar.</p>
                    <p className="workwithfrulla_p">För denna tjänst krävs ingen tidigare erfarenhet av liknande arbete. Vi tycker att personliga egenskaper är viktigare än formella meriter och söker dig som är en positiv samt ansvarsfull lagspelare.</p>
                    <p className="workwithfrulla_p">Tack vare det stora intresset från ytterligare idrottslag som vill starta en försäljning med oss så söker vi flera personer till denna roll. Därav finns det goda möjligheter att få jobba tillsammans med dina vänner om ni är flera som ansöker.</p>
                    <li className="workwithfrulla_item">Kort om oss</li>
                    <p className="workwithfrulla_p">Frulla.se är en snabbväxande tjänst där kunder köper frukostprodukter till lördagsmorgnar för att stötta den lokala ungdomsidrotten.</p>
                    <p className="workwithfrulla_p">Kunder beställer på frulla.se med en kod som de får av en idrottsungdom och på lördag morgon får de sedan sin beställning levererad av samma ungdom.</p>
                    <li className="workwithfrulla_item">Upplägg och kontakt</li>
                    <p className="workwithfrulla_p">Omfattning och arbetstider: Extraarbete på lördagar kl 05:30-09:00.</p>
                    <p className="workwithfrulla_p">Placering: Packning och leverans utgår från Askim.</p>
                    <p className="workwithfrulla_p">Krav: Körkort och tillgång till bil.</p>
                    <p className="workwithfrulla_p">Lön: 150kr/timme + semesterersättning samt milersättning.</p>
                    <p className="workwithfrulla_p">Tillträde till tjänsten: Omgående.</p>
                </ul>
                <br></br>
            </div>
            <SectionHeader title="Intresseanmälan" />
            <div className="workwithfrulla_container">
                <p className="workwithfrulla_p extra_margin_bottom">Är du intresserad? Skicka namn och telefonnummer till <a href="mailto:niclas@frulla.se">niclas@frulla.se</a> så hör vi av oss inom kort.</p>
                <Button
                    className="submitButton"
                    variant="contained"
                    color="primary"
                    onClick={onHomePageClick}
                >Tillbaka till startsidan
                </Button>
            </div>
            <Footer />
        </div>
    );
}
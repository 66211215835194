import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../../Common/table";

class TeamsTable extends Component {
    columns = [
        { path: "name", label: "Lagnamn", content: team => <Link to={`/admin-page/teams/${team.id}`}>{team.name}</Link> },
        { path: "contactPerson", label: "Kontaktperson" },
        { path: "phoneNumber", label: "Telefonnummer" },
        { path: "address", label: "Adress" },
        { path: "finalSellingDate", label: "Försäljning till" },
        { path: "loginIsSent", label: "Skicka login", content: team => <button className={team.loginIsSent ? "greybutton" : "bluebutton"} onClick={() => this.props.onSend(team)}> Skicka</button> },
    ]
    render() {
        const { orders, onSort, sortColumn } = this.props;

        return (
            <div>
                <Table
                    columns={this.columns}
                    data={orders}
                    sortColumn={sortColumn}
                    onSort={onSort} />
            </div>
        );
    }
};

export default TeamsTable;
const players = [
    {
        id: "abc123",
        firstname: "Erik",
        lastname: "Eriksson",
        deliverycode: "1234Erik",
        phonenumber: "0709-121212",
        active: true,
        team: {
            id: "5b21ca3eeb7f6fbccd471812",
            name: "Floda IBK F09",
            teamPercentEarning: 0.02,
            personalPercentEarning: 0.175,
        },
        loginIsSent: false,
    },
    {
        id: "abb122",
        firstname: "Johan",
        lastname: "Johansson",
        deliverycode: "4321Johan",
        phonenumber: "0705-334455",
        active: true,
        team: {
            id: "5b21ca3eeb7f6fbccd471812",
            name: "Floda IBK F09",
            teamPercentEarning: 0.02,
            personalPercentEarning: 0.175,
        },
        loginIsSent: true,
    },
    {
        id: "aad333",
        firstname: "Jan",
        lastname: "Josefsson",
        deliverycode: "2222Jan",
        phonenumber: "0706-987123",
        active: true,
        team: {
            id: "5b21ca3eeb7f6fbccd471813",
            name: "Askims IK P06",
            teamPercentEarning: 0.02,
            personalPercentEarning: 0.175,
        },
    },
    {
        id: "bfd543",
        firstname: "Johanna",
        lastname: "Green",
        deliverycode: "9119Johanna",
        phonenumber: "0760-512472",
        active: true,
        team: {
            id: "5b21ca3eeb7f6fbccd471814",
            name: "Ebba Peterssonskolan 7C",
            teamPercentEarning: 0.25,
            personalPercentEarning: 0,
        },
        loginIsSent: false,
    },
    {
        id: "eee113",
        firstname: "Matilda",
        lastname: "Berg",
        deliverycode: "5454Matilda",
        phonenumber: "0703-331902",
        active: true,
        team: {
            id: "5b21ca3eeb7f6fbccd471812",
            name: "Floda IBK F09",
            teamPercentEarning: 0.02,
            personalPercentEarning: 0.175,
        },
        loginIsSent: false,
    },
];

export function getPlayers() {
    return players;
}

export function getPlayer(id) {
    return players.find(p => p.id === id);
}

export function savePlayer(player) {
    let playerInDb = players.find(p => p.id === player.id) || {};
    playerInDb.firstname = player.firstname;
    playerInDb.lastname = player.lastname;
    playerInDb.deliverycode = player.deliverycode;
    playerInDb.phonenumber = player.phonenumber;
    playerInDb.active = player.active;
    playerInDb.team = player.team;

    if (!playerInDb.id) {
        playerInDb.id = Date.now().toString();
        players.push(playerInDb);
    }

    return playerInDb;
}

export function deletePlayer(id) {
    let playerInDb = players.find(p => p.id === id);
    players.splice(players.indexOf(playerInDb), 1);
    return playerInDb;
}

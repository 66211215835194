import React, { Component } from "react";
import Collapsible from "../Collapsible";
import Footer from "../Footer";
import SectionHeader from "../SectionHeader";
import Input from "../Common/input";
import SendEmail from "../Common/sendEmail";
import { Link } from "react-router-dom";
import "./styles.css";
export default class TeamPage extends Component {
  state = {
    data: { emailSubject: "Intresseanmälan lagförsäljning", name: "", phonenumber: "", other: "", email: "", team: "" },
    isPending: false,
  }

  async handleSubmit(event) {
    const data = this.state.data;
    this.setState({ isPending: true })
    await SendEmail(event, data);
    this.setState({ isPending: false })
  }


  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data }
    data[input.name] = input.value
    this.setState({ data })
  }

  render() {

    return (
      <div>
        <SectionHeader title="Tjäna pengar till laget" />
        <div className="background_infopage">
          <div className="container_infopage">
            <h2 className="h2_infopage">En svårslagen tjänst för kontinuerlig förtjänst!</h2>
            <p className="text_infopage">Med Frulla delar laget ut nybakt bröd och annat efterlängtat till grannar på lördagsmorgnar. De får starta helgen på bästa sätt samtidigt som ni på ett enkelt sätt tjänar pengar till resor, aktiviteter eller cuper.</p>
            <p className="text_infopage"><b>PSST…</b> Föräldrar och lagledare, ni kan ta sovmorgon! Frulla sköter all administration - kunder betalar direkt på frulla.se och lagets ungdomar får dessutom sina kunders beställningar levererade hem till dörren.</p>
            <p className="text_infopage">Det är jättelätt att komma igång! Så här fungerar det: </p>

            <Collapsible
              heading="Kom igång"
              body={<>
                <h3>Steg 1</h3>
                <p>Alla i laget får startpaket innehållande foldrar och varsin personlig leveranskod.</p>
                <h3>Steg 2</h3>
                <p>Under första veckan delar laget ut foldrar till grannar i sina områden. I foldern står all information de behöver veta för att kunna beställa med ungdomens leveranskod.</p>
                <h3>Steg 3</h3>
                <p>Grannen går själv in på frulla.se, fyller i koden och klickar hem de produkter som önskas till lördag morgon.</p>
              </>} />

            <Collapsible
              heading="Leverans"
              body={<>
                <h3>Steg 1</h3>
                <p>På lördagsmorgnar levereras färdigpackade beställningar hem till varje ungdom i laget. De får ett sms i samband med att påsarna lämnas utanför deras dörr.</p>
                <h3>Steg 2</h3>
                <p>På brödpåsarna ser ungdomen vilka som har beställt och var de bor så att han/hon enkelt kan leverera till de kunder som beställt i sitt område.</p>
                <span>På fredagar ser de hur många kunder som beställt samt hur mycket de tjänat till laget.</span>
              </>} />

            <Collapsible
              heading="Återkommande beställningar"
              body={<>
                <p>Efter att kunder fått ungdomens kod så kan de enkelt fortsätta att beställa på vår hemsida även nästkommande veckor. På torsdagar får befintliga kunder en uppskattad påminnelse för att hjälpa dem komma ihåg att beställa inför helgen. Vidare har vi kontinuerligt nya produkter, kampanjer och erbjudanden för att hjälpa laget att få återkommande beställningar.</p>
              </>} />

            <Collapsible
              heading="Vad tjänar laget?"
              body={<>
                <p>25% ersättning på alla produkter som säljs. 100kr i försäljning ger 25kr i förtjänst.</p>
                <h3>Exempel för ett lag på 20 personer</h3>
                <li>10st beställningar per person = 6.000kr i förtjänst/vecka.</li>
                <li>4 veckor = 24.000kr i förtjänst</li>
                <li>8 veckor = 48.000kr i förtjänst</li>
                <li>12 veckor = 72.000kr i förtjänst</li>
                <span>Ovanstående exempel beräknas på 120kr per beställning vilket är genomsnittet på frulla.se.</span>
              </>} />

            <Collapsible
              heading="Bra att känna till"
              body={<>
                <h3>Kan ungdomar i laget dela sin egen kod på sociala medier?</h3>
                <p>Det går utmärkt så länge de kan leverera till personen som beställer av dem. Många delar vanligtvis sin kod i mindre områdesgrupper på Facebook.</p>
                <h3>Vad händer om någon i laget blir sjuk eller ska bort en helg?</h3>
                <p>Inga konstigheter! Om ungdomen inte kan leverera under en eller flera helger smsar de oss så hjälper vi till med utkörning direkt till kund.</p>
                <h3>Hur lång tid tar det att gå ut med beställningar på lördagsmorgnar?</h3>
                <p>Då ungdomen levererar i sitt eget område så går det fort att gå ut med brödpåsarna till sina grannar. Vanligtvis tar det ungefär 30 minuter att gå ut med 10 beställningar.</p>
                <h3>Hur länge fortsätter laget att leverera frukost till grannar?</h3>
                <p>Ni väljer själva hur lång period ni vill leverera nybakt och när ni känner er nöjda hör ni av er till oss. Varje ungdom i laget får då möjlighet att välja om de vill fortsätta att leverera nybakt till sina kunder genom Frulla som helgjobb. Om man tackar nej till fortsättning så tar vi bort ungdomens leveranskod och meddelar samtliga kunder samtidigt som vi tackar för deras stöd.</p>
                <h3>Finns det alltså möjlighet att fortsätta med Frulla som helgjobb?</h3>
                <p>Absolut, det fungerar precis som i lagaktiviteten! Ungdomen tjänar då pengar till sig själv istället och får ett helgjobb på sitt CV! Klicka på <b><Link to="/helgjobb">Frulla som helgjobb</Link></b> för att läsa mer.</p>
              </>} />
            <p className="text_infopage">
              Vill du ha fler alternativ på enkla och lättsålda produkter för laget kan du även spana in vår andra hemsida, Lagshoppen. Ta genvägen dit genom att klicka på länken <b><a href="https://www.lagshoppen.se/tjana-pengar/lagforsaljning" target="_blank">www.lagshoppen.se/tjana-pengar/lagforsaljning</a></b>
            </p>
          </div>
        </div>
        <SectionHeader title="Intresseanmälan" />
        <div className="background_infopage">
          <div className="emailForm">
            <h2 className="contact_infopage">
              Är ni intresserade av att veta mer eller redo testa på Frulla med laget? Fyll i formuläret nedan så hör vi av oss till dig på sms eller mail.
            </h2>

            <form onSubmit={e => this.handleSubmit(e)}>
              <Input name="name" label="Namn" value={this.state.data.name} onChange={this.handleChange} required />
              <Input name="team" label="Förening/Lag" value={this.state.data.address} onChange={this.handleChange} required />
              <Input name="phonenumber" label="Telefonnummer" value={this.state.data.phonenumber} onChange={this.handleChange} required />
              <Input name="email" label="Email" value={this.state.data.email} onChange={this.handleChange} />
              <Input name="other" label="Meddelande" value={this.state.data.other} onChange={this.handleChange} multiline rows={3} />
              <button className="sendFormButton" disabled={this.state.isPending} >
                Skicka
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

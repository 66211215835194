/**
 *
 * ErrorOrder
 *
 */

import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";
import {
  Button,
} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

export default function ErrorOrder({ status, resetOrder, resetAppState }) {
  const history = useHistory();

  const onCancel = () => {
    resetAppState();

    history.push("/");
  };

  const classes = useStyle();

  return (
    <div className={classes.container}>
      <div className="error-order-message-container">
        <p className="error-order-text">Betalningen avbröts</p>
      </div>
      <div>
        <p className="error-order-p">De flesta betalningar som avbryts beror på att:</p>
        <li className="error-order-p">Du själv avbrutit betalningen i swishappen.</li>
        <li className="error-order-p">En siffra blivit fel i ditt telefonnummer.</li>
        <li className="error-order-p">Du har ett pågående ärende i swish. Avbryt betalningen i swish-appen innan du försöker igen.</li>
      </div>
      <div className="error-order-buttons-container ">
        <Button
          className="submitButton"
          variant="contained"
          color="primary"
          onClick={resetOrder}>
          Tillbaka till varukorgen
        </Button>

        <Button
          className="errorButton"
          variant="contained"
          onClick={onCancel}>
          Töm varukorgen
        </Button>

      </div>
    </div>
  );
}

ErrorOrder.propTypes = {
  props: PropTypes.object,
};

import moment from "moment";

export { default as calculateTotalPrice } from "./calculateTotalPrice";
export { default as flatten } from "./flatten";
export * from "./validators";

moment.updateLocale("se", {
  months: [
    "januari",
    "febuari",
    "mars",
    "pril",
    "maj",
    "juni",
    "juli",
    "augusti",
    "september",
    "oktober",
    "november",
    "december",
  ],
});

export function getDeliveryDate() {
  const suterday = 6; // Lördag
  const lastDayToOrder = 4; // Torsdag
  const today = moment().isoWeekday();

  if (today <= lastDayToOrder) {
    return moment().locale("se").isoWeekday(suterday).format("DD MMMM");
  } else {
    return moment()
      .locale("se")
      .add(1, "weeks")
      .isoWeekday(suterday)
      .format("DD MMMM");
  }
}

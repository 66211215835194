import React from "react";
import Input from "./input"

const SearchBox = ({ value, onChange, ...rest }) => {
    return (
        <Input
            type="text"
            name="query"
            className="form-control my-3"
            placeholder="Search..."
            value={value}
            onChange={e => onChange(e.currentTarget.value)}
            {...rest}
        />
    );
};

export default SearchBox;

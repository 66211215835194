import React, { Component } from "react";
import { Link } from "react-router-dom";
import SellerOrdersTable from "./sellerOrdersTable";
import { getOrders } from "../../services/fakeOrderService";
import SectionHeader from "../SectionHeader";
import _ from "lodash";

class SellerAdminPage extends Component {
    state = {
        myDeliverycode: "0101vita",
        myOrders: [],
        sortColumn: { path: "name", order: "asc" },
    };

    componentDidMount() {
        const myOrders = getOrders();
        this.setState({ myOrders: myOrders.filter(o => (o.deliverycode.toLowerCase() === this.state.myDeliverycode.toLowerCase())) })
    }

    handleSort = (sortColumn) => {
        this.setState({ sortColumn });
    };


    getVisualData = () => {
        const { myOrders, sortColumn } = this.state;

        const sortedOrders = _.orderBy(myOrders, [sortColumn.path], [sortColumn.order]);

        return { data: sortedOrders };
    };

    render() {

        const { data: myOrders } = this.getVisualData();
        const { sortColumn } = this.state;

        return (
            <div className="teams_container">
                <div>
                    <SectionHeader title="Leverans vecka 23" />
                    <div className="weekly_orders">
                        <p>{`<-- Vecka 22`}</p><p>{`Vecka 24 -->`}</p>
                    </div>
                    <h2 className="margin_top">Orders</h2>
                    <p>Antal beställningar: <b>2st</b></p>
                    <p>Min förtjänst: <b>46kr</b></p>
                    <SellerOrdersTable
                        sortColumn={sortColumn}
                        orders={myOrders}
                        onSort={this.handleSort} />
                </div>
            </div>
        );
    }
}

export default SellerAdminPage;
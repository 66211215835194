/**
 *
 * GDPR
 *
 */

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import BackArrowImage from "../../assets/img/back-arrow.png";

export default function GDPR(props) {
  return (
    <div className="TermsContainer">
      <div className="MarginLeft">
        <Link className="BackArrow" to="/">
          <img className="BackArrowImage" src={BackArrowImage} alt="back" />
        </Link>
      </div>

      <p className="TermsTitle">
        <strong>GDPR</strong>
      </p>
      <p className="TermsText">
        <strong>Behandling av personuppgifter</strong>
      </p>
      <p className="TermsText">
        Vi på Frulla Sverige AB org nr 559262-1105 anser att din integritet är
        viktig för oss och förklarar i denna policy hur vi behandlar och skyddar
        dina personuppgifter enligt dataskyddsförordningen. Vi förklarar även
        vad du har för rättigheter och hur du kan komma i kontakt med oss.
      </p>
      <p className="TermsText">Dokumentet är uppdaterat 2022-05-10</p>

      <p className="TermsText">
        Här nedan har vi gjort det tydligt för dig att se vilka personuppgifter
        vi behandlar och vad Frullas syfte är med behandlingen. Frulla har som
        målsättning att samla in personuppgifter som är relevanta och inte
        använda personuppgifterna till annat än att uppfylla dess syfte.
      </p>

      <p className="TermsText">
        <strong>Hur samlar Frulla in uppgifter</strong>
      </p>

      <p className="TermsText">
        Lämnande av personuppgifter till Frulla sker: Vid kontakt med Frulla via
        mail, telefonsamtal eller formulär på hemsidan, samt vid beställning. Uppgifter: Namn,
        e-postadress, telefonnummer, adress, leveranskod, leveranssätt,
        ordernummer.
      </p>

      <p className="TermsText">
        <strong>Hur vi behandlar personuppgifter</strong>
      </p>
      <p className="TermsText">
        <em>Kontaktuppgifter::</em>
        <br />
        Namn, leveransadress, telefonnummer, e-postadress, leveranskod. Rättslig
        grund: Intresseavvägning. Syfte: Vi behöver veta vem vi pratar med och
        se tidigare konversationer oss emellan för att på bästa sätt kunna
        hjälpa till med aktuella ärenden när du kontaktar oss. Leveranskod
        används för att vi ska se vilken ungdom som sålt och levererat hem till
        dig. Lagringstid: 1 år efter sista kontakt med Frulla.
      </p>

      <p className="TermsText">
        <em>Beställningsuppgifter:</em>
        <br />
        Namn, leveransadress, leveranssätt, telefonnummer, e-postadress,
        ordernummer, orderhistorik, leveranskod. Rättslig grund: Avtal. Syfte:
        Namn, leveransadress, leveranssätt och leveranskod behöver vi för att
        kunna leverera din beställning. Denna information kommer ihop med
        orderinformationen göras synlig för den ungdom som levererar. Din
        e-postadress använder vi för att skicka kvitto till dig och ditt
        telefonnummer använder vi för att kunna informera om leverans.
        Lagringstid: 2 år efter sista beställning.
      </p>

      <p className="TermsText">
        <em>Kommunikationsuppgifter:</em>
        <br />
        Namn, e-postadress, telefonnummer, leveranskod. Rättslig grund:
        Intresseavvägning. Syfte: Om du handlat hos oss så använder vi dessa
        uppgifter för att kunna skicka information, erbjudanden eller
        påminnelser om veckans deadline till dig. Vi använder även uppgifterna
        för att informera om vi inte längre kan leverera till din adress eller
        om utbudet av produkter förändras. Lagringstid: 2 år efter sista
        beställning.
      </p>

      <p className="TermsText">
        <em>Arkiveringsuppgifter:</em>
        <br />
        Namn, e-postadress, telefonnummer, leveransadress, leveranskod,
        ordernummer. Rättslig grund: Rättslig förpliktelse. Syfte: Vi sparar
        dessa uppgifter för att följa den lagstiftning som gäller för
        arkivering. Lagringstid: 10 år
      </p>

      <p className="TermsText">
        <strong>Frulla delar personuppgifter med:</strong>
      </p>
      <p className="TermsText">
        Ungdom kopplad till angiven leveranskod - Den information som delas med
        ungdomen är nödvändig information för att kunna hantera
        leveransen av din beställning.
      </p>

      <p className="TermsText">
        <strong>Säkerhet</strong>
      </p>
      <p className="TermsText">
        Frulla behandlar dina uppgifter med största försiktighet och strävar
        efter att alltid använda ett uppdaterat och lämpligt skydd mot
        dataintrång.
      </p>

      <p className="TermsText">
        <strong>Ändringar</strong>
      </p>
      <p className="TermsText">
        Frulla äger rätten att uppdatera denna policy och rekommenderar dig att
        ta del av den senast uppdaterade versionen. Datum för uppdatering finner
        du i dokumentet.
      </p>

      <p className="TermsText">
        <strong>Dina rättigheter</strong>
      </p>
      <p className="TermsText">
        Rätt till information – Du har rätt att få information om hur dina
        uppgifter behandlas. Du kan läsa om hur vi behandlar dina uppgifter i
        det här dokumentet. Har du vidare frågor går det bra att kontakta
        personuppgiftsansvarig som du hittar i slutet av dokumentet.
      </p>
      <p className="TermsText">
        Rätt till registerutdrag – Du har rätt att begära en kopia på de
        uppgifter som vi har sparade om dig. För att vi ska lämna ut
        informationen behöver vi kunna identifiera dig, detta för att
        information inte ska delas till obehöriga.
      </p>
      <p className="TermsText">
        Rätt till rättelse – Du har rätt att få sparade felaktiga uppgifter
        rättade.
      </p>
      <p className="TermsText">
        Rätt till radering – Vi raderar ditt konto på din begäran eller att
        kontot har varit inaktivt i 5 år. Innan radering meddelar vi dig så att
        du har chansen att förhindra radering.
      </p>
      <p className="TermsText">
        Rätt till begränsning av behandling – Vid felaktiga uppgifter har du
        rätt att begränsa behandlingen av de felaktiga uppgifterna.
      </p>
      <p className="TermsText">
        Dataportabilitet – På begäran har du rätt att få dina uppgifter skickade
        till andra organisationer.
      </p>
      <p className="TermsText">
        Rätt att göra invändningar – Du har rätt att göra invändningar på vår
        behandling av dina personuppgifter.
      </p>
      <p className="TermsText">
        Rätt att lämna klagomål till datainspektionen – Vi ser gärna att du hör
        av dig till oss om du inte är nöjd med hur vi hanterar behandlingen av
        dina personuppgifter för att ge oss chansen till förbättring.
      </p>
      <p className="TermsText">
        Om du inte vill höra av dig till oss har du ändå alltid rätt att lämna
        klagomål till datainspektionen, för mer information om hur sådan anmälan
        går till kan du läsa om på datainspektionen{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.datainspektionen.se/vagledningar/for-dig-som-privatperson/klagomal-och-tips/"
        >
          hemsida
        </a>
        .
      </p>

      <p className="TermsText">
        <strong>Kontaktuppgifter</strong>
      </p>
      <p className="TermsText">
        För att utöva någon av dina rättigheter eller i annat ärende komma i
        kontakt med oss gör du det genom att använda uppgifterna nedan.
      </p>
      <p className="TermsText">Victor Eriksson Elfsberg</p>
      <p className="TermsText">Email: victor@frulla.se || Tel: 0724-545151</p>
    </div>
  );
}

GDPR.propTypes = {
  props: PropTypes.object,
};

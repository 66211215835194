import React from "react";
import Input from "../../Common/input";
import { saveUser, getUser } from "../../../services/fakeUserService";
import { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import "./styles.css"


function UserForm() {
    const history = useHistory();
    const [state, setState] = useState({
        data: { name: "", deliverycode: "", address: "", phoneNumber: "", city: "", team: "", },
    })
    const { id: paramsId } = useParams();
    const isNewuser = paramsId === "new" ? true : false;

    useEffect(() => {
        if (isNewuser) return;

        const user = getUser(paramsId);
        if (!user) return history.replace("/not-found");

        setState({ data: user });
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        const data = state.data;
        saveUser(data);
        history.push("/admin-page/users")
    }

    function handleChange({ currentTarget: input }) {
        const data = { ...state.data }
        data[input.name] = input.value
        setState({ data })
    }

    const { name, deliverycode, address, phoneNumber, team, active, city } = state.data;

    return (
        <div className="addTeam_container">
            <button><Link to="/admin-page/users">Tillbaka</Link></button>
            <h2>{isNewuser ? "Lägg till ny user" : "Gör ändring i befintlig user"}</h2>
            <form onSubmit={e => handleSubmit(e)}>
                <Input label="Ungdomens namn" name="name" onChange={handleChange} value={name} required />
                <Input label="Lag" name="team" value={team} required />
                <Input label="Leveranskod" name="deliverycode" value={deliverycode} required />
                <Input label="Adress" name="address" onChange={handleChange} value={address} required />
                <Input label="Ort" name="city" onChange={handleChange} value={city} required />
                <Input label="Telefonnummer" name="phoneNumber" onChange={handleChange} value={phoneNumber} required />
                <button className="sendFormButton">{isNewuser ? "Skapa user" : "Spara"}</button>
            </form>
        </div >
    );
};

export default UserForm;
import React from "react";
import { Button } from "@material-ui/core";
import "./styles.css";
import { useAppStateContext } from "../../hooks/useAppState";


export default function SuccessSubsription() {
    const { onHomePageClick } = useAppStateContext();
    return (
        <div className="successform_item">
            <h3 className="successform_heading">Tack för visat intresse!</h3>
            <p className="successform_p">Vi hör av oss till dig inom kort</p>
            <Button
                variant="contained"
                color="primary"
                className="submitButton"
                onClick={onHomePageClick}
            >Till startsidan</Button>
        </div>
    );
}
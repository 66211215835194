const users = [
    {
        id: "123abc",
        name: "Victor Eriksson",
        deliverycode: "0203victor",
        phoneNumber: "0724-545151",
        address: "Bohusgatan 24",
        postalCode: "41139",
        city: "Göteborg",
        team: "FBC Lerum P07",
        myEarnings: 0,
        active: true,
    },
    {
        id: "234bcd",
        name: "Niclas Boquist",
        deliverycode: "0202niclas",
        phoneNumber: "0702-121212",
        address: "Holmenkollen 147",
        postalCode: "41139",
        city: "Göteborg",
        team: "FBC Lerum P07",
        myEarnings: 719,
        active: true,
    },
];

export function getUsers() {
    return users;
}

export function getUser(id) {
    return users.find(u => u.id === id);
}

export function saveUser(user) {
    let userInDb = users.find(o => o.id === user.id) || {};
    userInDb.name = user.name;
    userInDb.deliverycode = user.deliverycode;
    userInDb.phoneNumber = user.phoneNumber;
    userInDb.address = user.address;
    userInDb.postalCode = user.postalCode;
    userInDb.city = user.city;
    userInDb.team = user.team;
    userInDb.myEarnings = user.myEarnings;
    userInDb.active = user.active;

    if (!userInDb.id) {
        userInDb.id = Date.now().toString();
        users.push(userInDb);
    }

    return userInDb;
}

export function deleteUser(id) {
    let userInDb = users.find(o => o.id === id);
    users.splice(users.indexOf(userInDb), 1);
    return userInDb;
}

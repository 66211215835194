import './styles.css';
import ClockIcon from '../../assets/img/iconClock.svg';
import DeliveryIcon from '../../assets/img/iconDelivery.svg';
import SportsIcon from '../../assets/img/iconSport.svg';

function Slider() {

    return (
        <div className="slider">
            <div className="wrapper">
                <div className="slides">
                    <img className="slider-icon" src={ClockIcon} alt="En klocka" ></img>
                    <span className="slide">Beställ senast torsdag 23:59</span>
                </div>
                <div className="slides">
                    <img className="slider-icon" src={DeliveryIcon} alt="En ungdom på moped" ></img>
                    <span className="slide">Hemleverans lördag morgon</span>
                </div>
                <div className="slides">
                    <img className="slider-icon" src={SportsIcon} alt="En fotboll" ></img>
                    <span className="slide">Stötta ungdomar, lag och klasser</span>
                </div>
                <div className="slides">
                    <img className="slider-icon" src={ClockIcon} alt="En klocka" ></img>
                    <span className="slide">Beställ senast torsdag 23:59</span>
                </div>
            </div>
        </div>

    );
}

export default Slider;
import React, { Component } from "react";
import Table from "../../Common/table";
import { Link } from "react-router-dom";

class UsersTable extends Component {
    columns = [
        { path: "deliverycode", label: "Leveranskod", content: user => <Link to={`/admin-page/users/${user.id}`}>{user.deliverycode}</Link> },
        { path: "name", label: "Namn" },
        { path: "phoneNumber", label: "Telefonnummer" },
        { path: "team", label: "Lag" },
    ]

    render() {
        const { users, onSort, sortColumn } = this.props;

        return (
            <div>
                <Table
                    columns={this.columns}
                    data={users}
                    sortColumn={sortColumn}
                    onSort={onSort} />
            </div>
        );
    }
};

export default UsersTable;
import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  sectionHeader: {
    // marginTop: 30,
    marginBottom: 30,
  },

  section: {
    backgroundColor: "#F4F4F4",
    height: 90,
  },
}));

const SectionHeader = ({ title }) => {
  const classes = useStyle();

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.sectionHeader}
    >
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignContent="center"
        className={classes.section}
      >
        <Grid item>
          <Typography variant="h6" component="p">
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionHeader;

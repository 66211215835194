export const validateEmail = (email) => {
  /* eslint-disable */
  const emailRegExp =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  /* eslint-enable */
  return emailRegExp.test(email);
};

export const validatePhoneNumber = (phoneNumber) => {
  // Swedish Phone numbers
  let regex =
    /^[(]?[+]?([4][6])[)]?[ -]?(\d{1})[ -]?(\d{1})[ -]?(\d{1})[ -]?(\d{1})[ -]?(\d{1})[ -]?(\d{1})[ -]?(\d{1})[ -]?(\d{1})[ -]?(\d{1})[ -]?$/;

  return regex.test(phoneNumber);
};

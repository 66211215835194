import React from "react";
import ciabattalevainImage from "../assets/img/ciabattalevain.jpg";
import croissantImage from "../assets/img/croissant.jpg";
import frobrodImage from "../assets/img/frobrod.jpg";
import gingerBreadImage from "../assets/img/gingerBread.jpg";
import gourmetknyteImage from "../assets/img/gourmet.jpg";
import granolaImage from "../assets/img/granola.jpg";
import julkaffeImage from "../assets/img/julkaffe.jpg";
import kramkaffeImage from "../assets/img/kaffekram.jpg";
import kallarfranskaImage from "../assets/img/kallarfranska.jpg";
import kanelbulleImage from "../assets/img/kanelbulle.jpg";
import kokoskladdkakaImage from "../assets/img/kokoskladdkaka.jpg";
import lemonShotImage from "../assets/img/lemonShot.jpg";
import levainImage from "../assets/img/levain.jpg";
import lyxmusliImage from "../assets/img/lyxmusli.jpg";
import mummabulleImage from "../assets/img/mummabulle.jpg";
import muslibrackImage from "../assets/img/muslibrack.jpg";
import ostbrackImage from "../assets/img/ostbrack.jpg";
import pistagebulleImage from "../assets/img/pistagebulle.jpg";
import pomegranateShotImage from "../assets/img/pomegranateShot.jpg";
import sichuanShotImage from "../assets/img/ShotSichuan.jpg";
import ragbulleImage from "../assets/img/ragbulle.jpg";
import saffranskattImage from "../assets/img/saffranskatt.jpg";
import solrosrutaImage from "../assets/img/solrosruta.jpg";
import solsnackaImage from "../assets/img/solsnacka.jpg";
import surdegsVortbrodImage from "../assets/img/surdegsvort.jpg";
import tekakaImage from "../assets/img/tekaka.jpg";
import tranbarImage from "../assets/img/tranbar.jpg";
import vallmobrackImage from "../assets/img/vallmobrack.jpg";
import vortbrodImage from "../assets/img/vortmrussin.jpg";
import vortbrodUtanRussinImage from "../assets/img/vortbrod.jpg";
import semlaImage from "../assets/img/semla.jpg";
import karleksmumsImage from "../assets/img/karleksmums.jpg";
import marmeladFikonImage from "../assets/img/marmeladFikon.jpg";
import marmeladHallonImage from "../assets/img/marmeladHallon.jpg";
import marmeladAprikosImage from "../assets/img/marmeladAprikos.jpg";
import briocheImage from "../assets/img/brioche.png";
import sarabulleImage from "../assets/img/sarabulle.jpg";
import wienerImage from "../assets/img/wienerbrod.jpg";
import wienerChokladImage from "../assets/img/wienerchoklad.jpg";
import fikonValnotImage from "../assets/img/fikonvalnot.jpg";
import barkisImage from "../assets/img/barkis.jpg";
import BagImage from "../assets/img/tygpase.jpg";
import morotsmumsImage from "../assets/img/morotsmums.jpg";
import wienerHallonImage from "../assets/img/wienerrutaHallon.jpg";
import dansktRagbrodImage from "../assets/img/dansktragbrod.jpg";
import pumpabrackImage from "../assets/img/pumpabrack.jpg";
import rusticFrallaImage from "../assets/img/rusticFralla.jpg";
import paveRusticImage from "../assets/img/paverustic.jpg";
import formbakadLevainImage from "../assets/img/formbakadlevain.jpg";
import transbarSurdegImage from "../assets/img/tranbarsSurdeg.jpg";
import broverraskningImage from "../assets/img/broverraskning.jpg";
import fikabroverraskningImage from "../assets/img/fikabroverraskning.jpg";
import croissantChocolateImage from "../assets/img/croissantchoklad.jpg";
import appleJuiceImage from "../assets/img/applejuice.jpg";
import smoothieImage from "../assets/img/smoothie.jpg";
import myntaLajmonadImage from "../assets/img/myntalajmonad.jpg";
import teaSommargottImage from "../assets/img/tesommargott.jpg";
import teaBlabarLingonYoghurtImage from "../assets/img/teblabarlingonyoghurt.jpg";
import teaVittmullbarImage from "../assets/img/tevittmullbar.jpg";
import kaffepaketImage from "../assets/img/kaffepaket.jpg";
import rusticRagsurImage from "../assets/img/rusticragsur.jpg";
import rusticRagsurRussinImage from "../assets/img/rusticragsurmrussin.jpg";
import vetesurImage from "../assets/img/vetesursolroschiavallmo.jpg";
import berlinerImage from "../assets/img/berlinerknuppel.jpg";
import durumfrallaImage from "../assets/img/durumfralla.jpg";
import valnotsfrallaImage from "../assets/img/valnotsfralla.jpg";
import miniJoelImage from "../assets/img/mini-joel.jpg";
import durumbrodImage from "../assets/img/durumbrod.jpg";
import snoddasImage from "../assets/img/snoddas.jpg";
import sesamlevainImage from "../assets/img/sesamlevain.jpg";
import sesamlevainGrovImage from "../assets/img/sesamlevaingrov.jpg";
import pabloImage from "../assets/img/pablo.jpg";
import majsansImage from "../assets/img/majsans.jpg";
import nottoppImage from "../assets/img/nottopp.jpg";
import kokostoppImage from "../assets/img/kokostopp.jpg";
import smorbulleImage from "../assets/img/smorbulle.jpg";
import vaniljbulleImage from "../assets/img/vaniljbulle.jpg";
import vaniljgiffelImage from "../assets/img/vaniljgiffel.jpg";
import vaniljcremebulleImage from "../assets/img/vaniljcremebulle.jpg";
import hallongrottorImage from "../assets/img/hallongrottor.jpg";
import ryehoneyImage from "../assets/img/ryehoney.jpg";


const FRALLOR = "Klassiska frallor";
const BREAD = "Bröd";
const FIKA = "Fika";
const LITE_LYX = "Lägg till lite lyx";
const SUR_DEG = "Surdegsfrallor";
const OTHER = "Övrigt";
const FROM_GOTHENBURGS_GINGERBREAD_BAKERY = "Från Göteborgs pepparkaksbageri";
const COFFEE = "Kaffe";
const TEA = "Te";
const GLUTENFRITT = "Bakas utan gluten";

export const CATEGORIES = [
  {
    id: SUR_DEG,
    products: {
      levain: true,
      gourmetknyte: true,
      rusticRagsur: false,
      rusticRagsurRussin: true,
      durumFralla: true,
      rusticKnyte: false,
      vetesur: true,
      valnotsFralla: true,
    },
  },
  {
    id: FRALLOR,
    products: {
      kallarfranska: true,
      ostbrack: true,
      berlinerKnuppel: true,
      vallmobrack: true,
      pumpabrack: false,
      muslibrack: true,
      ragbulle: true,
      miniJoel: false,
      solrosruta: false,
      tekaka: true,
    },
  },
  {
    id: BREAD,
    products: {
      ciabattalevain: false,
      fikonValnot: false,
      paveRustic: false,
      tranbarslevain: false,
      formbakadLevain: false,
      vortbrod: false,
      vortbrodUtanRussin: false,
      surdegsvortbrod: false,
      frobrod: true,
      ryeHoney: true,
      durumBrod: false,
      snoddas: false,
      dansktRagbrod: false,
      sesamLevain: true,
      sesamLevainGrov: false,
      barkis: false,
      brioche: true,
      broverraskning: false,
    },
  },
  {
    id: GLUTENFRITT,
    products: {
      pablo: true,
      majsans: true,
      nottopp: true,
      kokostopp: true,
    },
  },
  {
    id: FIKA,
    products: {
      saffranskatt: false,
      wienerbrod: false,
      wienerchoklad: false,
      wienerrutaHallon: false,
      croissant: true,
      croissantChoklad: true,
      semla: false,
      semlaVanilj: false,
      smorBulle: true,
      vaniljBulle: false,
      vaniljGiffel: false,
      vaniljcremebulle: false,
      kanelbulle: true,
      mummabulle: true,
      sarabulle: true,
      solsnacka: false,
      pistagebulle: false,
      kokoskladdkaka: true,
      karleksmums: false,
      morotsmums: false,
      hallongrottor: false,
      fikabroverraskning: false,
    },
  },
  // {
  //   id: FROM_GOTHENBURGS_GINGERBREAD_BAKERY,
  //   products: {
  //     gingerBread: true,
  //   },
  // },
  // {
  //   id: LITE_LYX,
  //   products: {
  //     tranbar: false,
  //     myntaLajmonad: false,
  //     smoothie: false,
  //     sichuanShot: false,
  //     pomegranateShot: false,
  //     marmeladHallon: false,
  //     marmeladAprikos: false,
  //     marmeladFikon: false,
  //     lyxmusli: false,
  //     granola: false,
  //   },
  // },
  // {
  //   id: COFFEE,
  //   products: {
  //     kaffepaket: false,
  //     kaffekram: false,
  //   }
  // },
  // {
  //   id: TEA,
  //   products: {
  //     teVittmullbar: false,
  //     teSommargott: false,
  //     teBlabarLingon: true,
  //   }
  // },
  {
    id: OTHER,
    products: {
      appleJuice: false,
      lemonShot: true,
      tygpase: true,
    }
  },
];

export const NUTRITIONAL_VALUE_TITELS = {
  energi: "Energi",
  carbs: "Kolhydrater",
  fat: "Fett",
  protein: "Protein",
  salt: "Salt",
  dietaryFiber: "Kostfiber",
  wholeGrains: "Fullkorn",
  suger: "Varav sockerarter",
  saturatedFat: "Varav mättat fett",
  monounsaturatedFat: "Varav enkelomättat fett",
  polyunsaturatedFat: "Varav fleromättat fett",
};





export const PRODUCTS = {
  croissant: {
    id: "croissant",
    title: "Croissant",
    company: "Dahls bageri",
    image: croissantImage,
    price: 19.95,
    category: FIKA,
    weight: 70,
    nutritionValues: {
      energi: "1450 Kj / 350 kcal",
      fat: {
        value: "19 g",
        saturatedFat: "13 g",
        monounsaturatedFat: "5 g",
        polyunsaturatedFat: "1 g",
      },
      carbs: {
        value: "36 g",
        suger: "5.3 g",
      },
      dietaryFiber: "1.9 g",
      protein: "7.3 g",
      salt: "0 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, SMÖR, vatten, socker, jäst,
        VETEGLUTEN, VETESURDEG, ÄGG, salt, dextros, VASSLE (från MJÖLK),
        emulgeringsmedel (E322 (raps), E471, E472e),
        arom, LAKTOS, majsstärkelse, SOJAMJÖL.
        Kan innehålla spår av HASSELNÖTTER, VALNÖTTER & MANDEL.
      </div>
    ),
  },

  croissantChoklad: {
    id: "croissantChoklad",
    title: "Croissant choklad",
    company: "Dahls bageri",
    image: croissantChocolateImage,
    price: 19.95,
    category: FIKA,
    weight: 70,
    nutritionValues: {
      energi: "1450 Kj / 350 kcal",
      fat: {
        value: "19 g",
        saturatedFat: "13 g",
        monounsaturatedFat: "5 g",
        polyunsaturatedFat: "1 g",
      },
      carbs: {
        value: "36 g",
        suger: "5.3 g",
      },
      dietaryFiber: "1.9 g",
      protein: "7.3 g",
      salt: "0 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, SMÖR, vatten, socker, choklad, jäst, VETEGLUTEN, VETESURDEG, ÄGG, salt, dextros, VASSLE (från MJÖLK), emulgeringsmedel (E322 (raps), E471, E472e), arom, LAKTOS, majsstärkelse, SOJAMJÖL. Kan innehålla spår av HASSELNÖTTER, VALNÖTTER & MANDEL.
      </div>
    ),
  },

  tekaka: {
    id: "tekaka",
    title: "Tekaka",
    company: "Dahls bageri",
    image: tekakaImage,
    price: 7.95,
    category: FRALLOR,
    weight: 70,
    nutritionValues: {
      energi: "1150 Kj / 270 kcal",
      fat: {
        value: "5.4 g",
        saturatedFat: "0.5 g",
        monounsaturatedFat: "2.7 g",
        polyunsaturatedFat: "1.3 g",
      },
      carbs: {
        value: "48 g",
        suger: "7.7 g",
      },
      dietaryFiber: "2 g",
      protein: "6.9 g",
      salt: "0.61 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, socker, vegetabilisk olja (raps),
        jäst, salt, fullhärdad rapsolja, VETEGLUTEN, druvsocker.
        Kan innehålla spår av: ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER,
        MANDEL och SESAMFRÖN.
      </div>
    ),
  },
  ciabattalevain: {
    id: "ciabattalevain",
    title: "Ciabatta levain",
    company: "Dahls bageri",
    image: ciabattalevainImage,
    price: 17.95,
    category: BREAD,
    weight: 140,
    nutritionValues: {
      energi: "900 Kj / 210 kcal",
      fat: {
        value: "1.2 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "43 g",
        suger: "1.4 g",
      },
      dietaryFiber: "1.8 g",
      protein: "6.9 g",
      salt: "0.99 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, salt, surdeg (VETE), jäst, emulgeringsmedel (E472 e),
        socker, druvsocker, VETEGLUTEN,
        MALTMJÖL (KORN), psyllium, enzym (bärare VETE),
        vegetabilisk olja (raps), enzym. Kan innehålla spår av: ägg,
        mjölk, hasselnötter, valnötter, mandel, sesamfrön.
      </div>
    ),
  },
  pistagebulle: {
    id: "pistagebulle",
    title: "Pistagebulle",
    company: "Dahls bageri",
    image: pistagebulleImage,
    price: 14.95,
    category: FIKA,
    weight: 85,
    nutritionValues: {
      energi: "1300 Kj / 310 kcal",
      fat: {
        value: "10 g",
        saturatedFat: "1,8 g",
        monounsaturatedFat: "5.3 g",
        polyunsaturatedFat: "2.3 g",
      },
      carbs: {
        value: "47 g",
        suger: "19 g",
      },
      dietaryFiber: "2 g",
      protein: "6.2 g",
      salt: "0.47 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, socker, MANDEL, rapsolja, glukossirap,
        KÄRNMJÖLKSPULVER, modifierad stärkelse, salt, aprikoskärnor,
        potatisgranulat, vegetabilisk olja (raps, kokos), jäst, arom, kardemumma,
        VASSLEPULVER (från MJÖLK), invertsocker, färgämne (E142, E160a, 101),
        SKUMMJÖLKSPULVER, fullhärdad rapsolja, naturlig arom (MANDEL), MJÖLKPROTEINER,
        modifierad stärkelse (E1442), KORNMALT, vaniljarom (MJÖLK), emulgeringsmedel (E471),
        VETEGLUTEN, druvsocker, surhetsreglerande medel (E450, E500),
        LAKTOS. Kan innehålla spår av ÄGG, HASSELNÖTTER, VALNÖTTER och SESAMFRÖN.
      </div>
    ),
  },
  kokoskladdkaka: {
    id: "kokoskladdkaka",
    title: "Kokoskladdkaka 2-pack",
    company: "Dahls bageri",
    image: kokoskladdkakaImage,
    price: 39.95,
    category: FIKA,
    weight: 130,
    nutritionValues: {
      energi: "1750 Kj 410 kcal",
      fat: {
        value: "22 g",
        saturatedFat: "9,7 g",
        monounsaturatedFat: "7.6 g",
        polyunsaturatedFat: "2.7 g",
      },
      carbs: {
        value: "50 g",
        suger: "41 g",
      },
      dietaryFiber: "2.6 g",
      protein: "4.6 g",
      salt: "0.57 g",
    },
    contentDeclaration: (
      <div>
        Socker, VETEMJÖL, vatten, rapsolja, GRÄDDE (MJÖLK),
        kokos, invertsockersirap, ÄGG, kakao, SMÖR, sirap, majsstärkelse, potatisstärkelse,
        ethyvanillin, KORNMALT. Kan innehålla spår av:
        HASSELNÖTTER, VALNÖTTER, MANDEL & SESAMFRÖN.
      </div>
    ),
  },
  surdegsvortbrod: {
    id: "surdegsvortbrod",
    title: "Stenugnsbakad surdegsvört",
    company: "Dahls bageri",
    image: surdegsVortbrodImage,
    price: 69.95,
    category: BREAD,
    weight: 600,
    nutritionValues: {
      energi: "1050 Kj / 250 kcal",
      fat: {
        value: "2.8 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "1 g",
        polyunsaturatedFat: "0.6 g",
      },
      carbs: {
        value: "49 g",
        suger: "6.8 g",
      },
      dietaryFiber: "2.6 g",
      protein: "7.3 g",
      salt: "1 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, russin, bakvört(KORNmalt, vatten), RÅGMJÖL, rapsolja,
        honung, pomeransskal, salt, VETEGLUTEN, jäst, ingefära, kardemumma, kanel,
        kryddnejlika.Kan innehålla spår av ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER,
        MANDEL, SESAMFRÖN.
      </div>
    ),
  },
  julkaffe: {
    id: "julkaffe",
    title: "Julkaffe - malet",
    company: "Kahls Kaffe AB",
    image: julkaffeImage,
    price: 49.95,
    category: COFFEE,
    weight: 250,
    contentDeclaration: (
      <div>
        Malet kaffe på 100% Arabicabönor.
      </div>
    ),
    description: (
      <div>
        Mellanrost - Malet <br />
        <br />
        Certifiering - UTZ <br />
        <br />
        Ett smakrikt och fylligt kaffe som passar utmärkt till julmaten.
        En god klassiker med blandning av bönor från Brasilien,
        Östafrika och Indien.
      </div>
    ),
  },
  kaffekram: {
    id: "kaffekram",
    title: "KRAM - malet",
    company: "Kahls Kaffe AB",
    image: kramkaffeImage,
    price: 59.95,
    category: COFFEE,
    weight: 200,
    contentDeclaration: (
      <div>
        Malet kaffe på 100% Arabicabönor.
      </div>
    ),
    description: (
      <div>
        Mellanrost - Malet <br />
        <br />
        Certifieringar - Fairtrade, KRAV & EU-ekologisk. <br />
        <br />
        Kaffet är 100% Arabica och kommer från Peru eller Honduras beroende på
        säsong. Bönorna är odlade enligt reglerna för ekologisk produktion.
        Smaken är mellanfyllig med god syrlighet och mild eftersmak.
        Passar bäst som bryggkaffe.
      </div>
    ),
  },
  vortbrod: {
    id: "vortbrod",
    title: "Vörtbröd med russin",
    company: "Dahls bageri",
    image: vortbrodImage,
    price: 59.95,
    category: BREAD,
    weight: 700,
    nutritionValues: {
      energi: "1100 Kj / 260 kcal",
      fat: {
        value: "4.2 g",
        saturatedFat: "1.2 g",
        monounsaturatedFat: "1.3 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "48 g",
        suger: "11 g",
      },
      dietaryFiber: "3.6 g",
      protein: "8.1 g",
      salt: "1 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, RÅGMJÖL, sirap, bakvört (KORNmalt, vatten), surdeg av RÅG (5,5%), russin, vegetabilisk olja (palm, raps), VETEGLUTEN, jäst, pomeransskal, salt, ingefära, kardemumma, kanel, kryddnejlika, MJÖLKPROTEINER, surhetsreglerande medel (E450,E500), arom, LAKTOS, vitamin A och D. Kan innehålla spår av ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL, SESAMFRÖN.
      </div>
    ),
  },
  vortbrodUtanRussin: {
    id: "vortbrodUtanRussin",
    title: "Vörtbröd utan russin",
    company: "Dahls bageri",
    image: vortbrodUtanRussinImage,
    price: 59.95,
    category: BREAD,
    weight: 700,
    nutritionValues: {
      energi: "1100 Kj / 260 kcal",
      fat: {
        value: "4.2 g",
        saturatedFat: "1.2 g",
        monounsaturatedFat: "1.3 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "47 g",
        suger: "9.0 g",
      },
      dietaryFiber: "3.6 g",
      protein: "8.4 g",
      salt: "1.1 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, RÅGMJÖL, sirap, bakvört (KORNmalt, vatten),
        surdeg av RÅG (5,5%), vegetabilisk olja (palm, raps), VETEGLUTEN,
        jäst, pomeransskal, salt, ingefära, kardemumma, kanel, kryddnejlika,
        MJÖLKPROTEINER, surhetsreglerande medel (E450,E500),
        arom, LAKTOS, vitamin A och D. Kan innehålla spår av
        ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL, SESAMFRÖN.
      </div>
    ),
  },
  tranbar: {
    id: "tranbar",
    title: "Tranbär",
    company: "Dahls bageri",
    image: tranbarImage,
    price: 39.95,
    category: OTHER,
    weight: 250,
    nutritionValues: {
      energi: "1310 Kj / 311 kcal",
      fat: {
        value: "1.3 g",
        saturatedFat: "0.1 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "72 g",
        suger: "59 g",
      },
      dietaryFiber: "6.1 g",
      protein: "1.3 g",
      salt: "0 g",
    },
    contentDeclaration: (
      <div>Tranbär, äppeljuicekoncentrat och solrosolja.</div>
    ),
  },
  gingerBread: {
    id: "gingerBread",
    title: "Pepparkakor 50st",
    company: "Göteborgs Pepparkaksbageri",
    image: gingerBreadImage,
    price: 149,
    category: FROM_GOTHENBURGS_GINGERBREAD_BAKERY,
    weight: 200,
    contentDeclaration: (
      <div>MJÖL, socker, sirap, GRÄDDE, citronolja & kryddor</div>
    ),
    description: (
      <div>
        Citrusdoftande pepparkakor från Göteborgs pepparkaksbageri. Ett hantverk
        som bakats på samma hemliga recept sedan 1924. Göteborgs
        pepparkaksbageri har vid flertalet tillfällen fått fina utmärkelser -
        bland annat för att vara Sveriges bästa pepparkaksbageri.
      </div>
    ),
  },
  pomegranateShot: {
    id: "pomegranateShot",
    title: "Shot - Granatäpple Ingefära",
    company: "Rscued",
    image: pomegranateShotImage,
    price: 19.95,
    category: LITE_LYX,
    volume: "6 cl",
    nutritionValues: {
      energi: "199 Kj / 47 kcal",
      fat: {
        value: "0 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "11 g",
        suger: "5.7 g",
      },
      dietaryFiber: "0 g",
      protein: "1 g",
      salt: "0 g",
    },
    contentDeclaration: (
      <div>
        Äpple 37,2%, vatten 34%, ingefära 10,2%, rödbeta 6,8%,
        granatäpplejuice*KRAV 6,7%, tranbärsjuice*KRAV 5,1%, chili *Juicer från
        koncentrat
      </div>
    ),
    description: (
      <div>
        Granatäpple/Ingefära är det rubinröda resultatet av mötet mellan
        granatäpple, äpple, ingefära, rödbeta, tranbär och chili. En rivig,
        fruktig och frisk vitaminboost! Hållbart producerad på räddad frukt som
        annars skulle gå till spillo.
      </div>
    ),
  },
  lemonShot: {
    id: "lemonShot",
    title: "Shot - Ingefära Citron",
    company: "Rscued",
    image: lemonShotImage,
    price: 19.95,
    category: LITE_LYX,
    volume: "6 cl",
    nutritionValues: {
      energi: "221 Kj / 53 kcal",
      fat: {
        value: "0 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "12 g",
        suger: "3.5 g",
      },
      dietaryFiber: "0 g",
      protein: "0 g",
      salt: "0 g",
    },
    contentDeclaration: (
      <div>Äpple 30%, citron 30%, ingefära 40%, gurkmeja, svartpeppar</div>
    ),
    description: (
      <div>
        En frisk, rivig och syrlig shot fylld av smak och vitaminer som gör
        skillnad för miljön. Gjord på äpple, citron, ingefära, gurkmeja och
        svartpeppar. Hållbart producerad på räddad frukt som annars skulle gå
        till spillo.
      </div>
    ),
  },
  granola: {
    id: "granola",
    title: "Honungsrostad granola",
    company: "Dahls bageri",
    image: granolaImage,
    price: 69.95,
    category: LITE_LYX,
    weight: 450,
    nutritionValues: {
      energi: "Energi 1400 Kj / 340 kcal",
      fat: {
        value: "23 g",
        saturatedFat: "4.6 g",
        monounsaturatedFat: "9.3 g",
        polyunsaturatedFat: "7.7 g",
      },
      carbs: {
        value: "24 g",
        suger: "18 g",
      },
      dietaryFiber: "8 g",
      protein: "9.2 g",
      salt: "0.07 g",
    },

    contentDeclaration: (
      <div>
        Vatten, solroskärnor, honung, invertsockersirap, linfrö, HASSELNÖTTER,
        MANDEL, HAVREGRYN, kokos, KRUSKAKLI, kanel. Kan innehålla spår av: ÄGG,
        MJÖLK, VETEMJÖL, VALNÖTTER och SESAMFRÖN.
      </div>
    ),
  },
  lyxmusli: {
    id: "lyxmusli",
    title: "Lyxmüsli",
    company: "Dahls bageri",
    image: lyxmusliImage,
    price: 69.95,
    category: LITE_LYX,
    weight: 500,
    nutritionValues: {
      energi: "Energi 2150 Kj / 500 kcal",
      fat: {
        value: "36 g",
        saturatedFat: "4.4 g",
        monounsaturatedFat: "15 g",
        polyunsaturatedFat: "17 g",
      },
      carbs: {
        value: "30 g",
        suger: "13 g",
      },
      dietaryFiber: "9.1 g",
      protein: "17 g",
      salt: "0.02 g",
    },

    contentDeclaration: (
      <div>
        Pumpakärnor, solroskärnor, HAVREGRYN, HASSELNÖTTER, russin, linfrö,
        VALNÖT, ananas, socker, vegetabilisk olja (raps, solros), E220 (SULFIT).
        Kan innehålla spår av: MJÖLK, ÄGG & MANDEL.
      </div>
    ),
  },
  vallmobrack: {
    id: "vallmobrack",
    title: "Vallmobräck",
    company: "Dahls bageri",
    image: vallmobrackImage,
    price: 9.95,
    category: FRALLOR,
    weight: 70,
    nutritionValues: {
      energi: "1000 Kj / 230 kcal",
      fat: {
        value: "2.6 g",
        saturatedFat: "- g",
        monounsaturatedFat: "1.1 g",
        polyunsaturatedFat: "- g",
      },
      carbs: {
        value: "45 g",
        suger: "1 g",
      },
      dietaryFiber: "2.4 g",
      protein: "7.9 g",
      salt: "1.1 g",
    },

    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, vallmofrö, salt, KORNMALTEXTRAKT, jäst, druvsocker,
        VETEGLUTEN, psyllium, enzym (bärare VETE), vegetabilisk olja (raps). Kan
        innehålla spår av ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL &
        SESAMFRÖN.
      </div>
    ),
  },
  solsnacka: {
    id: "solsnacka",
    title: "Solsnäcka",
    company: "Dahls bageri",
    image: solsnackaImage,
    price: 14.95,
    category: FIKA,
    weight: 75,
    nutritionValues: {
      energi: "1250 Kj / 390 kcal",
      fat: {
        value: "7.7 g",
        saturatedFat: "1.8 g",
        monounsaturatedFat: "3.7 g",
        polyunsaturatedFat: "1.8 g",
      },
      carbs: {
        value: "50 g",
        suger: "22 g",
      },
      dietaryFiber: "1.6 g",
      protein: "5.2 g",
      salt: "0.49 g",
    },

    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, socker, rapsolja, glukossirap, KÄRNMJÖLKSPULVER, jäst,
        vegetabilisk olja (raps, kokos), kanel, kardemumma, salt, VASSLEPULVER
        (från MJÖLK), aprikoskärnor, modifierad stärkelse, SKUMMJÖLKSPULVER,
        majsmjöl, färgämne (E101, E160a), fullhärdad rapsolja, emulgeringsmedel
        (E471), MJÖLKPROTEINER, modifierad stärkelse (E1442), KORNMALT,
        vaniljarom (MJÖLK), MANDEL, VETEGLUTEN, druvsocker, aromämne (vanillin),
        surhetsreglerande medel (E450, 500), invertsocker, LAKTOS. Kan innehålla
        spår av ÄGG, HASSELNÖTTER, VALNÖTTER, och SESAMFRÖN.
      </div>
    ),
  },
  gourmetknyte: {
    id: "gourmetknyte",
    title: "Gourmet",
    company: "Dahls bageri",
    image: gourmetknyteImage,
    price: 11.95,
    category: FRALLOR,
    weight: 75,
    nutritionValues: {
      energi: "900 kj / 210 kcal",
      fat: {
        value: "1.6 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "41 g",
        suger: "2.7 g",
      },
      dietaryFiber: "1.6 g",
      protein: "7.4 g",
      salt: "1.1 g",
    },

    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, aprikos, HAVREGRYN, linfrö gula, RÅGMJÖL, KORNFLINGOR,
        KROSSAD RÅGMALT, MÄLTADE VETEFLINGOR, salt, surdeg av DURUMVETEMJÖL,
        jäst, VETEGLUTEN, KORNMALT, Konerveringsmedel E220 (SULFIT), rismjöl.
        Kan innehålla spår av: ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL &
        SESAMFRÖN.
      </div>
    ),
  },
  levain: {
    id: "levain",
    title: "Levain",
    company: "Dahls bageri",
    image: levainImage,
    price: 11.95,
    category: FRALLOR,
    weight: 75,
    nutritionValues: {
      energi: "900 kj / 210 kcal",
      fat: {
        value: "1.2 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "43 g",
        suger: "1.4 g",
      },
      dietaryFiber: "1.8 g",
      protein: "6.9 g",
      salt: "0.99 g",
    },

    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, salt, surdeg (VETE), jäst, socker, VETEGLUTEN,
        MALTMJÖL (KORN), psyllium, raps. Kan innehålla spår av; ÄGG, MJÖLK,
        HASSELNÖTTER, VALNÖTTER, MANDEL & SESAMFRÖN.
      </div>
    ),
  },
  solrosruta: {
    id: "solrosruta",
    title: "Solrosruta",
    company: "Dahls bageri",
    image: solrosrutaImage,
    price: 7.95,
    category: FRALLOR,
    weight: 70,
    nutritionValues: {
      energi: "1050 kj / 250 kcal",
      fat: {
        value: "4.9 g",
        saturatedFat: "0.6 g",
        monounsaturatedFat: "1.3 g",
        polyunsaturatedFat: "2.1 g",
      },
      carbs: {
        value: "42 g",
        suger: "4.8 g",
      },
      dietaryFiber: "4.1 g",
      protein: "8.4 g",
      salt: "0.81 g",
    },

    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, klippt RÅG, solroskärnor (4%), sirap, jäst,
        VETEGLUTEN, salt, surhetsreglerande medel E270, enzym (bärare VETE). Kan
        innehålla spår av ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER & MANDEL.
      </div>
    ),
  },

  kallarfranska: {
    id: "kallarfranska",
    title: "Källarfranska",
    company: "Dahls bageri",
    image: kallarfranskaImage,
    price: 7.95,
    category: FRALLOR,
    weight: 70,
    nutritionValues: {
      energi: "1050 kj / 250 kcal",
      fat: {
        value: "3.8 g",
        saturatedFat: "- g",
        monounsaturatedFat: "1.6 g",
        polyunsaturatedFat: "1.8 g",
      },
      carbs: {
        value: "45 g",
        suger: "1.9 g",
      },
      dietaryFiber: "2.3 g",
      protein: "7.6 g",
      salt: "1.3 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, RÅGMJÖL, vegetabilisk olja (raps), salt, socker,
        VETEGLUTEN, jäst, fullhärdad rapsolja, druvsocker. Kan innehålla spår
        av: ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  ostbrack: {
    id: "ostbrack",
    title: "Ostbräck",
    company: "Dahls bageri",
    image: ostbrackImage,
    price: 9.95,
    category: FRALLOR,
    weight: 75,
    nutritionValues: {
      energi: "1100 kj / 260 kcal",
      fat: {
        value: "6.4 g",
        saturatedFat: "2.3 g",
        monounsaturatedFat: "2.1 g",
        polyunsaturatedFat: "0.8 g",
      },
      carbs: {
        value: "40 g",
        suger: "1.7 g",
      },
      dietaryFiber: "2.1 g",
      protein: "9.5 g",
      salt: "1.3 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, OST, RÅGMJÖL, vegetabilisk olja (raps), salt, socker,
        VETEGLUTEN, jäst, fullhärdad rapsolja, druvsocker, potatissärkelse. Kan
        innehålla spår av: ÄGG, HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  muslibrack: {
    id: "muslibrack",
    title: "Müslibräck",
    company: "Dahls bageri",
    image: muslibrackImage,
    price: 9.95,
    category: FRALLOR,
    weight: 75,
    nutritionValues: {
      energi: "1000 kj / 240 kcal",
      fat: {
        value: "3.2 g",
        saturatedFat: "- g",
        monounsaturatedFat: "- g",
        polyunsaturatedFat: "0.9 g",
      },
      carbs: {
        value: "41 g",
        suger: "5.8 g",
      },
      dietaryFiber: "4.2 g",
      protein: "8.6 g",
      salt: "1.1 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, russin, VETEGLUTEN, VETEKLI, HAVREGRYN, MÄLTADE
        VETEFLINGOR, surdeg av RÅG, VETEFLINGOR, VETESTÄRKELSE, socker, jäst,
        linfrö, MALTMJÖL (KORN), vegetabilisk olja (raps), salt, solroskärnor,
        torkad ananas, torkad papaya, antioxidationsmedel (SULFIT), solrosolja,
        torkade äpplen, druvsocker. (Innehåller 6% torkad Frukt). Kan innehålla
        spår av: ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  ragbulle: {
    id: "ragbulle",
    title: "Rågbulle",
    company: "Dahls bageri",
    image: ragbulleImage,
    price: 9.95,
    category: FRALLOR,
    weight: 70,
    nutritionValues: {
      energi: "1050 kj / 250 kcal",
      fat: {
        value: "4.1 g",
        saturatedFat: "- g",
        monounsaturatedFat: "1.8 g",
        polyunsaturatedFat: "0.9 g",
      },
      carbs: {
        value: "44 g",
        suger: "5.8 g",
      },
      dietaryFiber: "4.3 g",
      protein: "7.7 g",
      salt: "1.1 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, fullkornsmjöl av RÅG, sirap, surdeg av RÅG,
        vegetabilisk olja (raps), KORNMALTEXTRAKT, VETEGLUTEN, salt, jäst,
        RÅGMJÖL, fullhärdad rapsolja, druvsocker. Kan innehålla spår av: ÄGG,
        MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  frobrod: {
    id: "frobrod",
    title: "Fröbröd",
    company: "Dahls bageri",
    image: frobrodImage,
    price: 39.95,
    category: BREAD,
    weight: 420,
    nutritionValues: {
      energi: "1350 kj / 320 kcal",
      fat: {
        value: "20 g",
        saturatedFat: "2.2 g",
        monounsaturatedFat: "- g",
        polyunsaturatedFat: "- g",
      },
      carbs: {
        value: "16 g",
        suger: "1.1 g",
      },
      dietaryFiber: "8 g",
      protein: "15 g",
      salt: "1.2 g",
    },
    contentDeclaration: (
      <div>
        Vatten, solroskärnor, fullkornsmjöl av RÅG (19%), linfrö, surdeg av RÅG
        (6%), VETEGLUTEN, kallpressad rapsolja, pumpakärnor, MALTMJÖL (KORN),
        salt, jäst, psyllium. Kan innehålla spår av: ÄGG, MJÖLK, HASSELNÖTTER,
        VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  kanelbulle: {
    id: "kanelbulle",
    title: "Kanelbulle",
    company: "Dahls bageri",
    image: kanelbulleImage,
    price: 14.95,
    category: FIKA,
    weight: 90,
    nutritionValues: {
      energi: "1350 kj / 320 kcal",
      fat: {
        value: "8 g",
        saturatedFat: "1 g",
        monounsaturatedFat: "4.3 g",
        polyunsaturatedFat: "2.1 g",
      },
      carbs: {
        value: "56 g",
        suger: "24 g",
      },
      dietaryFiber: "1.9 g",
      protein: "5.4 g",
      salt: "0.56 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, socker, rapsolja, glukossirap, vegetabilisk olja
        (raps), kanel, jäst, kardemumma, salt, VASSLEPULVER (från MJÖLK),
        aprikoskärnor, modifierad stärkelse, majsmjöl, SKUMMJÖLKSPULVER,
        emulgeringsmedel (E471), fullhärdad rapsolja, MJÖLKPROTEINER, KORNMALT,
        MANDEL, VETEGLUTEN, druvsocker, aromämne (vanillin), surhetsreglerande
        medel (E450, E500), invertsocker, LAKTOS. Kan innehålla spår av ÄGG,
        HASSELNÖTTER, VALNÖTTER, och SESAMFRÖN.
      </div>
    ),
  },

  mummabulle: {
    id: "mummabulle",
    title: "Mummabulle",
    company: "Dahls bageri",
    image: mummabulleImage,
    price: 14.95,
    category: FIKA,
    weight: 85,
    nutritionValues: {
      energi: "1300 kj / 320 kcal",
      fat: {
        value: "8.8 g",
        saturatedFat: "1 g",
        monounsaturatedFat: "5.1 g",
        polyunsaturatedFat: "2.1 g",
      },
      carbs: {
        value: "53 g",
        suger: "24 g",
      },
      dietaryFiber: "1.5 g",
      protein: "5.5 g",
      salt: "0.52 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, socker, rapsolja, aprikoskärnor, vegetabilisk olja
        (raps), modifierad stärkelse, kardemumma, jäst, VASSLEPULVER (från
        MJÖLK), salt, glukossirap, SKUMMJÖLKSPULVER, emulgeringsmedel (E471),
        fullhärdad rapsolja, MJÖLKPROTEINER, KORNMALT, arom, VETEGLUTEN,
        druvsocker, surhetsreglerande medel (E450, E500), LAKTOS. Kan innehålla
        spår av ÄGG, HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  sarabulle: {
    id: "sarabulle",
    title: "Sarabulle",
    company: "Dahls bageri",
    image: sarabulleImage,
    price: 14.95,
    category: FIKA,
    weight: 85,
    nutritionValues: {
      energi: "1400 kj / 340 kcal",
      fat: {
        value: "12 g",
        saturatedFat: "2.1 g",
        monounsaturatedFat: "- g",
        polyunsaturatedFat: "- g",
      },
      carbs: {
        value: "53 g",
        suger: "24 g",
      },
      dietaryFiber: "1.3 g",
      protein: "5.9 g",
      salt: "0.51 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, socker, vatten, rapsolja, ÄGG, aprikoskärnor, vegetabilisk olja (raps,kokos), jäst, modifierad stärkelse, salt, VASSLEPULVER (från MJÖLK), emulgeringsmedel (E471), glukossirap, fullhärdad rapsolja, bakpulver (E450, E500), SKUMMJÖLKSPULVER, färgämne (E160a, E120), MJÖLKPROTEINER, KORNMALT, arom, druvsocker, VETEGLUTEN, aromämne (vanillin), LAKTOS, majsstärkelse. Kan innehålla spår av HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },


  saffranskatt: {
    id: "saffranskatt",
    title: "Saffranskatt",
    company: "Dahls bageri",
    image: saffranskattImage,
    price: 19.95,
    category: FIKA,
    weight: 70,
    nutritionValues: {
      energi: "1250 kj / 300 kcal",
      fat: {
        value: "7.9 g",
        saturatedFat: "2,5 g",
        monounsaturatedFat: "3.2 g",
        polyunsaturatedFat: "1.3 g",
      },
      carbs: {
        value: "50 g",
        suger: "13 g",
      },
      dietaryFiber: "1.9 g",
      protein: "6.6 g",
      salt: "0.69 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, socker, vegetabilisk olja (raps), SMÖR, VASSLEPULVER
        (från MJÖLK), jäst, salt, SKUMMJÖLKSPULVER, MJÖLKPROTEINER, saffran,
        arom, VETEGLUTEN, druvsocker, surhetsreglerande medel (E450, E500),
        LAKTOS. Kan innehålla spår av ÄGG, HASSELNÖTTER, VALNÖTTER, MANDEL och
        SESAMFRÖN.
      </div>
    ),
  },

  semla: {
    id: "semla",
    title: "Semla 2-pack",
    company: "Dahls bageri",
    image: semlaImage,
    price: 69.95,
    category: FIKA,
    weight: 250,
    nutritionValues: {
      energi: "1400 kj / 340 kcal",
      fat: {
        value: "19 g",
        saturatedFat: "9.3 g",
        monounsaturatedFat: "3.7 g",
        polyunsaturatedFat: "1.4 g",
      },
      carbs: {
        value: "33 g",
        suger: "15 g",
      },
      dietaryFiber: "2.3 g",
      protein: "7.1 g",
      salt: "0.42 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, GRÄDDE, socker, vatten, MANDEL, vegetabilisk olja (palm, raps), jäst, glukos, SMÖR, modifierad potatisstärkelse, MJÖLKPROTEINER, förtjockningsmedel (E440, E401), salt, VASSLEPULVER (från MJÖLK), kardemumma, arom, konserveringsmedel (E202), stabiliseringsmedel (karragenan), SKUMMJÖLKSPULVER, majsstärkelse, surhetsreglerande medel (E450, E500), VETEGLUTEN, druvsocker, LAKTOS. Kan innehålla spår av: ägg, hasselnötter, valnötter och sesamfrön. Kan innehålla spåra av: ÄGG, HASSELNÖTTER och VALNÖTTER.
      </div>
    ),
  },

  semlaVanilj: {
    id: "semlaVanilj",
    title: "Vaniljsemla 2-pack",
    company: "Dahls bageri",
    image: semlaImage,
    price: 69.95,
    category: FIKA,
    weight: 250,
    nutritionValues: {
      energi: "1150 kj / 280 kcal",
      fat: {
        value: "15 g",
        saturatedFat: "9.7 g",
        monounsaturatedFat: "1.8 g",
        polyunsaturatedFat: "0.8 g",
      },
      carbs: {
        value: "30 g",
        suger: "9 g",
      },
      dietaryFiber: "1 g",
      protein: "4.8 g",
      salt: "0.28 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, GRÄDDE, vatten, socker, vegetabilisk olja (palm, raps, kokos), KÄRNMJÖLKSPULVER, jäst, SMÖR, VASSLEPULVER (från MJÖLK), salt, kardemumma, MJÖLKPROTEINER, färgämne (E101, E160a), stabiliseringsmedel (karragenan), SKUMMJÖLKSPULVER, modifierad stärkelse E1442, vaniljarom (MJÖLK), majsstärkelse, arom, surhetsreglerande medel (E450, E500), VETEGLUTEN, druvsocker, konserveringsmedel (E202), LAKTOS. Kan innehålla spår av: ägg, hasselnötter, valnötter, mandel och sesamfrön.
      </div>
    ),
  },

  karleksmums: {
    id: "karleksmums",
    title: "Kärleksmums 2-pack",
    company: "Dahls bageri",
    image: karleksmumsImage,
    price: 39.95,
    category: FIKA,
    weight: 200,
    nutritionValues: {
      energi: "2900 kj / 700 kcal",
      fat: {
        value: "68 g",
        saturatedFat: "5.7 g",
        monounsaturatedFat: "40 g",
        polyunsaturatedFat: "21 g",
      },
      carbs: {
        value: "21 g",
        suger: "13 g",
      },
      dietaryFiber: "0.7 g",
      protein: "1.8 g",
      salt: "0.11 g",
    },
    contentDeclaration: (
      <div>
        Rapsolja, socker, vatten, VETEMJÖL, sirap, potatisstärkelse, HELÄGGS-PULVER, kakao, kokos, kakaopulver, glukossirap, vegetabilisk olja (kokos, palm, raps), bakpulver (E450, E500, VETE), VETESTÄRKELSE, kaffe, härdad vegetabilisk olja (kokos), salt, kakaomassa, arom, emulgeringsmedel (E471,E472 e), kakaosmör, arom (vanilj), emulgeringsmedel SOJALECITIN, enzym (bärare VETE), naturlig arom (vanilj), vitamin A och D. Kan innehålla spår av MJÖLK, HASSELNÖTTER, VALNÖTTER och MANDEL.
      </div>
    ),
  },

  marmeladFikon: {
    id: "marmeladFikon",
    title: "Marmelad - Fikon",
    company: "Kahls Kaffe AB",
    image: marmeladFikonImage,
    price: 14.95,
    category: LITE_LYX,
    weight: 42,
    nutritionValues: {
      energi: "1153 kj / 274 kcal",
      fat: {
        value: "0 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "66 g",
        suger: "66 g",
      },
      dietaryFiber: "0 g",
      protein: "0.6 g",
      salt: "0 g",
    },
    contentDeclaration: (
      <div>
        Socker, Fikon, Surhetsreglerande medel: Citronsyra. Fruktmängd 49g per 100g. Total mängd socker: 67g per 100g.
      </div>
    ),
  },

  marmeladHallon: {
    id: "marmeladHallon",
    title: "Marmelad - Hallon",
    company: "Kahls Kaffe AB",
    image: marmeladHallonImage,
    price: 14.95,
    category: LITE_LYX,
    weight: 42,
    nutritionValues: {
      energi: "1140 kj / 268 kcal",
      fat: {
        value: "0 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "66 g",
        suger: "66 g",
      },
      dietaryFiber: "0 g",
      protein: "0.7 g",
      salt: "0.02 g",
    },
    contentDeclaration: (
      <div>
        Socker, Hallon, Förtjockningsmedel: Citronpektin, Surhetsreglerande medel: Natriumcitrat. Fruktmängd 52g per 100g. Total mängd socker: 67g per 100g.
      </div>
    ),
  },

  marmeladAprikos: {
    id: "marmeladAprikos",
    title: "Marmelad - Aprikos",
    company: "Kahls Kaffe AB",
    image: marmeladAprikosImage,
    price: 14.95,
    category: LITE_LYX,
    weight: 42,
    nutritionValues: {
      energi: "1155 kj / 272 kcal",
      fat: {
        value: "0 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "66 g",
        suger: "66 g",
      },
      dietaryFiber: "0 g",
      protein: "0.6 g",
      salt: "0 g",
    },
    contentDeclaration: (
      <div>
        Aprikos, Socker, Surhetsreglerande medel: Citronsyra. Fruktmängd 70g per 100g. Total mängd socker: 67g per 100g.
      </div>
    ),
  },

  sichuanShot: {
    id: "sichuanShot",
    title: "Shot - Citron Sichuan",
    company: "Rscued",
    image: sichuanShotImage,
    price: 19.95,
    category: LITE_LYX,
    volume: "6 cl",
    nutritionValues: {
      energi: "169 Kj / 41 kcal",
      fat: {
        value: "0.1 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "8.8 g",
        suger: "6.8 g",
      },
      dietaryFiber: "0 g",
      protein: "0.4 g",
      salt: "0 g",
    },
    contentDeclaration: (
      <div>
        Äpple 49%, citron 27%, ingefära 14%, rödbeta 10%,
        sichuanpeppar, chili
      </div>
    ),
    description: (
      <div>
        Citron/Sichuanpeppar är en rivig och frisk vitaminboost! Hållbart producerad på räddad frukt som
        annars skulle gå till spillo.
      </div>
    ),
  },

  brioche: {
    id: "brioche",
    title: "Burgerbrioche 4-pack",
    company: "Dahls bageri",
    image: briocheImage,
    price: 39.95,
    category: BREAD,
    weight: 280,
    nutritionValues: {
      energi: "1250 Kj / 300 kcal",
      fat: {
        value: "8.1 g",
        saturatedFat: "2.9 g",
        monounsaturatedFat: "2.9 g",
        polyunsaturatedFat: "1.1 g",
      },
      carbs: {
        value: "50 g",
        suger: "6.5 g",
      },
      dietaryFiber: "2.1 g",
      protein: "8 g",
      salt: "0.57 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, surdeg av VETE, socker, ÄGG, SMÖR, rapsolja, jäst, VASSLEPULVER (från mjölk), salt, SKUMMJÖLKSPULVER, VETEGLUTEN, färgämne (E120, E160), fullhärad rapsolja, KORNMALT. VETEGLUTEN, emilgeringsmedel (e471), MJÖLKPROTEINER, surhetsreglerande medel (e450, e500), LAKTOS. Kan innehålla spår av HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  wienerbrod: {
    id: "wienerbrod",
    title: "Wienerbröd",
    company: "Dahls bageri",
    image: wienerImage,
    price: 19.95,
    category: FIKA,
    weight: 75,
    nutritionValues: {
      energi: "1400 Kj / 340 kcal",
      fat: {
        value: "22 g",
        saturatedFat: "10 g",
        monounsaturatedFat: "8.3 g",
        polyunsaturatedFat: "2.7 g",
      },
      carbs: {
        value: "30 g",
        suger: "9.9 g",
      },
      dietaryFiber: "0.9 g",
      protein: "4.3 g",
      salt: "0.56 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, vegetabilisk olja (palm, raps, palmkärna, shea), socker, ÄGGVITA, KÄRNMJÖLKSPULVER, glukossirap, jäst, SMÖR, salt, arom, modifierad stärkelse E1442, emulgeringsmedel (E471, E322 (SOJA), E472e), färgämne (E101, E160a), antioxidationsmedel (E304, E306), druvsocker, naturlig arom, konserveringsmedel (E202), stabiliseringsmedel (E412), vitamin A och D. Kan innehålla spår av: HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  wienerchoklad: {
    id: "wienerchoklad",
    title: "Wienerbröd Choklad",
    company: "Dahls bageri",
    image: wienerChokladImage,
    price: 19.95,
    category: FIKA,
    weight: 75,
    nutritionValues: {
      energi: "1400 Kj / 330 kcal",
      fat: {
        value: "20 g",
        saturatedFat: "9.7 g",
        monounsaturatedFat: "7.2 g",
        polyunsaturatedFat: "2.4 g",
      },
      carbs: {
        value: "34 g",
        suger: "16 g",
      },
      dietaryFiber: "1.6 g",
      protein: "4 g",
      salt: "0.46 g",
    },
    contentDeclaration: (
      <div>
        Vatten, VETEMJÖL, socker, vegetabilisk olja (palm, raps, palmkärna, shea), ÄGGVITA, kakaomassa, glukossirap, KÄRNMJÖLKSPULVER, kakaosmör, VETESTÄRKELSE, jäst, aprikoskärnor, SMÖR, salt, modifierad stärkelse E1442, potatisfiber, arom, färgämne (E101, E160a), emulgeringsmedel (E471, E322 (SOJA), E472e), druvsocker, naturlig arom, invertsocker, konserveringsmedel (E202), naturlig arom (vanilj), potatisstärkelse, stabiliseringsmedel (E412), vitamin A och D. Kan innehålla spår av: HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  fikonValnot: {
    id: "fikonValnot",
    title: "Fikon & Valnöt",
    company: "Dahls bageri",
    image: fikonValnotImage,
    price: 69.95,
    category: BREAD,
    weight: 600,
    nutritionValues: {
      energi: "1000 Kj / 230 kcal",
      fat: {
        value: "4.3 g",
        saturatedFat: "",
        monounsaturatedFat: "0.5 g",
        polyunsaturatedFat: "2.6 g",
      },
      carbs: {
        value: "41 g",
        suger: "3.6 g",
      },
      dietaryFiber: "3.7 g",
      protein: "7 g",
      salt: "1.2 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, surdeg av VETE (9%), fullkornsmjöl av RÅG, VALNÖTTER (4%), fikon (2%), russin, salt. Kan innehålla spår av ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL & SESAMFRÖN.
      </div>
    ),
  },


  barkis: {
    id: "barkis",
    title: "Barkis",
    company: "Dahls bageri",
    image: barkisImage,
    price: 39.95,
    category: BREAD,
    weight: 550,
    nutritionValues: {
      energi: "950 Kj / 230 kcal",
      fat: {
        value: "2.4 g",
        saturatedFat: "",
        monounsaturatedFat: "0.8 g",
        polyunsaturatedFat: "",
      },
      carbs: {
        value: "44 g",
        suger: "1.6 g",
      },
      dietaryFiber: "2.1 g",
      protein: "7.7 g",
      salt: "1.2 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, jäst, salt, KORNMALTEXTRAKT, vegetabilisk olja (raps), vallmofrö, VETEGLUTEN, fullhärdad rapsolja, druvsocker. Kan innehålla spår av: ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  tygpase: {
    id: "tygpase",
    title: "Tygpåse",
    image: BagImage,
    price: 29.95,
    category: OTHER,
    description: (
      <div>
        <p>
          Vill du ha din frukost levererad på ett praktiskt sätt?
          Klicka hem en tygpåse eller häng ut en egen så levereras din brödpåse i den på lördag morgon.
        </p>
        <br></br>
        <p>I varukorgen väljer du själv leveranssätt: "I påse på handtaget"</p>
      </div>
    ),
  },

  morotsmums: {
    id: "morotsmums",
    title: "Morotsmums 2-pack",
    company: "Dahls bageri",
    image: morotsmumsImage,
    price: 39.95,
    category: FIKA,
    weight: 200,
    nutritionValues: {
      energi: "2600 Kj / 650 kcal",
      fat: {
        value: "56 g",
        saturatedFat: "4.9 g",
        monounsaturatedFat: "33 g",
        polyunsaturatedFat: "17 g",
      },
      carbs: {
        value: "32 g",
        suger: "26 g",
      },
      dietaryFiber: "0 g",
      protein: "1.5 g",
      salt: "0.11 g",
    },
    contentDeclaration: (
      <div>
        Rapsolja, socker, vatten, VETEMJÖL, MJÖLK, morötter, sirap, GRÄDDE, HELÄGGSPULVER, potatisstärkelse, vegetabilisk olja (kokos, palm, raps), VETE- STÄRKELSE, kanel, härdad vegetabilisk olja (kokos), bakpulver (E450), druvsocker, salt, bikarbonat E500, färgämne (E160 a), arom, MJÖLKPROTEINER, bakteriekultur, emulgeringsmedel (E471), naturlig arom, vitamin A och D. Kan innehålla spår av HASSELNÖTTER, VALNÖTTER & MANDEL.
      </div>
    ),
  },

  broverraskning: {
    id: "broverraskning",
    title: "Bröverraskning",
    image: broverraskningImage,
    price: 39.95,
    category: BREAD,
    description: (
      <div>
        <p>
          Det blir va det blir änna!
        </p>
        <br></br>
        <p>
          Vår bröverraskning är en populär göteborgsk roulette som passar dig som är nyfiken eller som gillar lite extra spänning i vardagen. Bröverraskningens innehåll får du först veta när din leverans anländer på lördag morgon men förvänta dig produkter både i och utanför vårt sortiment.
        </p>
        <br></br>
        <p><b>Bröverraskningens innehåll:</b></p>
        <p>
          4 frallor eller 1 limpa - slumpen avgör!
        </p>
        <br></br>
        <p>
          Eftersom produkterna i vår bröverraskning kommer variera ber vi dig att höra av dig till oss om du har några frågor kring innehållet. Kontaktuppgifter hittar du på hemsidan.
        </p>
      </div>
    ),
  },

  fikabroverraskning: {
    id: "fikabroverraskning",
    title: "Fikabröverraskning",
    image: fikabroverraskningImage,
    price: 39.95,
    category: FIKA,
    description: (
      <div>
        <p>
          Det blir va det blir änna!
        </p>
        <br></br>
        <p>
          Vår fikabröverraskning är en populär göteborgsk roulette som passar dig som är nyfiken eller som gillar lite extra spänning i vardagen. Fikabröverraskningens innehåll får du först veta när din leverans anländer på lördag morgon men förvänta dig produkter både i och utanför vårt sortiment.
        </p>
        <br></br>
        <p><b>Fikabröverraskningens innehåll:</b></p>
        <p>
          3 bullar eller 1 längd eller 1 kondisbit - slumpen avgör!
        </p>
        <br></br>
        <p>
          Eftersom produkterna i vår fikabröverraskning kommer variera ber vi dig att höra av dig till oss om du har några frågor kring innehållet. Kontaktuppgifter hittar du på hemsidan.
        </p>
      </div>
    ),
  },


  wienerrutaHallon: {
    id: "wienerrutaHallon",
    title: "Wienerruta Hallon",
    company: "Dahls bageri",
    image: wienerHallonImage,
    price: 19.95,
    category: FIKA,
    weight: 70,
    nutritionValues: {
      energi: "1550 Kj / 370 kcal",
      fat: {
        value: "22 g",
        saturatedFat: "7.8 g",
        monounsaturatedFat: "9.6 g",
        polyunsaturatedFat: "3.2 g",
      },
      carbs: {
        value: "39 g",
        suger: "22 g",
      },
      dietaryFiber: "1.2 g",
      protein: "4.9 g",
      salt: "0.49 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vegetabilisk olja (palm, raps), vatten, socker, MANDEL, hallon, ÄGGVITA, jäst, hallonkoncentrat, SMÖR, salt, arom, emulgeringsmedel (E471, E322 (SOJA), E472e), druvsocker, naturlig arom, vitamin A och D, konserveringsmedel (E202), stabiliseringsmedel (E412). Kan innehålla spår av HASSELNÖTTER, VALNÖTTER och SESAMFRÖN.
      </div>
    ),
  },

  dansktRagbrod: {
    id: "dansktRagbrod",
    title: "Osötat danskt rågbröd",
    company: "Dahls bageri",
    image: dansktRagbrodImage,
    price: 49.95,
    category: BREAD,
    weight: 600,
    nutritionValues: {
      energi: "1050 Kj / 240 kcal",
      fat: {
        value: "6.4 g",
        saturatedFat: "0.8 g",
        monounsaturatedFat: "1.4 g",
        polyunsaturatedFat: "3.2 g",
      },
      carbs: {
        value: "35 g",
        suger: "2.3 g",
      },
      dietaryFiber: "8.7 g",
      protein: "8.1 g",
      salt: "0.89 g",
    },
    contentDeclaration: (
      <div>
        Vatten, surdeg av RÅG, klippta RÅGKÄRNOR, fullkornsmjöl av RÅG (34%), VETEMJÖL, linfrö, pumpakärnor, solroskärnor, KORNMALT, salt, jäst. Kan innehålla spår av: ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  pumpabrack: {
    id: "pumpabrack",
    title: "Pumpabräck",
    company: "Dahls bageri",
    image: pumpabrackImage,
    price: 10.95,
    category: FRALLOR,
    weight: 70,
    nutritionValues: {
      energi: "1200 Kj / 290 kcal",
      fat: {
        value: "10 g",
        saturatedFat: "1.9 g",
        monounsaturatedFat: "3.3 g",
        polyunsaturatedFat: "4.4 g",
      },
      carbs: {
        value: "35 g",
        suger: "2.2 g",
      },
      dietaryFiber: "4.2 g",
      protein: "13 g",
      salt: "0.88 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, pumpakärnor, RÅGMJÖL, linfrö, solroskärnor, sirap, jäst, quinoafrö, salt, VETEGLUTEN, VETESURDEG, socker, emulgeringsmedel (E472e), druvsocker. Kan innehålla spår av: ägg, mjölk, hasselnötter, valnötter, mandel, sesamfrön.
      </div>
    ),
  },

  rusticKnyte: {
    id: "rusticKnyte",
    title: "Rustic",
    company: "Dahls bageri",
    image: rusticFrallaImage,
    price: 11.95,
    category: FRALLOR,
    weight: 70,
    nutritionValues: {
      energi: "950 Kj / 230 kcal",
      fat: {
        value: "3.9 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "1.5 g",
        polyunsaturatedFat: "1 g",
      },
      carbs: {
        value: "42 g",
        suger: "2 g",
      },
      dietaryFiber: "2 g",
      protein: "6.9 g",
      salt: "1.1 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, raffinerad rapsolja, solroskärnor, KORNMALT, jäst, salt, MÄLTAT VETE, surdegspulver av VETE, druvsocker, socker, enzym (bärare VETE), emulgeringsmedel (E472e). Kan innehålla spår av: ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL & SESAMFRÖN.
      </div>
    ),
  },

  paveRustic: {
    id: "paveRustic",
    title: "Pave rustic",
    company: "Dahls bageri",
    image: paveRusticImage,
    price: 59.95,
    category: BREAD,
    weight: 700,
    nutritionValues: {
      energi: "1050 Kj / 250 kcal",
      fat: {
        value: "6.2 g",
        saturatedFat: "0.8 g",
        monounsaturatedFat: "1.7 g",
        polyunsaturatedFat: "3 g",
      },
      carbs: {
        value: "38 g",
        suger: "1.9 g",
      },
      dietaryFiber: "4.6 g",
      protein: "8.8 g",
      salt: "0.97 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, surdeg av VETE (8%), fullkornsmjöl av RÅG, fullkornsmjöl av VETE, linfrö, pumpakärnor, solroskärnor, farinsocker, salt. Kan innehålla spår av ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL & SESAMFRÖN.
      </div>
    ),
  },

  formbakadLevain: {
    id: "formbakadLevain",
    title: "Formbakad levain",
    company: "Dahls bageri",
    image: formbakadLevainImage,
    price: 49.95,
    category: BREAD,
    weight: 600,
    nutritionValues: {
      energi: "850 Kj / 200 kcal",
      fat: {
        value: "0.9 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "39 g",
        suger: "0.7 g",
      },
      dietaryFiber: "2 g",
      protein: "6.8 g",
      salt: "1.4 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, jäst, salt, surdeg (av VETE), MÄLTAT VETEMJÖL, RÅGMJÖL, VETEGLUTEN. Kan innehålla spår av: ÄGG, MJÖLK, HASSELNÖTTER, VALNÖTTER, MANDEL och SESAMFRÖN.
      </div>
    ),
  },

  tranbarslevain: {
    id: "tranbarslevain",
    title: "Tranbärslevain",
    company: "Dahls bageri",
    image: transbarSurdegImage,
    price: 59.95,
    category: BREAD,
    weight: 600,
    nutritionValues: {
      energi: "1100 Kj / 260 kcal",
      fat: {
        value: "1.2 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "54 g",
        suger: "2.8 g",
      },
      dietaryFiber: "2.8 g",
      protein: "8.7 g",
      salt: "1.5 g",
    },
    contentDeclaration: (
      <div>
        VETEmjöl, maltmjöl av KORN, vatten, tranbär, salt, socker, jäst, solrosolja. Kan innehåll spår av: ägg, mjölk, hasselnötter, valnötter, mandel och sesamfrön.
      </div>
    ),
  },

  appleJuice: {
    id: "appleJuice",
    title: "Äppeljuice",
    company: "Rscued",
    image: appleJuiceImage,
    price: 34.95,
    category: LITE_LYX,
    volume: "27 cl",
    nutritionValues: {
      energi: "200 Kj / 48 kcal",
      fat: {
        value: "0 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "11 g",
        suger: "11 g",
      },
      dietaryFiber: "0 g",
      protein: "0 g",
      salt: "0 g",
    },
    contentDeclaration: (
      <div>
        Äpple 100%
      </div>
    ),
  },

  myntaLajmonad: {
    id: "myntaLajmonad",
    title: "Mynta/lajmonad",
    company: "Rscued",
    image: myntaLajmonadImage,
    price: 37.95,
    category: LITE_LYX,
    volume: "27 cl",
    nutritionValues: {
      energi: "128 Kj / 31 kcal",
      fat: {
        value: "0 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "7.5 g",
        suger: "7.47 g",
      },
      dietaryFiber: "0 g",
      protein: "0 g",
      salt: "0 g",
    },
    contentDeclaration: (
      <div>
        Vatten 79%, Lime 16%, Socker 5%, Mynta
      </div>
    ),
  },

  kaffepaket: {
    id: "kaffepaket",
    title: "Gå bort-present",
    company: "Bergstrands Kafferosteri",
    image: kaffepaketImage,
    price: 199.95,
    category: COFFEE,
    weight: "3 x 250",
    description: (
      <div>
        <p>
          Bjuden på middag, grillfest eller annan tillställning?
          Blombukett och en flaska vin i all ära, men om du söker något roligt och annorlunda så har vi det självklara valet!
        </p>
        <br></br>
        <p>
          I vår gå bort-present finner du 3st olika varianter av gourmetkaffe från Bergstrands. Här nedan kan du läsa mer om vad Bergstrands skriver om de olika sorterna:
        </p>
        <br></br>
        <p>
          <i><b>Bön appetit 250 gram</b></i>
        </p>
        <p>
          Vi lät göteborgarna rösta fram sitt favoritkaffe. Valet föll på ett kaffe med karaktär som sticker ut från mängden och tar en självklar plats vid fikabordet. Bucaramangabönor kompletteras med bönor från Brasilien och Indonesien vilket ger en fyllig karaktär och kraftig kropp. Går hand i hand med mjölk men står också stadigt på egna ben, precis som göteborgaren.
        </p>
        <br></br>
        <p>
          <i><b>Sommarkoppen 250 gram</b></i>
        </p>
        <p>
          Årets sommarkaffe är ett bärigt, friskt och lättdrucket bryggkaffe. Ett tips är att låta Sommarkoppen svalna lite för att uppleva dess smak till fullo. Ett optimalt kaffe till bersån!
        </p>
        <br></br>
        <p>
          Dess lite extra bäriga och söta toner kommer ifrån den del av blandningen (10%) som utgörs av en bärtorkad böna med sittursprung i Etiopien. Vad innebär det då att bönan är bärtorkad?
        </p>
        <br></br>
        <p>
          De två vanligaste metoderna för att torka kaffe är den våta metoden och den torra metoden. Med den våta metoden avskiljs bönan från fruktköttet, för att sedantvättas och torkas. Kaffe som processats med denna metod kallasoftast tvättat kaffe. Det är med den torra metoden som man får bärtorkade bönor, på engelska kallat ”natural”. Att bönan är bärtorkad innebär att den efter skörd får torka med fruktköttet kvar tills den uppnår en fukthalt om ca 11%. Under torkningsperioden om 2-6 veckor börjar bäret att fermentera (jäsa) vilket sätter en unik smakkaraktär på slutprodukten.
        </p>
        <br></br>
        <p>
          <i><b>Båtblandning 250 gram</b></i>
        </p>
        <p>
          Precis som namnet antyder är det här ett kaffe för ett liv på sjön, både bokstavligt och när du bara vill drömma dig bort en stund. När vår farbror Thomas skulle ut på havet tyckte han att det behövdes något alldeles speciellt. Både för att han älskar livet på sjön och för att han ville fjäska lite extra för kräsna och förväntansfulla medpassagerare. Han satte ihop en mörkrostad blandning med en grapeaktig syrlighet som balanseras av en kraftig body och gräddig eftersmak. Kaffet bryggdes för första gången i en pressbryggare under en slör på Marstrandsfjorden. Vännerna utbrast i kör mitt under en jipp: Detta är det bästa kaffe vi druckit! Tryggt förtöjda vid kaj lite senare på kvällen gjorde kaffet även succé till den iskalla punsch som serverades efter en trerätters på Marstrands Wärdshus.
        </p>
      </div>
    ),
  },

  teVittmullbar: {
    id: "teVittmullbar",
    title: "Vitt mullbär",
    company: "Bergstrands Kafferosteri",
    image: teaVittmullbarImage,
    price: 69.95,
    category: TEA,
    weight: 100,
    description: (
      <div>
        <p>
          Ett elegant och smakrikt vitt te med bitar av papaya, äpple och mullbär. Himmelskt gott och passar även utmärkt att brygga som iste.
        </p>
        <br></br>
        <p>
          Bryggning: Hett vatten, ca 70 grader. 1 tsk per kopp, låt dra ca 3-4 minuter.
        </p>
      </div>
    ),
  },

  teSommargott: {
    id: "teSommargott",
    title: "Sommargott",
    company: "Bergstrands Kafferosteri",
    image: teaSommargottImage,
    price: 69.95,
    category: TEA,
    weight: 100,
    description: (
      <div>
        <p>
          Härliga bitar av hibiskus, nyponskal, äpple, apelsinskal och jordgubbar. Ger en mycket fin och härlig färg och passar perfekt att brygga som iste till ett härligt häng i hängmattan!
        </p>
        <br></br>
        <p>
          Bryggning: hett vatten, upp till ca 80-90 grader. Doseringen, ca 1 tsk á kopp, låt dra i 2-3 min.
        </p>
      </div>
    ),
  },

  teBlabarLingon: {
    id: "teBlabarLingon",
    title: "Blåbär lingon yoghurt",
    company: "Bergstrands Kafferosteri",
    image: teaBlabarLingonYoghurtImage,
    price: 69.95,
    category: TEA,
    weight: 100,
    description: (
      <div>
        <p>
          En storsäljare som går hem hos de flesta. Rooibosens naturliga sötma balanseras upp med bitar av blåbär, syrliga lingon och fryst youghurt. Denna blandning kan innehålla spår av laktos och mjölkprotein.
        </p>
        <br></br>
        <p>
          Bryggning: hett vatten, ca 95-100 grader. Doseringen, ca 1 tsk á kopp, låt dra i 3-6 min
        </p>
      </div>
    ),
  },

  smoothie: {
    id: "smoothie",
    title: "Smoothie mango/passion",
    company: "Rscued",
    image: smoothieImage,
    price: 39.95,
    category: LITE_LYX,
    volume: "25 cl",
    nutritionValues: {
      energi: "310 Kj / 70 kcal",
      fat: {
        value: "0,3 g",
        saturatedFat: "0,04 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "16 g",
        suger: "13 g",
      },
      dietaryFiber: "0 g",
      protein: "0,9 g",
      salt: "0,01 g",
    },
    contentDeclaration: (
      <div>
        Mangojuice* (35%), Banan, Passionsfruktsjuice* (21%), Äpple, Citronjuice. * Juicer från räddat koncentrat.
      </div>
    ),
  },
  rusticRagsurRussin: {
    id: "rusticRagsurRussin",
    title: "Rustik rågsur russin",
    company: "Steinbrenner & Nyberg",
    image: rusticRagsurRussinImage,
    price: 16.95,
    category: FRALLOR,
    weight: 85,
    description: (<p>En fralla bakad på ekologiskt vete och rågmjöl. I denna variant hittar du russin för lite extra sötma.</p>),
    contentDeclaration: (
      <div>
        VETEMJÖL ekologiskt, vatten, RÅGMJÖL ekologiskt, russin, salt, jäst.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  rusticRagsur: {
    id: "rusticRagsur",
    title: "Rustik rågsur",
    company: "Steinbrenner & Nyberg",
    image: rusticRagsurImage,
    price: 16.95,
    category: FRALLOR,
    weight: 85,
    description: (<p>En fralla bakad på ekologiskt vete och rågmjöl.</p>),
    contentDeclaration: (
      <div>
        VETEMJÖL ekologiskt, vatten, RÅGMJÖL ekologiskt, salt, jäst.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  durumFralla: {
    id: "durumFralla",
    title: "Durumfralla",
    company: "Steinbrenner & Nyberg",
    image: durumfrallaImage,
    price: 16.95,
    category: FRALLOR,
    weight: 100,
    description: (<p>En härlig italiens klassiker, här som fralla med hård skorpa bakad på surdeg av ekologiskt vetemjöl och italienskt durummjöl. Degen vilar minst 16 timmar i kyl innan den gräddas i stenugn.</p>),
    contentDeclaration: (
      <div>
        Vatten, VETEMJÖL ekologiskt, DURUMVETEMJÖL, salt, jäst.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  valnotsFralla: {
    id: "valnotsFralla",
    title: "Valnötsfralla",
    company: "Steinbrenner & Nyberg",
    image: valnotsfrallaImage,
    price: 18.95,
    category: FRALLOR,
    weight: 68,
    description: (<p>Valnötsfrallan bakas helt utan fett och socker. Den består av en klassisk lantdeg som innehåller 25% kravmärkt stenmalen fullkornsråg, surdeg och rikligt med ekologiska valnötter. Frallorna bakas efter att ha fått jäsa långsamt i kylen i minst 16 timmar.</p>),
    contentDeclaration: (
      <div>
        VETEMJÖL ekologiskt, RÅGMJÖL ekologiskt stenmald, VALNÖT ekologisk, KORNMALT.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  vetesur: {
    id: "vetesur",
    title: "Vetesur SolrosChiaVallmo",
    company: "Steinbrenner & Nyberg",
    image: vetesurImage,
    price: 16.95,
    category: FRALLOR,
    weight: 100,
    description: (<p>Vetesur är en fralla som bakas på ekologiskt vetemjöl med massa nyttiga och goda fröer. Här blandas även chia, vallmo och solrosfrön i degen.</p>),
    contentDeclaration: (
      <div>
        VETEMJÖL ekologiskt, vatten, RÅGMJÖL ekologiskt, solroskärnor, salt, chiafrö, jäst, vallmofrö.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  snoddas: {
    id: "snoddas",
    title: "Snoddas",
    company: "Steinbrenner & Nyberg",
    image: snoddasImage,
    price: 79.95,
    category: BREAD,
    weight: 700,
    description: (<p>En riktig Bohusfläta! Klassiskt vitt matbröd bakat med vetesurdeg och ekologiskt vetemjöl. Doppas i vacker och smaklig blå vallmo. Bakas långsamt i stenugnen direkt på hällen precis så som det gjordes när receptet kom till i Sverige på 20-talet.</p>),
    contentDeclaration: (
      <div>
        Rapsolja, VETEMJÖL ekologiskt, vatten, vallmofrö, salt, jäst, KORNMALT ekologisk.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  ryeHoney: {
    id: "ryeHoney",
    title: "Rye honey",
    company: "Steinbrenner & Nyberg",
    image: ryehoneyImage,
    price: 79.95,
    category: BREAD,
    weight: 650,
    description: (<p>Detta halvgrova surdegsbröd bakas med vete och råg samt en liten gnutta honung. Bakas i stenugn direkt på hällen vilket ger en rejäl skorpa precis som lantbröd ska ha.</p>),
    contentDeclaration: (
      <div>
        VETEMJÖL ekologiskt, vatten, RÅGMJÖL ekologiskt, honung, salt, jäst.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  durumBrod: {
    id: "durumBrod",
    title: "Durumbröd",
    company: "Steinbrenner & Nyberg",
    image: durumbrodImage,
    price: 79.95,
    category: BREAD,
    weight: 700,
    description: (<p>Härligt surdegsbröd bakad på vetesurdeg av finaste ekologiskt vetemjöl. Härlig skorpa som ger gott tuggmotstånd.</p>),
    contentDeclaration: (
      <div>
        Vatten, VETEMJÖL ekologiskt, DURUMVETEMJÖL, salt, jäst.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  smorBulle: {
    id: "smorBulle",
    title: "Smörbulle",
    company: "Steinbrenner & Nyberg",
    image: smorbulleImage,
    price: 33.95,
    category: FIKA,
    weight: 62,
    description: (<p>Vetebröd när det är som bäst? Här har vi en riktigt saftig bulle bakad med äkta smör och nymald kardemumma & farinsocker. Denna kladdiga fyllning, tillsammans med en klassisk vetedeg, gör sig makalöst bra tillsammans och för minnena bakåt i tiden för många. Bullarna toppas med en härlig blandning av ännu mer nymalen kardemumma och socker. Mums! </p>),
    contentDeclaration: (
      <div>
        VETEMJÖL ekologiskt, vatten, socker, vegetabilisk olja (palm, raps, HAVRE), farinsocker (socker/rörsockersirap), SMÖR, HAVREMJÖL, kardemumma mald, jäst, MJÖLKPROTEINER, salt, sockersirap, VETEMJÖL, rapsolja, potatisstärkelse, enzym, surhetsreglerande medel (E450), surhetsreglerande medel (E500), arom, LAKTOS.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  majsans: {
    id: "majsans",
    title: "Majsans fröflarn",
    company: "Steinbrenner & Nyberg",
    image: majsansImage,
    price: 79.95,
    category: GLUTENFRITT,
    weight: 195,
    description: (
      <div>
        <p>
          Majsans fröflarn är bakat på majsmjöl med massa goda fröer toppat med havssalt. Otroligt goda, dessutom bakat utan gluten, nötter, mandel, ägg, laktos och mjölkprotein. Ett riktigt gott fröflarn som funkar både som knäckebröd eller som snacks.
        </p><br />
        <b>
          <p>
            Råvaror som inte finns i receptet kan ändå förekomma i produkten då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
          </p>
        </b>
      </div>),
    contentDeclaration: (
      <div>
        Vatten, majsmjöl, majsstärkelse, rapsolja, linfrö, pumpakärnor, SESAMFRÖ, solroskärnor, salt.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  hallongrottor: {
    id: "hallongrottor",
    title: "Hallongrottor 6-pack",
    company: "Steinbrenner & Nyberg",
    image: hallongrottorImage,
    price: 89.95,
    category: FIKA,
    weight: 270,
    contentDeclaration: (
      <div>
        SMÖR, VETEMJÖL ekologiskt, Sylt (socker, hallon (45%),vatten, förtjockningsmedel (E401, E440), surhetsreglerande medel (E330, E331, E333), konserveringsmedel E202, arom), socker, potatismjöl, vanillin, bakpulver E450, bakpulver E500, salt, majsstärkelse.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  vaniljBulle: {
    id: "vaniljBulle",
    title: "Vaniljbulle",
    company: "Steinbrenner & Nyberg",
    image: vaniljbulleImage,
    price: 33.95,
    category: FIKA,
    weight: 100,
    description: (<p>En riktigt krämig bulle med kokt vaniljkräm, en fluffig vetedeg med ekologiskt vetemjöl.</p>),
    contentDeclaration: (
      <div>
        VETEMJÖL ekologiskt, vatten, socker, KÄRNMJÖLK, vegetabilisk olja (palm, raps, HAVRE), HAVREMJÖL, jäst, salt, kardemumma mald, vegetabiliskt fett (kokos), VETEMJÖL, arom (vanilj), färgämne (E160a), konserveringsmedel (E202), modifierad stärkelse, enzym, arom.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  pablo: {
    id: "pablo",
    title: "Pablo",
    company: "Steinbrenner & Nyberg",
    image: pabloImage,
    price: 79.95,
    category: GLUTENFRITT,
    weight: 750,
    description: (
      <div>
        <p>
          Livsstilsbrödet Pablo bakas på majs- och rismjöl. Det bakas på torsdagar och ställs direkt in i frysen för att behålla sin kvalitet. På lördag morgon tinas det upp innan leverans.
        </p><br />
        <b>
          <p>
            Råvaror som inte finns i receptet kan ändå förekomma i produkten då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
          </p>
        </b>
      </div>),
    contentDeclaration: (
      <div>
        Vatten, majsstärkelse, linfrö, SOJA proteinkoncentrat, solroskärnor, rapsolja, förtjockningsmedel (E412), förtjockningsmedel E415, förtjockningsmedel E464, rismjöl, socker, cikoriarotsfiber (inulin), psyllium, surdeg av rismjöl, salt, druvsocker, jäst, rostade linser, sockersirap, enzym, jästextrakt, maltodextrin, surhetsreglerande medel (E262).
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  berlinerKnuppel: {
    id: "berlinerKnuppel",
    title: "Berliner Knüppel",
    company: "Steinbrenner & Nyberg",
    image: berlinerImage,
    price: 15.95,
    category: FRALLOR,
    weight: 58,
    description: (<p>Ett klassiskt vitt rundstycke doppade i blå vallmofrön. Perfekt frukostfralla och underbart god med en skiva ost och paprika.</p>),
    contentDeclaration: (
      <div>
        Rapsolja, VETEMJÖL ekologiskt, vatten, vallmofrö, salt, jäst, KORNMALT ekologisk.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  miniJoel: {
    id: "miniJoel",
    title: "Mini-Joel",
    company: "Steinbrenner & Nyberg",
    image: miniJoelImage,
    price: 16.95,
    category: FRALLOR,
    weight: 75,
    description: (<p>Rustik fralla bakad på ekologiskt vete- & rågmjöl, svart sirap och med en hint av anis.</p>),
    contentDeclaration: (
      <div>
        VETEMJÖL ekologiskt, vatten, RÅGMJÖL ekologiskt, sockersirap, rapsolja, HAVREGRYN, jäst, HAVREMJÖL, ekologiskt fullkornsmjöl havre, salt, VETEMJÖL, anis, druvsocker, VETEGLUTEN, psyllium, enzym, socker, vegetabilisk olja (HAVRE), enzym (bärare VETE), mjölbehandlingsmedel (askorbinsyra), malt av VETE.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  vaniljGiffel: {
    id: "vaniljGiffel",
    title: "Vaniljgiffel",
    company: "Steinbrenner & Nyberg",
    image: vaniljgiffelImage,
    price: 33.95,
    category: FIKA,
    weight: 75,
    description: (<p>Vaniljgiffeln bakas med ekologiskt vetemjöl. Ett lager med vaniljkräm stryks på innan bagaren rullar ihop den till en kringelvariant och doppar den i strösocker. Sååå god!</p>),
    contentDeclaration: (
      <div>
        VETEMJÖL ekologiskt, vatten, socker, KÄRNMJÖLK, vegetabilisk olja (palm, raps, HAVRE), HAVREMJÖL, jäst, salt, kardemumma mald, vegetabiliskt fett (kokos), VETEMJÖL, arom (vanilj), färgämne (E160a), konserveringsmedel (E202), modifierad stärkelse, enzym, arom.
        <br />Notera att spår av andra produkter kan förekomma då de bakas på samma arbetsytor som Steinbrenners övriga produkter.
      </div>
    ),
  },
  nottopp: {
    id: "nottopp",
    title: "Nöttopp 2-pack",
    company: "Dahls bageri",
    image: nottoppImage,
    price: 49.95,
    category: GLUTENFRITT,
    weight: 120,
    nutritionValues: {
      energi: "2100 Kj / 500 kcal",
      fat: {
        value: "36 g",
        saturatedFat: "6 g",
        monounsaturatedFat: "2,4 g",
        polyunsaturatedFat: "5,2 g",
      },
      carbs: {
        value: "35 g",
        suger: "29 g",
      },
      dietaryFiber: "4,6 g",
      protein: "9,8 g",
      salt: "0,3 g",
    },
    description: (
      <div>
        <b>
          <p>
            Råvaror som inte finns i receptet kan ändå förekomma i produkten då de bakas på samma arbetsytor som Dahls övriga produkter.
          </p>
        </b>
      </div>),
    contentDeclaration: (
      <div>
        HASSELNÖTTER, socker, MANDEL, pastöriserad HELÄGGSMASSA, kakaomassa, kakaosmör, vegetabilisk olja (palm, kokos, raps), vegetabiliskt fett (palm) och härdat vegetabiliskt fett (palm), kakaopulver, vatten, glukossirap, salt, emulgeringsmedel SOJALECITIN, arom, vitamin A och D, vanillin. Kan innehålla spår av: VETEMJÖL, VALNÖTTER & SESAMFRÖN.
      </div>
    ),
  },
  kokostopp: {
    id: "kokostopp",
    title: "Kokostopp",
    company: "Dahls bageri",
    image: kokostoppImage,
    price: 49.95,
    category: GLUTENFRITT,
    weight: 120,
    nutritionValues: {
      energi: "1700 Kj / 410 kcal",
      fat: {
        value: "31 g",
        saturatedFat: "23 g",
        monounsaturatedFat: "4,9 g",
        polyunsaturatedFat: "1,6 g",
      },
      carbs: {
        value: "27 g",
        suger: "23 g",
      },
      dietaryFiber: "4,6 g",
      protein: "4,4 g",
      salt: "0,52 g",
    },
    contentDeclaration: (
      <div>
        Kokos (26%), socker, ÄGGVITA, vatten, vegetabilisk olja (palm), glukossirap, vegetabilisk olja (raps, kokos, shea), vegetabiliskt fett & vegetabilskt fullhärdat fett (palm), modifierad stärkelse, kakaopulver, ÄGGVITEPULVER, förtjockningsmedel (E401), salt, emulgeringsmedel SOJALECITIN, förtjockningsmedel (E412), arom, citronsyra, emulgeringsmedel (E322 (solros), E471, E475), färgämne (E160a), kaliumsorbat, konserveringsmedel (E263), natriumcitrat, skumbildande medel (trietylcitrat), vanillin, vitamin A, vitamin D. Kan innehålla spår av: mjöl, mjölk, hasselnötter, valnötter och sesamfrön.
      </div>
    ),
  },
  sesamLevain: {
    id: "sesamLevain",
    title: "Sesamlevain",
    company: "Dahls bageri",
    image: sesamlevainImage,
    price: 59.95,
    category: BREAD,
    weight: 450,
    nutritionValues: {
      energi: "950 Kj / 230 kcal",
      fat: {
        value: "1,7 g",
        saturatedFat: "0 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "45 g",
        suger: "0,6 g",
      },
      dietaryFiber: "2,6 g",
      protein: "7,9 g",
      salt: "1,4 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, VETESKÅLLNING, salt, jäst, SESAMFRÖ (0,8%), dextros, VETEGLUTEN, guarkärnmjöl, KORNMALT, solroslecitin, surdegspulver av VETE (7,5%), rapsolja. Kan innehålla spår av ägg, mjölk, hasselnötter, valnötter och mandel.
      </div>
    ),
  },
  sesamLevainGrov: {
    id: "sesamLevainGrov",
    title: "Sesamlevain grov",
    company: "Dahls bageri",
    image: sesamlevainGrovImage,
    price: 59.95,
    category: GLUTENFRITT,
    weight: 450,
    nutritionValues: {
      energi: "1000 Kj / 230 kcal",
      fat: {
        value: "4 g",
        saturatedFat: "0,7 g",
        monounsaturatedFat: "0 g",
        polyunsaturatedFat: "0 g",
      },
      carbs: {
        value: "41 g",
        suger: "0,7 g",
      },
      dietaryFiber: "2,5 g",
      protein: "7,7 g",
      salt: "0,97 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, solrosfrö, RÅGFLINGOR, salt, jäst, MALTMJÖL (KORN), mjölbehandlingsmedel amylas, linfrö, SESAMFRÖ, kummin, vitlök, KORNMALT, druvsocker, emulgeringsmedel (E472e), mjölbehandlingsmedel (E300), kan innehålla spår av: NÖTTER, MANDEL.
      </div>
    ),
  },
  vaniljcremebulle: {
    id: "vaniljcremebulle",
    title: "Vanilj-cremebulle",
    company: "Dahls bageri",
    image: vaniljcremebulleImage,
    price: 14.95,
    category: FIKA,
    weight: 90,
    nutritionValues: {
      energi: "1250 Kj / 300 kcal",
      fat: {
        value: "11 g",
        saturatedFat: "6,5 g",
        monounsaturatedFat: "3,1 g",
        polyunsaturatedFat: "0,9 g",
      },
      carbs: {
        value: "43 g",
        suger: "13 g",
      },
      dietaryFiber: "1,6 g",
      protein: "5,6 g",
      salt: "0,57 g",
    },
    contentDeclaration: (
      <div>
        VETEMJÖL, vatten, socker, vegetabilisk olja (palm, raps), SMÖR, KÄRNMJÖLKSPULVER, vegetabilisk olja (kokos), jäst, VASSLEPULVER (från MJÖLK), kardemumma, salt, färgämne (E101, E160a), SKUMMJÖLKSPULVER, modifierad stärkelse E1442, vaniljarom (MJÖLK), konserveringsmedel (E202), druvsocker, VETEGLUTEN, psyllium, arom, vitamin A och D, mjölbehandlingsmedel (E300). Kan innehålla spår av ÄGG, HASSELNÖTTER, VALNÖTTER och MANDEL.
      </div>
    ),
  },



};

export const INITIAL_DELIVERY_CODE_DATA_STATE = {
  validDevliveryCodes: {},
  currentDeliveryCode: "",
  devliveryCodeError: null,
  validCode: null,
};

export const INITIAL_PRODUCT_COUNT_STATE = Object.values(PRODUCTS).reduce(
  (state, product) => {
    state[product.id] = 0;

    return state;
  },
  {}
);

export const PRICE_BY_PRODUCT = Object.values(PRODUCTS).reduce(
  (state, product) => {
    state[product.id] = product.price;

    return state;
  },
  {}
);

/**
 *
 * DeliveryCodeInput
 *
 */

import {
  Button,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useEffect } from "react";
import backgroundImage from "../../assets/img/background.jpg";
import { useAppStateContext } from "../../hooks/useAppState";
import Slider from '../InfiniteSlider/index.js';

const useStyle = makeStyles((theme) => ({
  container: {
    height: 600,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    alignItems: "center",
  },

  section: {
    padding: "150px 10px 0px 10px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },

  title: {
    fontSize: 36,
    textAlign: "center",
    color: "white",
  },

  subTitle: {
    marginTop: 16,
    fontSize: 16,
    textAlign: "center",
    color: "white",
  },

  link: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    marginTop: -10,
  },

  errorText: {
    width: 330,
    backgroundColor: 'white',
    marginTop: -45,
    marginBottom: 30,
    borderRadius: 4,
    border: 1,
    borderStyle: 'solid',
    borderColor: 'red',
    padding: 14,
    fontSize: 13,
    lineHeight: 1.6,
  },

  inputContainer: {
    marginTop: 36,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },

  input: {
    width: 330,
    color: "gray",
  },

  white: {
    background: "white",
    overflow: "hidden",
    borderRadius: 4,
  },

  button: {
    position: "relative",
    zIndex: 10,
    top: -52,
    left: 130,
  },

  submitButton: {
    width: 100,
    height: 39,
    borderRadius: 24,
    fontSize: 10,
    backgroundColor: theme.palette.primary,
    color: "white",
    position: "relative",
    zIndex: 10,
    top: -48,
    left: 105,
  },

  christmasBreak: {
    width: 320,
    backgroundColor: "white",
    border: "2px solid red",
    borderRadius: "5px",
    padding: "25px 10px",
    textAlign: "center",
    marginTop: "-50px",
  }

}));

export default function DeliveryCodeInput({ deliveryCode }) {
  const {
    onSellerCodeChange,
    devliveryCodeError: error,
    validateCode,
    validCode,
    currentDeliveryCode: sellerCode,
    deliveryCodeInputRef,
    onNoCodePageClick,
  } = useAppStateContext();

  const classes = useStyle();

  const onTextChange = (e) => onSellerCodeChange(e.target.value);

  const hasError = error && !validCode;

  const handleKeyUp = (event) => {
    if (event.key === "Enter")
      validateCode();
  }

  useEffect(() => {
    const setDeliveryCodeAsSellerCode = () => {
      onSellerCodeChange(deliveryCode)
      validateCode(deliveryCode)
    }

    if (deliveryCode)
      setDeliveryCodeAsSellerCode()
  }, [])

  return (
    <>
      <div className={classes.container}>
        <div className={classes.section}>
          <div className={classes.christmasBreak}>
            <p><b>Frulla är pausat</b></p><br />
            <p>Leveranserna är pausade på obestämd tid.</p>
            <p>/ Frulla</p>
          </div>
          <Typography className={classes.title}>
            Nybakt bröd till lördag morgon
          </Typography>

          <Typography className={classes.subTitle}>
            Leverans av ungdomar från lokala idrottslag & klasser
          </Typography>

          <div className={classes.inputContainer}>
            <TextField
              onKeyUp={handleKeyUp}
              inputRef={deliveryCodeInputRef}
              className={classes.input}
              placeholder="Fyll i leveranskod"
              defaultValue={deliveryCode || sellerCode}
              name="SellerCode"
              variant="outlined"
              inputProps={{ className: classes.white }}
              onChange={onTextChange}
            />

            {hasError ? (
              <IconButton className={classes.button}>
                <CancelIcon htmlColor="red" />
              </IconButton>
            ) : validCode ? (
              <IconButton className={classes.button}>
                <CheckCircleIcon htmlColor="#21AC00" />
              </IconButton>
            ) : (
              <Button
                onClick={validateCode}
                className={classes.submitButton}
                variant="contained"
                color="primary"
              >
                Ange kod
              </Button>
            )}

          </div>
          {hasError ? (
            <div className={classes.errorText}>
              <p>Ange din kod med <b>4 siffror+namn</b>, som t.ex: 1234Victor eller 1111Göteborg och klicka på knappen "ange kod".</p>
            </div>) : ''
          }
          <p className={classes.link}>
            Har du ingen kod?
          </p>
          <Button
            className="errorButton_small"
            variant="contained"
            color="grey"
            onClick={onNoCodePageClick}
          >Klicka här
          </Button>
        </div>
      </div>
      <div>
        <Slider />
      </div>
    </>
  );
}

/**
 *
 * PendingOrder
 *
 */
import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import SwishLogo from "../../assets/logo/swish-logo.svg";
import DB from "../../services/DB";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  textContainer: {
    marginTop: 150,
    marginBottom: 30,
    width: 300,
    textAlign: "center",
  },

  title: {
    fontSize: 26,
    fontWeight: "bold",
    marginBottom: 16,
  },

  text: {
    fontWeight: "bold",
    marginBottom: 16,
  },

  swishLogo: {
    height: 60,
    width: 120,
    marginBottom: 5,
  },
}));

function fmtMSS(s) {
  return (s - (s %= 60)) / 60 + "min " + (9 < s ? "" : "0") + s + "s";
}

export default function PendingOrder({ order, resetAppState }) {
  const classes = useStyle();

  const [timeLeft, setTimeLeft] = useState(120);

  useInterval(() => {
    setTimeLeft((c) => {
      if (c === 0) {
        return;
      }

      return c - 1;
    });
  }, [1000]);

  useEffect(() => {
    if (timeLeft === 0 && order != null) {
      DB.update({
        orders: {
          [order.id]: {
            statues: "TIMEOUT_IN_APP",
          },
        },
      });

      resetAppState();
    }
  }, [timeLeft, order, resetAppState]);

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <img className={classes.swishLogo} src={SwishLogo} alt="Swish logo" />

        <Typography className={classes.title}>Öppna Swish</Typography>

        <Typography className={classes.text}>
          Du har {fmtMSS(timeLeft)} på dig att betala.
        </Typography>

        <Typography>
          Det kan ta några sekunder innan Frulla får svar från Swish om din
          betalning.
        </Typography>
      </div>
    </div>
  );
}

PendingOrder.propTypes = {
  props: PropTypes.object,
};

/**
 *
 *
 * calculateTotalPrice
 *
 */

import { PRICE_BY_PRODUCT } from '../constants/products'
import { SERVICE_FEE } from '../constants/cost'

export default function calculateTotalPrice(productCount) {
  let totalSum = 0

  for (let id in productCount) {
    const count = productCount[id]
    const price = PRICE_BY_PRODUCT[id]

    totalSum += count * price
  }

  if (totalSum === 0) {
    return totalSum
  }

  totalSum += SERVICE_FEE

  return Number(totalSum.toFixed(2))
}

/**
 *
 * ProductInfoPage
 *
 */

import PropTypes from "prop-types";
import React from "react";
import { Link, useParams } from "react-router-dom";
import BackArrowImage from "../../assets/img/back-arrow.png";
import { NUTRITIONAL_VALUE_TITELS, PRODUCTS } from "../../constants/products";
import "./styles.css";

export default function ProductInfoPage(props) {
  const { id } = useParams();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const product = PRODUCTS[id];

  return (
    <div>
      <Link className="BackArrow" to="/">
        <img className="BackArrowImage" src={BackArrowImage} alt="back" />
      </Link>

      <div className="ProductInfoContainer">
        <p className="ProductTitle">{product.title}</p>
        <img className="ProductImage" src={product.image} alt={product.id} />
        <div className="ProductMainInfo">
          {product.company && (
            <p className="ProductInfoTitleText">
              Leverantör: <strong>{product.company}</strong>
            </p>
          )}
          {product.weight && (
            <p className="ProductInfoTitleText">Vikt: {product.weight} G</p>
          )}
          {product.volume && (
            <p className="ProductInfoTitleText">Storlek: {product.volume}</p>
          )}
          {product.description && (
            <>
              <p className="ProductInfoTitle">Beskrivning</p>
              <div className="ContentDeclarationContainer ProductInfoTitleText">
                {product.description}
              </div>
            </>
          )}
        </div>
        {product.nutritionValues ? (
          <div className="ProductInfoNutritionContainer">
            <p className="ProductInfoTitle">
              Näringsvärde per {product.volume ? "100ml" : "100g"}
            </p>
            {Object.keys(product.nutritionValues).map((id, i) => {
              const value = product.nutritionValues[id];
              const title = NUTRITIONAL_VALUE_TITELS[id];
              if (typeof value == "object") {
                return (
                  <React.Fragment key={title}>
                    <div className="ProductInfoTextContainer">
                      <p className="ProductInfoTitleText">{title}</p>
                      <p className="ProductInfoTitleText">{value.value}</p>
                    </div>
                    {Object.keys(value)
                      .slice(1)
                      .map((id, i) => {
                        const subValue = value[id];
                        const subTitle = NUTRITIONAL_VALUE_TITELS[id];
                        return (
                          <div
                            key={subTitle}
                            className="ProductInfoTextContainer"
                          >
                            <p className="ProductInfoTitleText">- {subTitle}</p>
                            <p className="ProductInfoTitleText">{subValue}</p>
                          </div>
                        );
                      })}
                  </React.Fragment>
                );
              }
              return (
                <div key={title + id} className="ProductInfoTextContainer">
                  <p className="ProductInfoTitleText">{title}</p>
                  <p className="ProductInfoTitleText">{value}</p>
                </div>
              );
            })}
          </div>
        ) : null}
        {product.contentDeclaration && (
          <div className="ContentDeclaration">
            <p className="ProductInfoTitle">Innehållsdeklaration</p>
            <div className="ContentDeclarationContainer ProductInfoTitleText">
              {product?.contentDeclaration}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ProductInfoPage.propTypes = {
  props: PropTypes.object,
};

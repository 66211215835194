/**
 *
 * NoCodeInfo
 *
 */

import PropTypes from "prop-types";
import React, { Component } from "react";
import Collapsible from "../Collapsible";
import SectionHeader from "../SectionHeader";
import Footer from "../Footer";
import Input from "../Common/input";
import SendEmail from "../Common/sendEmail";

export default class NoCodeInfo extends Component {
  state = {
    data: { emailSubject: "Intresseanmälan kund - jag har ingen kod", name: "", phonenumber: "", other: "", email: "", address: "", city: "" },
    isPending: false,
  }

  async handleSubmit(event) {
    const data = this.state.data;
    this.setState({ isPending: true })
    await SendEmail(event, data);
    this.setState({ isPending: false })
  }


  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data }
    data[input.name] = input.value
    this.setState({ data })
  }

  render() {

    return (
      <div>
        <SectionHeader title="Har du ingen kod?" />
        <div className="background_infopage">
          <div className="container_infopage">

            <Collapsible
              heading="Varför behövs en leveranskod?"
              body={<>
                <p>Produkter som säljs på frulla.se levereras av ungdomar från idrottslag och klasser. För att Frulla ska veta vem som ska leverera din beställning behöver du fylla i din ungdoms personliga leveranskod på hemsidan för att komma vidare och få möjlighet att lägga din beställning. Dessa koder får du oftast via dörrknackning, folder i brevlådan eller sociala medier-annonsering från just en ungdom som bor/levererar i ditt geografiska område.</p>
              </>} />

            <Collapsible
              heading="Har du beställt tidigare?"
              body={<>
                <p>Har du beställt tidigare men tappat bort din kod så hittar du leveranskoden i din senaste bekräftelse från oss. Det går också bra att höra av dig till oss på <a href="mailto:support@frulla.se">support@frulla.se</a> så skickar vi ut koden till dig.</p>
              </>} />

            <Collapsible
              defaultOpen={true}
              heading="Använd en områdeskod"
              body={<>
                <p>Leveranskoderna gäller i hela området som koden antyder.</p>
                <li>Leveranskod: <b>0700Floda</b></li>
                <li>Leveranskod: <b>0800Lerum</b></li>
                <li>Leveranskod: <b>1100Olofstorp</b></li>
                <li>Leveranskod: <b>1500Kärra</b></li>
                <li>Leveranskod: <b>1700Gråbo</b></li>
                <li>Leveranskod: <b>1800Kvisljungeby</b></li>
                <li>Leveranskod: <b>1800Björlanda</b></li>
                <li>Leveranskod: <b>1800Lilleby</b></li>
                <li>Leveranskod: <b>2000Askim</b></li>
                <li>Leveranskod: <b>2000Näset</b></li>
                <li>Leveranskod: <b>2000Eklanda</b></li>
                <li>Leveranskod: <b>2000Frölunda</b></li>
                <li>Leveranskod: <b>2000Sjövik</b></li>
                <li>Leveranskod: <b>2000Mölndal</b></li>
                <br></br>
                <p>I dessa områden har vi så pass många föreningar/klasser med ungdomar att vi täcker hela geografiska läget. Om du bor i någon av orterna kan du med fördel använda områdeskoden och därefter gå vidare med din beställning.</p>
              </>} />

          </div>
        </div>

        <SectionHeader title="Intresseanmälan" />
        <div className="background_infopage">

          <div className="emailForm">
            <h2 className="contact_infopage">
              Är du nyfiken på om vi kan leverera din nybakta lördagslyx? Fyll i formuläret så återkommer vi till dig på sms eller mail.
            </h2>

            <form onSubmit={e => this.handleSubmit(e)}>
              <Input name="name" label="Namn" value={this.state.data.name} onChange={this.handleChange} required />
              <Input name="address" label="Adress" value={this.state.data.address} onChange={this.handleChange} required />
              <Input name="city" label="Ort" value={this.state.data.city} onChange={this.handleChange} required />
              <Input name="phonenumber" label="Telefonnummer" value={this.state.data.phonenumber} onChange={this.handleChange} required />
              <Input name="email" label="Email" value={this.state.data.email} onChange={this.handleChange} />
              <Input name="other" label="Meddelande" value={this.state.data.other} onChange={this.handleChange} multiline rows={3} />
              <button className="sendFormButton" disabled={this.state.isPending} >
                Skicka
              </button>
            </form>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

NoCodeInfo.propTypes = {
  props: PropTypes.object,
};
import React from "react";
import Input from "../../Common/input";
import { saveTeam } from "../../../services/fakeTeamService";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTeam } from "../../../services/fakeTeamService";
import { Link } from "react-router-dom";
import "./styles.css"


function TeamForm() {
    const history = useHistory();
    const [state, setState] = useState({
        data: { name: "", teamPercentEarning: "", personalPercentEarning: "", contactPerson: "", address: "", phoneNumber: "", totalTeamEarnings: 0, paidOutAmount: 0, active: true },
    })
    const { id: paramsId } = useParams();
    const isNewTeam = paramsId === "new" ? true : false;

    useEffect(() => {
        if (isNewTeam) return;

        const team = getTeam(paramsId);
        if (!team) return history.replace("/not-found");

        setState({ data: team });
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        const data = state.data;
        saveTeam(data);
        history.push("/admin-page/teams")
    }

    function handleChange({ currentTarget: input }) {
        const data = { ...state.data }
        data[input.name] = input.value
        setState({ data })
    }

    function toggleActive(e) {
        e.preventDefault();
        const data = { ...state.data }
        data.active = !data.active;
        setState({ data })
    }

    const { name, teamPercentEarning, personalPercentEarning, contactPerson, address, phoneNumber, paidOutAmount, active } = state.data;

    return (
        <div className="addTeam_container">
            <button><Link to="/admin-page/teams">Tillbaka</Link></button>
            <h2>{isNewTeam ? "Lägg till nytt lag" : ""}</h2>
            <h2>{active ? "" : "INAKTIVERAT"}</h2>
            <form onSubmit={e => handleSubmit(e)}>
                <Input label="Lagnamn" name="name" onChange={handleChange} value={name} required />
                <Input label="Lagets förtjänst" name="teamPercentEarning" onChange={handleChange} value={teamPercentEarning} required />
                <Input label="Personlig förtjänst" name="personalPercentEarning" onChange={handleChange} value={personalPercentEarning} required />
                <Input label="Kontaktperson" name="contactPerson" onChange={handleChange} value={contactPerson} required />
                <Input label="Telefonnummer" name="phoneNumber" onChange={handleChange} value={phoneNumber} required />
                <Input label="Adress" name="address" onChange={handleChange} value={address} required />
                <Input label="Utbetalt" name="paidOutAmount" onChange={handleChange} value={paidOutAmount} required />
                {active ? <button onClick={toggleActive}>Inaktivera</button> : <button onClick={toggleActive}>Aktivera</button>}
                <button className="sendFormButton">{isNewTeam ? "Skapa lag" : "Spara"}</button>
            </form>
        </div >
    );
};

export default TeamForm;
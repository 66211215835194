import React, { Component } from "react";
import Table from "../../Common/table";
import { Link } from "react-router-dom";

class OrdersTable extends Component {
    columns = [
        { path: "id", label: "Order id", content: order => <Link to={`/admin-page/orders/${order.id}`}>{order.id}</Link> },
        { path: "delivered", label: "Levererad", content: order => <p>{order.delivered ? "Levererad" : ""}</p> },
        { path: "deliveredTime", label: "Tid", content: order => <p>{order.delivered ? `${order.deliveredTime}` : ""}</p> },
        { path: "deliverycode", label: "Leveranskod" },
        { path: "name", label: "Namn" },
        { path: "address", label: "Adress" },
    ]

    render() {
        const { orders, onSort, sortColumn } = this.props;

        return (
            <div>
                <Table
                    columns={this.columns}
                    data={orders}
                    sortColumn={sortColumn}
                    onSort={onSort} />
            </div>
        );
    }
};

export default OrdersTable;
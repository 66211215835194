import React, { Component } from "react";
import Collapsible from "../Collapsible";
import Footer from "../Footer";
import SectionHeader from "../SectionHeader";
import Input from "../Common/input";
import SendEmail from "../Common/sendEmail";
export default class SellWithFrulla extends Component {
    state = {
        data: { emailSubject: "Intresseanmälan ungdom", name: "", phonenumber: "", other: "", email: "", address: "", recommendedBy: "" },
        isPending: false,
    }

    async handleSubmit(event) {
        const data = this.state.data;
        this.setState({ isPending: true })
        await SendEmail(event, data);
        this.setState({ isPending: false })
    }


    handleChange = ({ currentTarget: input }) => {
        const data = { ...this.state.data }
        data[input.name] = input.value
        this.setState({ data })
    }

    render() {

        return (
            <div>
                <SectionHeader title="Frulla som helgjobb" />
                <div className="background_infopage">
                    <div className="container_infopage">
                        <h2 className="h2_infopage">Helgjobb för dig som ungdom!</h2>
                        <p className="text_infopage">Med Frulla delar du ut nybakt bröd och annat gott till dina grannar på lördagsmorgnar. De får starta helgen på bästa sätt samtidigt som du får ett kul och enkelt jobb där du tjänar pengar och ger dig själv bättre möjligheter för framtiden.</p>
                        <p className="text_infopage"><b>PSST…</b> Dina föräldrar kan ta sovmorgon! Frulla sköter all administration - kunder betalar direkt på frulla.se och du får dessutom dina kunders beställningar levererade hem till dörren.</p>
                        <p className="text_infopage">Det är jättelätt att komma igång! Så här fungerar det: </p>

                        <Collapsible
                            heading="Kom igång"
                            body={<>
                                <h3>Steg 1</h3>
                                <p>Du får ett startpaket innehållande foldrar och en personlig leveranskod.</p>
                                <h3>Steg 2</h3>
                                <p>Under första veckan delar du ut foldrar till grannar i ditt område. I foldern står all information de behöver veta för att kunna beställa med din leveranskod.</p>
                                <h3>Steg 3</h3>
                                <p>Grannen går själv in på frulla.se, fyller i koden och klickar hem de produkter som önskas till lördag morgon.</p>
                            </>} />

                        <Collapsible
                            heading="Leverans"
                            body={<>
                                <h3>Steg 1</h3>
                                <p>På lördagsmorgnar levereras dina grannars färdigpackade beställningar hem till dig. Du får ett sms i samband med att påsarna lämnas utanför din dörr.</p>
                                <h3>Steg 2</h3>
                                <p>På brödpåsarna ser du vem som har beställt och var de bor så att du enkelt kan leverera till de kunder som beställt i ditt område.</p>
                                <span>På fredagar ser du hur många kunder som beställt samt hur mycket du tjänat.</span>
                            </>} />

                        <Collapsible
                            heading="Återkommande beställningar"
                            body={<>
                                <p>Efter att kunder fått din kod så kan de enkelt fortsätta att beställa av dig på vår hemsida även nästkommande veckor. På torsdagar får dina befintliga kunder en uppskattad påminnelse för att hjälpa dem komma ihåg att beställa inför helgen. Vidare har vi kontinuerligt nya produkter, kampanjer och erbjudanden för att hjälpa dig att få återkommande beställningar.</p>
                            </>} />

                        <Collapsible
                            heading="Hur mycket tjänar du?"
                            body={<>
                                <p>Du tjänar 17,5% på alla produkter som säljs. Om en kund beställer produkter för 100kr tjänar du alltså 17,50kr.</p>
                                <p><b>Exempel:</b> Du får 10st beställningar per vecka = Du tjänar 210kr per lördagsmorgon vilket blir 840kr i lön på en månad.</p>
                                <span>Ovanstående exempel beräknas på 120kr per beställning vilket är genomsnittet som en kund handlar för på frulla.se.</span>
                            </>} />

                        <Collapsible
                            heading="Bra att känna till"
                            body={<>
                                <h3>Kan jag dela min kod på sociala medier?</h3>
                                <p>Det går utmärkt så länge du kan leverera till personen som beställer av dig. Många av våra ungdomar delar vanligtvis sin kod i mindre områdesgrupper på Facebook.</p>
                                <h3>Vad händer om jag ska bort eller blir sjuk en helg?</h3>
                                <p>Inga konstigheter! Om du inte kan leverera under en eller flera helger smsar du oss så hjälper vi till med utkörning direkt till kund.</p>
                                <h3>Hur lång tid tar det att gå ut med beställningar på lördagsmorgnar?</h3>
                                <p>Om du levererar i ditt eget område så går det fort att gå ut med brödpåsarna till dina grannar. Vanligtvis tar det ungefär 30 minuter att gå ut med 10 beställningar.</p>
                                <h3>Kan jag ha Frulla som sommarjobb?</h3>
                                <p>Absolut! Vi fortsätter precis som vanligt under sommaren.</p>
                                <h3>Hur länge fortsätter jag att leverera frukost till mina grannar?</h3>
                                <p>Du väljer helt själv hur lång period du vill leverera nybakt. När du känner dig nöjd hör du av dig till oss så tar vi bort din personliga leveranskod och meddelar alla dina kunder samtidigt som vi tackar dem för att de stöttat dig.</p>
                            </>} />

                        <Collapsible
                            heading="Värva en vän"
                            body={<>
                                <h3>Har du en kompis, syskon eller kusin som också vill dela ut nybakt bröd på lördagsmorgnar?</h3>
                                <p>Passa på att tipsa din kompis om helgjobb på Frulla. Om hon eller han får minst 5 beställningar sin första vecka så får ni båda varsin biobiljett!</p>
                                <h3>Har du blivit värvad av en kompis som jobbar på Frulla?</h3>
                                <p>Skriv din kompis namn i rutan "Fått tips av" i formuläret så kommer ni båda att få varsin biobiljett om du får minst 5 beställningar din första vecka.</p>
                            </>} />

                    </div>
                </div>
                <SectionHeader title="Intresseanmälan" />
                <div className="background_infopage">

                    <div className="emailForm">
                        <h2 className="contact_infopage">
                            Är du intresserad av att veta mer eller redo testa på Frulla som helgjobb? Fyll i formuläret nedan så hör vi av oss till dig på sms eller mail.
                        </h2>

                        <form onSubmit={e => this.handleSubmit(e)}>
                            <Input name="name" label="Namn" value={this.state.data.name} onChange={this.handleChange} required />
                            <Input name="address" label="Adress" value={this.state.data.address} onChange={this.handleChange} />
                            <Input name="phonenumber" label="Telefonnummer" value={this.state.data.phonenumber} onChange={this.handleChange} required />
                            <Input name="email" label="Email" value={this.state.data.email} onChange={this.handleChange} />
                            <Input name="recommendedBy" label="Fått tips av" value={this.state.data.recommendedBy} onChange={this.handleChange} />
                            <Input name="other" label="Meddelande" value={this.state.data.other} onChange={this.handleChange} multiline rows={3} />
                            <button className="sendFormButton" disabled={this.state.isPending} >
                                Skicka
                            </button>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const teams = [
    {
        id: "5b21ca3eeb7f6fbccd471811",
        name: "FBC Lerum F08",
        teamPercentEarning: 0.02,
        personalPercentEarning: 0.175,
        contactPerson: "Lisa Nilsson",
        address: "Björkgatan 1",
        phoneNumber: "0709-121212",
        active: true,
        totalTeamEarnings: 0,
        paidOutAmount: 0,
        finalSellingDate: "2022-07-31",
    },
    {
        id: "5b21ca3eeb7f6fbccd471812",
        name: "Floda IBK F09",
        teamPercentEarning: 0.02,
        personalPercentEarning: 0.175,
        contactPerson: "Johan Johansson",
        address: "Jordvägen 1",
        phoneNumber: "0705-334455",
        active: false,
        totalTeamEarnings: 0,
        paidOutAmount: 0,
        loginIsSent: true,
        finalSellingDate: "2022-02-18",
    },
    {
        id: "5b21ca3eeb7f6fbccd471813",
        name: "Askims IK P06",
        teamPercentEarning: 0.02,
        personalPercentEarning: 0.175,
        contactPerson: "Erik Josefsson",
        address: "Mysgatan 22",
        phoneNumber: "0706-987123",
        active: true,
        totalTeamEarnings: 4989,
        paidOutAmount: 0,
        finalSellingDate: "",
    },
    {
        id: "5b21ca3eeb7f6fbccd471814",
        name: "Ebba Peterssonskolan 7C",
        teamPercentEarning: 0.25,
        personalPercentEarning: 0,
        contactPerson: "Johanna Eriksson",
        address: "Lindvägen 41",
        phoneNumber: "0760-512472",
        active: true,
        totalTeamEarnings: 14232,
        paidOutAmount: 6500,
        finalSellingDate: "2022-09-31",
    },
    {
        id: "5b21ca3eeb7f6fbccd471815",
        name: "Kärra HF F07",
        teamPercentEarning: 0.02,
        personalPercentEarning: 0.175,
        contactPerson: "Matilda Berg",
        address: "Nyvägen 12",
        phoneNumber: "0703-331902",
        active: false,
        totalTeamEarnings: 7834,
        paidOutAmount: 7834,
        finalSellingDate: "2022-04-15",
    },
];

export function getTeams() {
    return teams;
}

export function getTeam(id) {
    return teams.find(t => t.id === id);
}

export function saveTeam(team) {
    let teamInDb = teams.find(t => t.id === team.id) || {};
    teamInDb.name = team.name;
    teamInDb.teamPercentEarning = team.teamPercentEarning;
    teamInDb.personalPercentEarning = team.personalPercentEarning;
    teamInDb.contactPerson = team.contactPerson;
    teamInDb.phoneNumber = team.phoneNumber;
    teamInDb.active = team.active;
    teamInDb.totalTeamEarnings = team.totalTeamEarnings;
    teamInDb.paidOutAmount = team.paidOutAmount;

    if (!teamInDb.id) {
        teamInDb.id = Date.now().toString();
        teams.push(teamInDb);
    }

    return teamInDb;
}

export function deleteTeam(id) {
    let teamInDb = teams.find(t => t.id === id);
    teams.splice(teams.indexOf(teamInDb), 1);
    return teamInDb;
}

const orders = [
    {
        id: "5b7f6fbccd471811",
        name: "Victor Eriksson",
        deliverycode: "0101vita",
        amount: 109.75,
        phoneNumber: "0709-121212",
        address: "Holmenkollen 147",
        city: " Göteborg",
        products: {
            källarfranska: 2,
            sichuanShot: 1,
        },
        deliveryDate: "2022-05-28",
        deliveryMethod: "I brevlådan",
        delivered: false,
        deliveredTime: "",
    },
    {
        id: "eb7f6fbccd47181e",
        name: "Johan Johansson",
        deliverycode: "0102maja",
        amount: 89.85,
        phoneNumber: "0709-551243",
        address: "Rivarstigen 17",
        city: " Mölndal",
        products: {
            kanelbulle: 4,
            levain: 4,
            gourmet: 2,
            ostbrack: 3,
        },
        deliveryDate: "2022-05-28",
        deliveryMethod: "Utanför dörren",
        delivered: false,
        deliveredTime: "",
    },
    {
        id: "5b1ca3f6fbccd473",
        name: "Kerstin Beckeman",
        deliverycode: "0309Agnes",
        amount: 92.80,
        phoneNumber: "0709-991244",
        address: "Vidareslingan 33",
        city: " Lerum",
        products: {
            ostbrack: 2,
            ragbulle: 2,
        },
        deliveryDate: "2022-05-28",
        deliveryMethod: "Knacka på",
        delivered: false,
        deliveredTime: "",
    },
    {
        id: "5bf6fbccd4718174",
        name: "Elisabeth Mung",
        deliverycode: "0309Agnes",
        amount: 178.40,
        phoneNumber: "0760-011241",
        address: "Ringögatan 1",
        city: " Lerum",
        products: {
            mummabulle: 4,
            sichuanShot: 1,
        },
        deliveryDate: "2022-05-28",
        deliveryMethod: "I den bruna lådan bakom dörren",
        delivered: false,
        deliveredTime: "",
    },
    {
        id: "7f6fbccd4718155b",
        name: "Laura Skog",
        deliverycode: "0101vita",
        amount: 114.60,
        phoneNumber: "0739-771263",
        address: "Golfgatan 26",
        city: " Göteborg",
        products: {
            gourmet: 5,
            vallmobrack: 5,
        },
        deliveryDate: "2022-05-28",
        deliveryMethod: "I påse på handtaget",
        delivered: true,
        deliveredTime: "08:12:43",
    },
];

export function getOrders() {
    return orders;
}

export function getOrder(id) {
    return orders.find(o => o.id === id);
}

export function saveOrder(order) {
    let orderInDb = orders.find(o => o.id === order.id) || {};
    orderInDb.name = order.name;
    orderInDb.deliverycode = order.deliverycode;
    orderInDb.amount = order.amount;
    orderInDb.delivered = order.delivered;
    orderInDb.phoneNumber = order.phoneNumber;
    orderInDb.deliveryDate = order.deliveryDate;
    orderInDb.address = order.address;
    orderInDb.city = order.city;

    if (!orderInDb.id) {
        orderInDb.id = Date.now().toString();
        orders.push(orderInDb);
    }

    return orderInDb;
}

export function deleteOrder(id) {
    let orderInDb = orders.find(o => o.id === id);
    orders.splice(orders.indexOf(orderInDb), 1);
    return orderInDb;
}
